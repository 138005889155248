/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import queryString from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { getCommonTagId } from './components/AmenityChoices';
import Jotform from './components/JotformReact';
import { PageFooter } from './components/page-footer';
import { FeedbackParams, TopSection } from './Feedback';
import { getFeedback } from './profiles';
import styles from './styles/Home.module.css';
import { AssetInterface, FeedbackInterface, LocationInterface, OrganizationInterface, OrganizationStyling, PractitionerInterface, RecipientInterface } from './types';

const { stringify } = queryString;

export const FeedbackSurvey: FC = () => {
  const { feedbackId = '62a24459ffecdb6fdbe7ccfc' } = useParams<FeedbackParams>();
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );
  // const hrefPrevious = useHref( '../nonprofits', { relative: 'path' } );

  const [ isLoaded, setIsLoaded ] = useState( false );
  const [ feedback, setFeedback ] = useState<FeedbackInterface>();
  const [ recipient, setRecipient ] = useState<RecipientInterface>();
  const [ practitioner, setPractitioner ] = useState<PractitionerInterface>();
  const [ location, setLocation ] = useState<LocationInterface>();
  const [ organization, setOrganization ] = useState<OrganizationInterface>();
  const [ assets, setAssets ] = useState<AssetInterface[]>( [] );

  useEffect( () => {
    ( async () => {
      if( !feedbackId || isLoaded ) return;
      const { feedback, recipient, practitioner, location, organization, assets } = ( await getFeedback( feedbackId ) ) || {};
      if( !recipient ) return;
      setIsLoaded( true );
      setFeedback( feedback );
      setRecipient( recipient );
      setPractitioner( practitioner );
      setLocation( location );
      setOrganization( organization )
      setAssets( assets || [] );
    } )()
  }, [ feedbackId ] );

  const { logoAsset, logoTitle, colorPrimary, fontBody, fontHeader, formQueryString } = useMemo( () => {
    if( !( organization && practitioner && location && recipient && feedback ) ) return {};
    // const { amenityTags } = organization;
    const { iconStyle = 'default', amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader } = ( organization || {} ) as unknown as OrganizationStyling;
    const iconProps = {
      // htmlColor: [ 'default', 'primary', 'secondary' ].includes( iconStyle ) ? undefined : iconStyle,
      '& svg': {
        color: [ 'primary', 'secondary' ].includes( iconStyle )
          ? ( iconStyle == 'primary' ? colorPrimary : colorSecondary )
          : 'inherit',
        fontSize: '2.25rem', // 'large',
      }
    };
    const logoAsset = ( assets ).find( asset => ( asset.tags || [] ).includes( getCommonTagId( 'square' ) ) );
    const logoTitle = [ organization.name, logoAsset?.subject || 'logo' ].join( ' ' );
    const formQueryString = stringify( {
      feedback: feedback.id,
      organization: organization.id,
      location: location.id,
      practitioner: practitioner.id,
      recipient: recipient.id,
      appointment: feedback.appointment,
    } );

    return { logoAsset, logoTitle, iconProps, amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader, formQueryString };
  }, [ assets, organization, practitioner, feedback, location, recipient ] );

  if( !( recipient && organization && location && practitioner && feedback ) ) return null;

  return (
    <Box sx={{
      padding: { xs: 0, md: '0 1.5rem' },
      maxWidth: '960px',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: { xs: '1rem', md: '1.2rem' },
      backgroundColor: '#f9fafb',
      fontFamily: fontBody,

    }}>
      <Helmet>
        <title className='translated'>Feedback Survey - {location.name}</title>
        <meta name="description" content={`Feedback on ${ location.name }`} className='translated' />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <main className={styles.main} style={{ fontFamily: fontBody }}>

        <TopSection {...{ location, organization, fontHeader, colorPrimary, isXSmall }} />

        <Grid item key="form" container
          sx={{
            width: '100%',
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          <Jotform
            formURL={`https://form.jotform.com/221586440106147?${ formQueryString }&isIframeEmbed=1`}
            formID="221586440106147"
            // initialHeight={600}
            autoResize={true}
          />
        </Grid>

      </main>

      <PageFooter organization={organization} />

    </Box>
  )
}

