import { init } from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword/index.js';
import Passwordless from 'supertokens-auth-react/recipe/passwordless/index.js';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui.js';
import { PasswordlessPreBuiltUI } from 'supertokens-auth-react/recipe/passwordless/prebuiltui.js';
import Session from 'supertokens-auth-react/recipe/session/index.js';
import { SuperTokensConfig } from 'supertokens-auth-react/lib/build/types.js';
import Multitenancy from 'supertokens-auth-react/recipe/multitenancy/index.js';
import { RecipeImplementation } from 'supertokens-auth-react/lib/build/recipe/passwordless/types';

export function getApiDomain() {
  // const apiPort = process.env.REACT_APP_API_PORT || 3400; // 3001;
  const apiUrl = process.env.REACT_APP_API_SERVER || location.origin; // `http://localhost:3300`;
  return apiUrl;
}

export function getApiBasePath() {
  return '/api/v1/data/auth';
}

export function getWebsiteDomain() {
  // const websitePort = location.port; //  process.env.REACT_APP_WEBSITE_PORT || 3000;
  const websiteUrl = location.origin; //  process.env.REACT_APP_WEBSITE_URL || `http://localhost:${ websitePort }`;
  return websiteUrl;
}

export const superTokensConfig: SuperTokensConfig = {
  appInfo: {
    appName: 'Analog Concierge',
    apiDomain: getApiDomain(),
    apiBasePath: getApiBasePath(),
    websiteDomain: getWebsiteDomain(),
  },

  usesDynamicLoginMethods: true,

  disableAuthRoute: true,

  getRedirectionURL: async ( context ) => {
    if( context.action === 'SUCCESS' && context.newSessionCreated ) {
      if( context.redirectToPath !== undefined ) {
        // we are navigating back to where the user was before they authenticated
        return context.redirectToPath;
      }
      const params = new URLSearchParams( location.search );
      const redirectToPath = params.get( 'redirectToPath' );
      if( redirectToPath ) {
        return redirectToPath;
      }
      if( context.createdNewUser ) {
        // user signed up
      } else {
        // user signed in
      }
      // return '/app/profiles';
    }
    return undefined;
  },

  languageTranslations: {
    translations: {
      en: {
        // // lib/ts/recipe/passwordless/components/themes/translations.ts
        // GENERAL_ERROR_EMAIL_UNDEFINED: 'Please set your email',
        // GENERAL_ERROR_EMAIL_NON_STRING: 'Email must be of type string',
        // GENERAL_ERROR_EMAIL_INVALID: 'Email is invalid',
        //  
        // GENERAL_ERROR_PHONE_UNDEFINED: 'Please set your phone number',
        // GENERAL_ERROR_PHONE_NON_STRING: 'Phone number must be of type string',
        // GENERAL_ERROR_PHONE_INVALID: 'Phone number is invalid',
        //  
        GENERAL_ERROR_OTP_UNDEFINED: 'Please fill your code',
        GENERAL_ERROR_OTP_INVALID: 'Invalid code',
        GENERAL_ERROR_OTP_EXPIRED: 'Expired code.',
        GENERAL_ERROR_OTP_NON_STRING: 'Code must be of type string',
        GENERAL_ERROR_OTP_EMPTY: 'Code cannot be empty',
        //  
        // ERROR_SIGN_IN_UP_LINK: 'Invalid magic link. Please try again.',
        // ERROR_SIGN_IN_UP_RESEND_RESTART_FLOW: 'Login timed out. Please try again.',
        // ERROR_SIGN_IN_UP_CODE_CONSUME_RESTART_FLOW: 'Login unsuccessful. Please try again.',
        //  
        // PWLESS_SIGN_IN_UP_EMAIL_LABEL: 'Email',
        // PWLESS_SIGN_IN_UP_PHONE_LABEL: 'Phone Number',
        // PWLESS_SIGN_IN_UP_SWITCH_TO_PHONE: 'Use a Phone number',
        // PWLESS_SIGN_IN_UP_SWITCH_TO_EMAIL: 'Use an Email',
        // PWLESS_SIGN_IN_UP_CONTINUE_BUTTON: 'CONTINUE',
        // PWLESS_COMBO_CONTINUE_WITH_PASSWORDLESS_LINK: 'Continue with passwordless',
        // PWLESS_COMBO_CONTINUE_WITH_PASSWORDLESS_BUTTON: 'CONTINUE WITH PASSWORDLESS',
        //  
        // PWLESS_COMBO_PASSWORD_LABEL: 'Password',
        // PWLESS_COMBO_FORGOT_PW_LINK: 'Forgot password?',
        //  
        // PWLESS_LINK_SENT_RESEND_SUCCESS: 'Link resent',
        // PWLESS_LINK_SENT_RESEND_TITLE: 'Link sent!',
        // PWLESS_LINK_SENT_RESEND_DESC_START_EMAIL: 'We sent a link to ',
        // PWLESS_LINK_SENT_RESEND_DESC_START_PHONE: 'We sent a link to your phone number ',
        // PWLESS_LINK_SENT_RESEND_DESC_END_EMAIL: ' Click the link to login or sign up',
        // PWLESS_LINK_SENT_RESEND_DESC_END_PHONE: '',
        //  
        PWLESS_SIGN_IN_UP_CHANGE_CONTACT_INFO_EMAIL: 'Change email',
        PWLESS_SIGN_IN_UP_CHANGE_CONTACT_INFO_PHONE: 'Change phone number',
        //  
        // PWLESS_LINK_CLICKED_CONTINUE_HEADER: 'Sign Up or Log In',
        // PWLESS_LINK_CLICKED_CONTINUE_DESC: 'Click the button below to log in on this device',
        // PWLESS_LINK_CLICKED_CONTINUE_BUTTON: 'CONTINUE',
        //  
        // PWLESS_RESEND_SUCCESS_EMAIL: 'Email resent',
        // PWLESS_RESEND_SUCCESS_PHONE: 'SMS resent',
        //  
        PWLESS_RESEND_BTN_DISABLED_START: '', // 'Resend in ',
        PWLESS_RESEND_BTN_DISABLED_END: '',
        // PWLESS_RESEND_BTN_EMAIL: 'Resend Email',
        // PWLESS_RESEND_BTN_PHONE: 'Resend SMS',

        PWLESS_USER_INPUT_CODE_HEADER_TITLE: 'Enter code',
        PWLESS_USER_INPUT_CODE_HEADER_SUBTITLE: 'A code was sent to you at',
        // PWLESS_USER_INPUT_CODE_HEADER_SUBTITLE_LINK: 'An OTP and a magic link was sent to you at',
        PWLESS_USER_INPUT_CODE_INPUT_LABEL: 'Code',
      },
    },
    defaultLanguage: 'en',
  },


  recipeList: [
    EmailPassword.init(),
    Passwordless.init( {
      contactMethod: 'EMAIL_OR_PHONE',

      // validateEmailAddress: ( value: unknown ) => typeof value == 'string' ? value : undefined,
      // validatePhoneNumber: ( value: unknown ) => typeof value == 'string' ? value : undefined,

      signInUpFeature: {
        defaultCountry: 'US',
        // resendEmailOrSMSGapInSeconds: 120,  // Mis-interpreted as time to enter code
      },
      override: {

        functions: ( oI: RecipeImplementation ) => ( {
          ...oI,
          createCode: async ( input ) => {
            const r = await oI.createCode( input );
            console.log( 'createCode input', input );
            console.log( 'createCode result', r );
            return r;
          },
          resendCode: async ( input ) => {
            const r = await oI.resendCode( input );
            console.log( 'resendCode input', input );
            console.log( 'resendCode result', r );
            return r;
          },
          consumeCode: async ( input ) => {
            const r = await oI.consumeCode( input );
            console.log( 'consumeCode input', input );
            console.log( 'consumeCode result', r );
            return r;
          },
          getLoginAttemptInfo: async ( input ) => {
            console.log( 'getLoginAttemptInfo', input );
            return await oI.getLoginAttemptInfo( input );
            // console.log( 'getLoginAttemptInfo', r );
            // return r as Awaited<ReturnType<typeof oI.getLoginAttemptInfo>>;
          },
          setLoginAttemptInfo: async ( input ) => {
            console.log( 'setLoginAttemptInfo', input );
            await oI.setLoginAttemptInfo( input );
          },
        } )

      }
    } ),
    Session.init( {
      // exposeAccessTokenToFrontendInCookieBasedAuth: true,
    } ),

    Multitenancy.init( {
      // override: {
      //   functions: (oI) => {
      //     return {
      //       ...oI,
      //       getTenantId: (input) => {
      //         const tid = localStorage.getItem('tenantId');
      //         return tid === null ? undefined : tid;
      //       }
      //     }
      //   }
      // }
    } )

  ],

  clientType: 'web',
  // cookieHandler?: CookieHandlerInput;
  // windowHandler?: WindowHandlerInput;
  // dateProvider?: DateProviderInput;
  // usesDynamicLoginMethods?: boolean;
  // languageTranslations?: {
  //   defaultLanguage?: string;
  //   currentLanguageCookieScope?: string;
  //   translations?: TranslationStore;
  //   translationFunc?: TranslationFunc;
  // };
  // enableDebugLogs?: boolean;
  // getRedirectionURL?: (
  //   context: GetRedirectionURLContext,
  //   userContext: UserContext
  // ) => Promise<string | undefined | null>;
  // style?: string;
  // useShadowDom?: boolean;
  // disableAuthRoute?: boolean;
  // defaultToSignUp?: boolean;
  privacyPolicyLink: 'https://analoginformatics.com/privacy',
  termsOfServiceLink: 'https://analoginformatics.com/terms',
  style: `
        [data-supertokens~=authPage] [data-supertokens~=headerSubtitle] {
            display: none;
        }
        [data-supertokens~=superTokensBranding] {
            display: none;
        }
        [data-supertokens~=resendCodeBtn] strong {
            display: none;
        }
        [data-supertokens~=container] .MuiCardHeader-root {
            padding: 1rem 0;
        }
        [data-supertokens~=container] .MuiCardHeader-content .MuiTypography-root {
            font-size: 1.2rem;
        }
       [data-supertokens~=container] {
             --palette-primary: 46, 71, 102;
             --palette-primaryBorder: 146, 171, 202;
            // --palette-background: 51, 51, 51;
            // --palette-inputBackground: 41, 41, 41;
            // --palette-inputBorder: 41, 41, 41;
            // --palette-textTitle: 255, 255, 255;
            // --palette-textLabel: 255, 255, 255;
            // --palette-textPrimary: 255, 255, 255;
            // --palette-error: 173, 46, 46;
            // --palette-textInput: 169, 169, 169;
            // --palette-textLink: 169, 169, 169;
            --palette-superTokensBrandingBackground: 200,  0, 0, 0.001;
            --palette-superTokensBrandingText: 200,  0, 0, 0.001;
        }
      [data-supertokens~=secondaryLinkWithLeftArrow] {
         display: none;
      }
  `,
};

export const recipeDetails = {
  docsLink: 'https://supertokens.com/docs/emailpassword/introduction',
};

export const PreBuiltUIList = [ PasswordlessPreBuiltUI, EmailPasswordPreBuiltUI ]; // [EmailPasswordPreBuiltUI];

export const ComponentWrapper = ( props: { children: JSX.Element } ): JSX.Element => {
  return props.children;
};
