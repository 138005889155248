/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import { Card, CardActionArea, CardContent, Link, Pagination, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { AnomalyReportDocument, AnomalyReportEntry, getAnomalyReports } from './reports';
import styles from './styles/Home.module.css';
// import { useDemoData } from '@mui/x-data-grid-generator';
import { getYear } from 'date-fns/getYear';

export type FlattenedAnomalyReportEntry = AnomalyReportEntry & { reportId: string; description: string }; // NB: not the same as ReportParams.reportId

const logo = '/OG side by side logo.png';

export const ReportList: FC = () => {
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );
  const isSmall = useMediaQuery( theme.breakpoints.down( 'md' ) );
  const [ urlSearchParams, _setUrlSearchParams ] = useSearchParams();
  const token = useMemo<string | null>( () => urlSearchParams.get( 't' ), [ urlSearchParams ] );

  const [ isLoaded, setIsLoaded ] = useState( false );
  const [ reports, setReports ] = useState<Omit<AnomalyReportDocument, 'reports'>[]>( [] );
  const intervals = [ 'Daily', 'Weekly', 'Monthly' ];
  const [ intervalPages, setIntervalPages ] = useState<Record<string, number>>( Object.fromEntries( intervals.map( i => [ i, 1 ] ) ) );
  const pageSize = isSmall ? 3 : 5;
  const isFake = !!( reports.length && reports[ 0 ].fake );

  useEffect( () => {
    ( async () => {
      if( isLoaded ) return;
      const docs = await getAnomalyReports( token );
      setIsLoaded( true );
      setReports( docs );
    } )()
  }, [ token ] );

  const handleIntervalPageChange = useCallback( ( interval: string ) => ( event: React.ChangeEvent<unknown>, value: number ) => {
    setIntervalPages( { ...intervalPages, [ interval ]: value } );
  }, [ intervalPages, setIntervalPages ] )

  return (
    <Box sx={{
      padding: { xs: 0, md: '0 1.5rem' },
      maxWidth: '1280px',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: { xs: '1rem', md: '1.2rem' },
      backgroundColor: '#f9fafb',
      // fontFamily: fontBody,

    }}>
      <Helmet>
        <title>Anomaly Reports List{isFake ? ' DEMO' : ''}</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <main
        className={styles.main}
        style={{
          fontFamily: undefined, // fontBody
          maxWidth: 1100,
        }}
      >

        {/* <TopSection {...{ location, organization, fontHeader, colorPrimary, isXSmall }} /> */}

        <Grid item key="content"
          sx={{
            width: '100%',
          }}
        >
          {isFake &&
            <Box key='logo'>
              <img src={logo} height={isSmall ? 70 : 100} style={{ margin: '1em auto -1em', display: 'block' }} />
            </Box>
          }
          <Typography variant='h6'
            className={styles.description}
            sx={{
              margin: isSmall ? '1em 0 0.5em' : '1em 0 2em',
              fontWeight: 'bold',
              color: '#5f708f',
            }}
          >
            Anomaly Reports List
            { /* reports.length && reports[ 0 ].fake ? ' (Anonymized Data)' : '' */}
            {reports.length && reports[ 0 ].fake
              ? (
                <>
                  <br />
                  <span style={{ fontSize: '80%' }} >
                    &mdash; Anonymized Data &mdash;
                  </span>
                </>
              )
              : null
            }
          </Typography>

          <Grid container direction='row'
            spacing={2}
          // justifyContent='space-between'
          >

            {intervals.map( interval => {
              const intervalReports = reports.filter( r => r.reportIntervalType == interval );
              const pageCount = Math.ceil( intervalReports.length / pageSize );
              const intervalPage = intervalPages[ interval ] || 0;
              const pageReports = intervalReports.slice( ( intervalPage - 1 ) * pageSize, intervalPage * pageSize );
              if( intervalReports.length === 0 ) return null;
              return (
                <Grid item
                  key={interval}
                  sx={{
                    width: isSmall ? '100%' : undefined,
                  }}
                >
                  <Typography variant='h6' color='#5f708f' >{interval}</Typography>
                  <Stack
                    spacing={2}
                    sx={{
                      width: '100%',
                    }}
                  >
                    {pageReports.map( report => {
                      const isHigh = parseFloat( report.errorPercentageString ) >= 4;
                      return (
                        <Card key={report.id}>
                          <CardActionArea href={`./reports/${ report.id }${ token ? `?t=${ token }` : '' }`} >
                            <CardContent>
                              <Typography gutterBottom variant="h6" component="div">
                                {report.reportType}
                              </Typography>

                              <Grid container direction='column' justifyContent='space-between'>
                                <Grid item>
                                  <Typography variant="body2" color="text.secondary">
                                    <span
                                      style={{
                                        fontWeight: '600',
                                        color: isHigh ? 'red' : undefined,
                                      }}
                                    >
                                      {report.estimatedDollarLossString.replace( /\.\d\d$/, '' )}
                                    </span> Estimated Revenue Loss
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant="body2" color="text.secondary">
                                    <span
                                      style={{
                                        fontWeight: '600',
                                        color: isHigh ? 'red' : undefined,
                                      }}
                                    > {report.errorPercentageString}%  Error </span> (
                                    {report.appointmentsWithErrors} of {report.totalAppointmentsAnalyzed} Appointments)
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant="body2" color="text.secondary">
                                    Ending {report.humanAppointmentStopTime}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      );
                    } )}
                  </Stack>
                  {pageCount > 1 &&
                    <Pagination
                      count={pageCount}
                      page={intervalPages[ interval ]}
                      onChange={handleIntervalPageChange( interval )}
                      color='primary'
                      shape='rounded'
                      sx={{
                        marginTop: '1em',
                      }}
                    />
                  }
                </Grid>
              )
            } )}
          </Grid>

          <footer
            className={styles.footer}
            style={{
              maxWidth: 'inherit',
            }}
          >
            <Typography variant="caption" color="textSecondary"  >
              &copy;
              &nbsp;
              2021-{getYear( new Date() )}
              &nbsp;
              <Link href="https://analoginformatics.com/" target="_blank" rel="noopener" color="inherit" >
                Analog Informatics Corporation
              </Link>
            </Typography>
          </footer>
        </Grid>
      </main>


    </Box >
  )
}

