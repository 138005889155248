/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license';
import { titleize } from 'inflection';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import * as reactRouterDom from 'react-router-dom';
import { createBrowserRouter, Navigate, Outlet, redirect, RouterProvider } from 'react-router-dom';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import { PasswordlessPreBuiltUI } from 'supertokens-auth-react/recipe/passwordless/prebuiltui';
import { SessionAuth } from 'supertokens-auth-react/recipe/session/index.js';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import { setLicenseKey as setSurveyLicenseKey } from 'survey-core';
import { Appointment } from './Appointment';
import { AppointmentCancel } from './AppointmentCancel';
import { AppointmentConfirmed } from './AppointmentConfirmed';
import { AppointmentContainer, prettyShortDateTime, ProfileAppointmentContainer } from './AppointmentContainer';
import { AppointmentLocations } from './AppointmentLocations';
import { AppointmentLocationSchedule } from './AppointmentLocationSchedule';
import { AppointmentReschedule } from './AppointmentReschedule';
import { AppointmentSchedule } from './AppointmentSchedule';
import { AppointmentsIndex } from './AppointmentsIndex';
import { SupertokensUiCustomization, AuthPage } from './AuthPage';
import { CameraExample } from './CameraExample';
import { Capture } from './Capture';
import { ErrorPage } from './ErrorPage';
import { Feedback } from './Feedback';
import { FeedbackForm } from './FeedbackForm';
import { FeedbackFormThankYou } from './FeedbackFormThankYou';
import { FeedbackNonProfits } from './FeedbackNonProfits';
import { FeedbackRecommend } from './FeedbackRecommend';
import { FeedbackReviews } from './FeedbackReviews';
import { FeedbackSurvey } from './FeedbackSurvey';
import i18n from './i18n';
import './index.css';
import { Loc, LocAbout, LocAmenity, LocIndex, LocNearbyDetail, LocNearbyDirectory, LocRideShare } from './Loc';
import { LocMatch, Locs, LocsIndex } from './Locs';
import { Orgs, OrgsIndex } from './Orgs';
import { PatientContainer } from './PatientContainer';
import { Profile } from './Profile';
import { ProfileContainer } from './ProfileContainer';
import { ProfileCreate } from './ProfileCreate';
import { ProfileFormContainer } from './ProfileFormContainer';
import { ProfileFormsContainer } from './ProfileFormsContainer';
import { ProfileFormsIndex } from './ProfileFormsIndex';
import { ProfileFormUpdate } from './ProfileFormUpdate';
import { ProfileFormView } from './ProfileFormView';
import { AppointmentData, getAppointment, getAppointmentProfile, getAppointmentRescheduleSlots, getFormDefinition, getLocation, getLocations, getLocationScheduleSlots, getNearbys, getProfileAppointments, getProfileForms, getProfileScheduleSlots, getSmartConfiguration, LocationData, Retail } from './profiles';
import { ProfileSearch } from './ProfileSearch';
import { ProfileThankYou } from './ProfileThankYou';
import { Report } from './Report';
import { ReportList } from './ReportList';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { SmartLaunch } from './SmartLaunch';
import { SmartProvider } from './SmartProvider';
import { ComponentWrapper, superTokensConfig } from './supertokens-config';
import { SurveyExample } from './SurveyExample';
import { Unsubscribe } from './Unsubscribe';
import { FeedbackContainer } from './FeedbackContainer';


LicenseInfo.setLicenseKey( '3d4c088f780d581c43d95cc7a5b4e0edTz0xMDE4NjQsRT0xNzYzMjgwMjU3MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y' );
setSurveyLicenseKey( 'ZjY4YjAxMzAtMGFjZC00MmQ2LTg5NmQtMmMxYWYyMzg0YzY0OzE9MjAyNS0wMS0xNywyPTIwMjUtMDEtMTcsND0yMDI1LTAxLTE3' );

const isDirectory = !!location.hostname.match( /^(www.)?analog/ );
if( !isDirectory ) {
  SuperTokens.init( superTokensConfig );
}

const theme = createTheme( {
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 300,
  //     md: 600,
  //     lg: 900,
  //     xl: 1200,
  //   },
  // },
} );



const router = isDirectory
  ? createBrowserRouter( [
    {
      path: '/',
      loader: async () => {
        return redirect( '/directory' );
      },
    },
    {
      path: '/directory',
      errorElement: <ErrorPage />,
      element: <Orgs />,
      id: 'orgs',
      // handle: {
      //   breadcrumb: 'Home',
      // },
      children: [
        {
          index: true,
          element: <OrgsIndex />,
        },
      ]
    },
  ] )
  : createBrowserRouter( [

    // https://github.com/supertokens/supertokens-auth-react/issues/581
    ...( isDirectory
      ? []
      : getSuperTokensRoutesForReactRouterDom( reactRouterDom, [ PasswordlessPreBuiltUI ] ).map( ( r ) => r.props )
    ),

    {
      path: '/auth',
      element: <AuthPage />,
    },
    {
      path: '/',
      loader: async () => {
        if( location.hostname.match( /^(www.)?analog/ ) ) { // local // match analoginf* or www.analoginfo* or analog-dev.analoginfo*
          return redirect( '/directory' );
        }
        return redirect( '/locations' );
      },
    },
    {
      path: '/directory',
      errorElement: <ErrorPage />,
      element: <Orgs />,
      id: 'orgs',
      // handle: {
      //   breadcrumb: 'Home',
      // },
      children: [
        {
          index: true,
          element: <OrgsIndex />,
        },
      ]
    },
    {
      path: '/locations',
      errorElement: <ErrorPage />,
      element: <Locs />,
      id: 'locs', // for useRouteLoaderData
      loader: async () => getLocations(),
      handle: {
        breadcrumb: 'Locations',
      },
      children: [
        {
          index: true,
          element: <LocsIndex />,
        },
        {
          path: ':locationId',
          id: 'loc', // for useRouteLoaderData
          element: <Loc />, // has common page layout w Outlet for children
          loader: async ( { params } ) => getLocation( params.locationId ),
          handle: {
            // @ts-ignore:2344
            // breadcrumb: ( { data }: LocMatch<LocationData> ) => <Typography component='span' className='translated'>{data.location.name}</Typography>,
            breadcrumb: ( { data }: LocMatch<LocationData> ) => data.location.name,
          },
          // loader: async ( { params } ) => {
          //   return {};
          // },
          children: [
            {
              index: true,
              element: <LocIndex />  // has detail for location            
            }, {
              path: 'about-us',
              element: <LocAbout />,
              handle: {
                breadcrumb: 'About us',
              },
            }, {
              path: 'amenities/ride-share',
              element: <LocRideShare />,
              handle: {
                breadcrumb: 'Ride Share',
              },
            }, {
              path: 'amenities/:tagId',
              element: <LocAmenity />,
              handle: {
                breadcrumb: ( { params }: LocMatch ) => titleize( ( params.tagId || '' ).replaceAll( /-/g, ' ' ) ),
              },
            }, {
              path: 'nearby/:directoryId',
              id: 'loc-nearbys', // for useRouteLoaderData
              loader: async ( { params } ) => ( await getNearbys( params.locationId ) )[ params.directoryId || 'pharmacy' ],
              handle: {
                breadcrumb: ( { params }: LocMatch<Record<string, Retail[]>> ) => {
                  const { directoryId = '' } = params;
                  return titleize( directoryId );
                },
              },
              children: [
                {
                  index: true,
                  element: <LocNearbyDirectory />,
                }, {
                  path: ':name?/:address?/:nearbyId', // optionals allow seo worthy segments but only nearbyId is used
                  element: <LocNearbyDetail />,
                  handle: {
                    breadcrumb: ( { parent, params }: LocMatch ) => {
                      const { directoryId = '', nearbyId } = params;
                      // @ts-ignore:7006
                      const nearby = ( parent?.data || [] ).find( n => n.id == nearbyId );
                      return nearby?.name || nearbyId || 'unknown';
                    },
                  },
                },
              ]
            },
          ],

        },
      ]
    },

    {
      path: '/app',
      element: (
        <SmartProvider>
          <div id='slash'>
            <Outlet />
          </div>
        </SmartProvider >
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <div>Hello world!</div>,
        },

        {
          path: 'capture',
          element: <Capture />,
        },
        {
          path: 'example',
          element: <CameraExample />,
        },
        {
          path: 'forms',
          children: [
            {
              index: true,
              element: <SurveyExample />,
            },
            {
              path: ':id',
              loader: async ( { params } ) => await getFormDefinition( params.id ),
              element: <SurveyExample />,
            }
          ]
        },

        // Transition entry from /appointments/:id => /app/profiles/:id/appointments/:id
        {
          path: 'appointments/:appointmentId',
          loader: async ( { params } ) => {
            const profileId = await getAppointmentProfile( params.appointmentId );
            return redirect( `/app/profiles/${ profileId }/appointments/${ params.appointmentId }` )
          },
        },

        {
          path: 'feedback',
          element: (
            <div>
              <Outlet />
            </div>
          ),
          children: [
            {
              path: ':feedbackId',
              element: <FeedbackContainer />,
              children: [
                {
                  index: true,
                  element: <FeedbackRecommend />,
                },
                {
                  path: 'recommend',
                  element: <Feedback />,
                },
                {
                  path: 'nonprofits',
                  element: <FeedbackNonProfits />,
                },
                {
                  path: 'reviews',
                  element: <FeedbackReviews />,
                },
                {
                  path: 'form/:severity?',
                  element: <FeedbackForm />,
                },
                {
                  path: 'form/thank-you',
                  element: <FeedbackFormThankYou />,
                },
                {
                  path: 'survey',
                  element: <FeedbackSurvey />,
                },
              ],
            },
          ],
        },
        {
          path: 'launch',
          loader: async () => await getSmartConfiguration(),
          element: <SmartLaunch />,
          errorElement: <ErrorPage />,
        },
        {
          path: 'patient',
          element: <PatientContainer />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/app/profiles',
          id: 'profiles',
          loader: async () => getLocations(),
          element: (
            < div id='profiles' >
              <Outlet />
            </div >
          ),
          errorElement: <ErrorPage />,
          children: [
            {
              index: true,
              element: <Navigate to='lookup' />,
              errorElement: <ErrorPage />,
            },
            {
              path: 'logged-out',
              element: <ProfileThankYou />,
              errorElement: <ErrorPage />,
            },
            {
              path: 'lookup',
              loader: async () => await getLocations(),
              element: <ProfileSearch />,
              errorElement: <ErrorPage />,
              children: [
                {
                  index: true,
                  element: (
                    <GoogleReCaptchaProvider
                      reCaptchaKey='6Ldf2l0nAAAAANoIt6BSHLRb7JFoA5W5Jlk7IbMv'
                      useRecaptchaNet={false}
                      useEnterprise
                      scriptProps={{
                        async: false, // optional, default to false,
                        defer: false, // optional, default to false
                        appendTo: 'head', // optional, default to 'head', can be 'head' or 'body',
                        // nonce: undefined // optional, default undefined
                      }}
                    >
                      <ProfileCreate />
                    </GoogleReCaptchaProvider>
                  ),
                  errorElement: <ErrorPage />,
                },
              ],
            },
            {
              path: ':profileId',
              element: <ProfileContainer />,
              // loader: async ( { params } ) => await getProfile( params.profileId ),
              errorElement: <ErrorPage />,
              handle: { breadcrumb: 'Profile' },
              children: [
                {
                  index: true,
                  element: (
                    <Profile />
                  ),
                  errorElement: <ErrorPage />,
                },
                {
                  path: 'manageProfile',
                  element: <Navigate to='..' />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: 'forms',
                  loader: async ( { params } ) => await getProfileForms( params.profileId ),
                  element: <ProfileFormsContainer />,
                  errorElement: <ErrorPage />,
                  id: 'forms',
                  handle: { breadcrumb: 'Forms' },
                  children: [
                    {
                      index: true,
                      element: <ProfileFormsIndex />,
                    },
                    {
                      path: ':formId',
                      element: <ProfileFormContainer />,
                      handle: {
                        breadcrumb: ( { params }: { params: { formId: string } } ) => params.formId,
                      },
                      children: [
                        {
                          index: true,
                          // path: 'view',
                          element: <ProfileFormView />,
                          // handle: { breadcrumb: 'View' },
                        },
                        {
                          path: 'update',
                          element: <ProfileFormUpdate />,
                          handle: { breadcrumb: 'Update' },
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'appointments',
                  element: <ProfileAppointmentContainer />,
                  errorElement: <ErrorPage />,
                  loader: async ( { params } ) => await getProfileAppointments( params.profileId ),
                  id: 'appointments', // for useRouteLoaderData
                  handle: { breadcrumb: 'Appointments' },
                  children: [
                    {
                      index: true,
                      element: <AppointmentsIndex />,
                    },
                    {
                      path: 'schedule',
                      loader: async ( { params } ) => await getProfileScheduleSlots( params.profileId ),
                      element: <AppointmentSchedule />,
                      errorElement: <ErrorPage />,
                      handle: { breadcrumb: 'Schedule New' },
                    },
                    {
                      path: 'locations',
                      id: 'appointment-locations',
                      loader: async () => getLocations(),
                      children: [
                        {
                          index: true,
                          element: <AppointmentLocations />,
                          errorElement: <ErrorPage />,
                          handle: { breadcrumb: 'Locations' },
                        },
                        {
                          path: ':locationId/schedule',
                          loader: async ( { params } ) => await getLocationScheduleSlots( params.profileId, params.locationId ),
                          element: <AppointmentLocationSchedule />,
                          errorElement: <ErrorPage />,
                          // handle: {breadcrumb: ( { data }: LocMatch<LocationData> ) => data.location.name,},
                        }
                      ],
                    },
                    {
                      path: ':appointmentId',
                      id: 'appointment',
                      element: <AppointmentContainer />,
                      loader: async ( { params } ) => getAppointment( params.appointmentId ),
                      handle: {
                        // @ts-ignore:2344
                        breadcrumb: ( { data }: LocMatch<AppointmentData> ) => prettyShortDateTime( data.appointment.startTime, data.location.timeZoneName ),
                      },
                      children: [
                        {
                          index: true,
                          element: <Appointment />  // has detail for appointment            
                        }, {
                          path: 'reschedule',
                          id: 'appointment-reschedule',
                          element: <AppointmentReschedule />,
                          loader: async ( { params } ) => getAppointmentRescheduleSlots( params.appointmentId ),
                          handle: {
                            breadcrumb: 'Reschedule',
                          },
                        }, {
                          path: 'confirmed',
                          element: <AppointmentConfirmed />,
                          handle: {
                            breadcrumb: 'Confirmed',
                          },
                        }, {
                          path: 'cancelled',
                          element: <AppointmentCancel />,
                          handle: {
                            breadcrumb: ( { params }: LocMatch ) => 'Cancelled',
                          },
                        },
                      ],
                    },
                  ]
                },
                {
                  path: 'unsubscribe',
                  element: <Unsubscribe />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: 'unsubscribeSms',
                  element: <Unsubscribe />,
                  errorElement: <ErrorPage />,
                }
              ]
            },
          ]
        },


        {
          path: '/app/reports',
          element: (
            <SessionAuth>
              <div id='reports'>
                <Outlet />
              </div>
            </SessionAuth>
          ),
          errorElement: <ErrorPage />,
          children: [
            {
              path: '/app/reports',
              index: true,
              element: <ReportList />,
              errorElement: <ErrorPage />,
            },
            {
              path: '/app/reports/:reportId',
              element: <Report />,
              errorElement: <ErrorPage />,
            }
          ]
        },

      ]
    },
  ], {} );


// export const ServiceWorkerNotification: FC<{ message?: string }> = ( { message } ) => {
//   const [ open, setOpen ] = useState( false );
//   const handleClose = () => { setOpen( false ) };
//  
//   return (
//     <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
//       <Alert onClose={handleClose} severity='info' sx={{ width: '100%' }}>
//         {message}
//       </Alert>
//     </Snackbar>
//   );
//  
// };



ReactDOM.createRoot( document.getElementById( 'root' ) as HTMLElement ).render(
  <StrictMode>
    <>
      <CssBaseline />
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n} defaultNS={'translation'}>
            {isDirectory
              ? (
                <RouterProvider router={router} />
              )
              : (
                <SuperTokensWrapper>
                  <SupertokensUiCustomization>
                    <ComponentWrapper>
                      <RouterProvider router={router} />
                      {/* <ServiceWorkerNotification /> */}
                    </ComponentWrapper>
                  </SupertokensUiCustomization>
                </SuperTokensWrapper>
              )
            }
          </I18nextProvider>
        </ThemeProvider>
      </HelmetProvider>
    </>
  </StrictMode>
)

// Solves CSP unsafe-eval error
// https://github.com/exceljs/exceljs/issues/1411#issuecomment-977578401
// https://github.com/facebook/regenerator/issues/378
// @ts-ignore: 7017
globalThis.regeneratorRuntime = undefined

// Disable the PWA
serviceWorkerRegistration.unregister();




