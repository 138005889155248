import { Save } from '@mui/icons-material';
import { Box, Button, CircularProgress, Pagination, Toolbar } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { File, OnDocumentLoadSuccess } from 'react-pdf/dist/cjs/shared/types';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { useOutletContext } from 'react-router-dom';
import 'survey-core/modern.min.css';
import { SurveyPDF } from 'survey-pdf';
import { ProfileFormContext } from './ProfileFormContainer';

pdfjs.GlobalWorkerOptions.workerSrc = new URL( 'pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url, ).toString();

export const ProfileFormView: FC = () => {
  const profileFormContext = useOutletContext<ProfileFormContext>();
  const { profileForm, setError } = profileFormContext || {};
  const { formName, formJson, submission } = profileForm || {};
  const [ isStarted, setIsStarted ] = useState( false );
  const [ pdf, setPdf ] = useState<File | undefined>();
  const [ numPages, setNumPages ] = useState<number>();
  const [ pageNumber, setPageNumber ] = useState<number>( 1 );

  const form = useMemo( () => {
    if( !formJson ) return;
    try {
      return JSON.parse( formJson );
    } catch( e ) {
      setError( 'Error reading form data' );
    }
  }, [ formJson ] );

  const savePdf = useCallback( () => {
    if( !form ) return;

    const surveyPdf = new SurveyPDF( form, {
      fontSize: 10,
      // commercial: true,
      // haveCommercialLicense: true,
      applyImageFit: true,
    } );
    surveyPdf.data = submission;
    surveyPdf.save( `${ formName }.pdf` );
  }, [ form, submission ] );

  useEffect( () => {
    ( async () => {
      if( isStarted || pdf || !form || !submission ) return;
      console.log( 'pdf' );
      setIsStarted( true );
      const surveyPdf = new SurveyPDF( form, {
        fontSize: 10,
        // commercial: true,
        // haveCommercialLicense: true,
        applyImageFit: true,
      } );
      surveyPdf.data = submission;
      surveyPdf.mode = 'display';
      const blob = ( await surveyPdf.raw( 'blob' ) ) as unknown as Blob;
      const raw = await blob.arrayBuffer();
      setPdf( { data: raw } );
    } )();
  }, [ form, submission, pdf, isStarted, setIsStarted ] );

  const onDocumentLoadSuccess: OnDocumentLoadSuccess = ( { numPages } ) => {
    setNumPages( numPages );
    setPageNumber( 1 );
  }

  if( !pdf ) return <CircularProgress />;

  return (
    <Box>

      <Toolbar disableGutters >
        {numPages &&
          <Pagination
            count={numPages}
            onChange={( _e, newPage ) => setPageNumber( newPage )}
            variant='outlined'
            shape='rounded'
            color='primary'
            className='translated'
          // siblingCount={2}
          // boundaryCount={0}
          />
        }
        <Box sx={{ flexGrow: 1 }} />
        <Button
          color='primary'
          startIcon={<Save />}
          onClick={() => savePdf()}
        >
          Save as PDF
        </Button>
      </Toolbar>

      <Document
        file={pdf}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<CircularProgress />}
        className='translated'
      // onItemClick={( { dest, pageIndex, pageNumber } ) => alert( 'Clicked an item from page ' + pageNumber + '!' )}
      >
        <Page
          pageNumber={pageNumber}
          renderAnnotationLayer
          renderTextLayer
          width={900}
        />
      </Document>

    </Box >
  );

};

