/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC } from 'react';
import { useHref, useOutletContext } from 'react-router-dom';
import { FeedbackContext } from './FeedbackContainer';
import { ReviewButtons } from './components/ReviewButtons';
import styles from './styles/Home.module.css';

export const FeedbackReviews: FC = () => {
  const { feedback, location, practitioner, disableCharities, selectedOption, reputationServices } = useOutletContext<FeedbackContext>();
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

  const hrefPrevious = disableCharities ? useHref( '../recommend', { relative: 'path' } ) : useHref( '../nonprofits', { relative: 'path' } );

  // if( !( recipient && organization && location && practitioner && feedback ) ) return null;

  return (
    <Box sx={{
    }}>
      <Grid item key="content">
        <Typography variant='h6' className={styles.description}
          sx={{ margin: '0.5em 0' }}
        >
          LEAVE A REVIEW
        </Typography>

        <p>
          We encourage you to post a review on one or more of these independent sites. Clicking will open a new browser tab. Come back here to leave a review on another.            &nbsp;
          {selectedOption &&
            <>
              As you do, we will make a donation to:
            </>
          }
        </p>
        {selectedOption &&
          <Box sx={{ margin: '1em auto', textAlign: 'center' }} >
            <Box sx={{ width: 'fit-content', margin: 'auto' }}>
              <img
                src={selectedOption.logo_url}
                alt={`${ selectedOption.name } logo`}
                style={{
                  maxWidth: '120px',
                  maxHeight: '120px',
                }}

              />
            </Box>
            <Typography sx={{ fontWeight: 'bold' }} className='translated'>
              {selectedOption.name}
            </Typography>
          </Box>
        }


        <ReviewButtons subject={location} feedback={feedback} reputationServices={reputationServices} />

        {practitioner.isExternalReviewEnabled &&

          <ReviewButtons subject={practitioner} feedback={feedback} reputationServices={reputationServices} />
        }


        {/* <p>
            Clicking on any of these card will open a new browser tab. Come back here to leave a review on another.
            </p>
          */}
        <Grid item container key="nextButton" justifyContent="flex-start" sx={{ marginTop: '4em' }}>
          {!isXSmall &&
            <Button variant="outlined" size="large" href={hrefPrevious} fullWidth={isXSmall}>
              Previous
            </Button>
          }
        </Grid>

      </Grid>
    </Box>
  )
}

