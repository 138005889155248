import { Box, CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { client } from 'fhirclient';
import { dump } from 'js-yaml';
import { jwtDecode } from 'jwt-decode';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSmartContext } from './SmartProvider';

type Client = ReturnType<typeof client>;

export const promisedSleep: ( timeToSleep: number ) => Promise<string> = (
  timeToSleep: number
) => {
  return new Promise( ( resolve ) => {
    setTimeout( () => {
      resolve( `Sleep time of ${ timeToSleep } ms completed` );
    }, timeToSleep );
  } );
};

export const PatientContainer: FC = () => {
  const { pathname } = useLocation();
  const smartContext = useSmartContext();
  const { client: smartClient } = smartContext;
  // const [ profile, setProfile ] = useState<ProfileData | undefined>();
  //  
  // useEffect( () => {
  //   ( async () => {
  //     const profile = await getPatient( smartClient?.state?.tokenResponse );
  //     setProfile( profile );
  //   } )();
  // }, [ setProfile ] );
  const decodedTokens = useMemo( () => {
    if( !smartClient?.state?.tokenResponse?.access_token ) return;
    const { access_token, id_token } = smartClient.state.tokenResponse;
    localStorage.setItem( 'smartTokenResponse', JSON.stringify( smartClient.state.tokenResponse ) );
    return {
      access_token: jwtDecode( access_token, { header: false } ),
      id_token: id_token ? jwtDecode( id_token, { header: false } ) : undefined,
    };
  }, [ smartClient ] );
  const navigate = useNavigate();
  const [ gone, setGone ] = useState( false );
  useEffect( () => {
    ( async () => {
      if( gone || !smartClient?.state.tokenResponse?.patient ) return;
      setGone( true );
      const url = `/app/profiles/${ smartClient.state.tokenResponse.patient }`;
      console.log( pathname, '->', url );
      await promisedSleep( 1500 ); // added this to prevent token nbf errors !!??!!
      navigate( url ); // , { relative: 'path' } );
    } )();
  }, [ smartClient, setGone ] );

  return <CircularProgress />;
  // return (
  //   <Box>
  //     <Typography>
  //       PatientContainer
  //     </Typography>
  //     <CircularProgress />
  //   </Box>
  // );

  // return (
  //   <Box
  //     id='patient'
  //     sx={{
  //       padding: { xs: '0 1.5rem' },
  //       // maxWidth: { xs: '100%', md: '960px' },
  //       //  
  //       // marginLeft: 'auto',
  //       // marginRight: 'auto',
  //       fontSize: { xs: '1rem', md: '1.2rem' },
  //       backgroundColor: '#f9fafb',
  //     }}>
  //     <Typography>
  //       PatientContainer
  //     </Typography>
  //     <CircularProgress />
  //  
  //     {profile &&       <Typography variant='h6'>
  //       Profile
  //       </Typography>
  //  
  //       <Typography component='pre'>
  //       {dump( profile )}
  //       </Typography>
  //      }
  //  
  //     {decodedTokens?.access_token &&
  //       <Box>
  //         <Typography variant='h6'>
  //           Decoded access_token
  //         </Typography>
  //  
  //         <Typography component='pre'>
  //           {dump( decodedTokens.access_token )}
  //         </Typography>
  //       </Box>
  //     }
  //  
  //     {decodedTokens?.id_token &&
  //       <Box>
  //         <Typography variant='h6'>
  //           Decoded id_token
  //         </Typography>
  //         <Typography component='pre'>
  //           {dump( decodedTokens.id_token )}
  //         </Typography>
  //       </Box>
  //      }
  //  
  //  
  //     <Typography variant='h6'>
  //       State
  //     </Typography>
  //     {smartClient?.state &&
  //       <Typography component='pre'>
  //         {dump( smartClient.state )}
  //       </Typography>
  //     }
  //  
  //   </Box >
  // );
}
