import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

export interface ConfirmDialogProps {
  title: string;
  message: string;
  open: boolean;
  fullScreen?: boolean;
  onClose: ( confirmed: boolean ) => void;
  confirmButton?: string;
  cancelButton?: string;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ( props ) => {
  const { onClose, message, title, open, confirmButton = 'Yes', cancelButton = 'Cancel', fullScreen = false, ...other } = props;

  const handleCancel = () => {
    onClose( false );
  };

  const handleOk = () => {
    onClose( true );
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': fullScreen ? {} : { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      fullScreen={fullScreen}
      onClose={handleCancel}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={false}>
        <Typography>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          {cancelButton}
        </Button>
        {confirmButton !== '' &&
          <Button onClick={handleOk}>{confirmButton}</Button>
        }
      </DialogActions>
    </Dialog>
  );
}

