import Grid from '@mui/material/Grid';
import { CSSProperties, FC } from 'react';
import Carousel from 'react-material-ui-carousel';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

const image01 = '/charities/04-2296967.jpg';
const image02 = '/charities/06-0726487.jpg';
const image03 = '/charities/13-1788491.jpg';
const image04 = '/charities/13-5613797.jpg';
const image05 = '/charities/13-5644916-001.jpg';
const image06 = '/charities/20-2370934.jpg';
const image07 = '/charities/20-3021444.jpg';
const image08 = '/charities/23-7124261.jpg';
const image09 = '/charities/35-2464610.jpg';
const image10 = '/charities/53-0196517.jpg';
const image11 = '/charities/59-2174510.jpg';
const image12 = '/charities/91-1914868.jpg';

export interface NonProfitTickerProps {
  style?: CSSProperties;
}

export const NonProfitTicker: FC<NonProfitTickerProps> = ( _props ) => {

  return (
    <Carousel height='17em' indicators={false} duration={2000} interval={6000}  >
      <Grid container alignItems='center' justifyContent='space-between' spacing={{ xs: 1, sm: 5 }} sx={{ height: '19em' /*, backgroundColor: '#efe' /*, paddingLeft: '0em' */ }} columns={10} >
        <Grid item xs={4} sm={2}><img src={image03} alt='image03' key='image03' loading='eager' style={{ /* maxHeight: '80%', */ maxWidth: '100%', objectFit: 'contain' }} /></Grid>
        <Grid item xs={4} sm={2}><img src={image01} alt='image01' key='image01' loading='eager' style={{ /* maxHeight: '80%', */ maxWidth: '100%', objectFit: 'contain' }} /></Grid>
        <Grid item xs={4} sm={2}><img src={image04} alt='image04' key='image04' loading='eager' style={{ /* maxHeight: '80%', */ maxWidth: '100%', objectFit: 'contain' }} /></Grid>
        <Grid item xs={4} sm={2}><img src={image02} alt='image02' key='image02' loading='eager' style={{ /* maxHeight: '80%', */ maxWidth: '100%', objectFit: 'contain' }} /></Grid>
      </Grid>

      <Grid container alignItems='center' justifyContent='space-between' spacing={{ xs: 1, sm: 5 }} sx={{ height: '19em' /*, backgroundColor: '#eef' /*, paddingLeft: '0em' */ }} columns={10} >
        <Grid item xs={4} sm={2}><img src={image05} alt='image05' key='image05' loading='lazy' style={{ /* maxHeight: '80%', */ maxWidth: '100%', objectFit: 'contain' }} /></Grid>
        <Grid item xs={4} sm={2}><img src={image07} alt='image07' key='image07' loading='lazy' style={{ /* maxHeight: '80%', */ maxWidth: '100%', objectFit: 'contain' }} /></Grid>
        <Grid item xs={4} sm={2}><img src={image08} alt='image08' key='image08' loading='lazy' style={{ /* maxHeight: '80%', */ maxWidth: '100%', objectFit: 'contain' }} /></Grid>
        <Grid item xs={4} sm={2}><img src={image06} alt='image06' key='image06' loading='lazy' style={{ /* maxHeight: '80%', */ maxWidth: '100%', objectFit: 'contain' }} /></Grid>
      </Grid>

      <Grid container alignItems='center' justifyContent='space-between' spacing={{ xs: 1, sm: 5 }} sx={{ height: '19em' /*, backgroundColor: '#eff' /*, paddingLeft: '0em' */ }} columns={10} >
        <Grid item xs={4} sm={2}><img src={image10} alt='image10' key='image10' loading='lazy' style={{ /* maxHeight: '80%', */ maxWidth: '100%', objectFit: 'contain' }} /></Grid>
        <Grid item xs={4} sm={2}><img src={image09} alt='image09' key='image09' loading='lazy' style={{ /* maxHeight: '80%', */ maxWidth: '100%', objectFit: 'contain' }} /></Grid>
        <Grid item xs={4} sm={2}><img src={image12} alt='image12' key='image12' loading='lazy' style={{ /* maxHeight: '80%', */ maxWidth: '100%', objectFit: 'contain' }} /></Grid>
        <Grid item xs={4} sm={2}><img src={image11} alt='image11' key='image11' loading='lazy' style={{ /* maxHeight: '80%', */ maxWidth: '100%', objectFit: 'contain' }} /></Grid>
      </Grid>
    </Carousel >
  );
};

