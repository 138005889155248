/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import { Box, Button, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { FeedbackContext } from './FeedbackContainer';
import { FeedbackFormData, addFeedbackMessage } from './profiles';

export type FeedbackFormParams = {
  severity?: string;
};

export const FeedbackForm: FC = () => {
  const { feedbackId, feedback, recipient, location, practitioner, organization,
    colorPrimary, colorSecondary, fontBody, fontHeader,
    disableCharities, selectedOption, reputationServices,
    // setNotify, setError, setTitle, isXSmall, isSmall, isMedium,
    countryCode: regionCode = 'US'
  } = useOutletContext<FeedbackContext>();
  const { severity = 'notAtAll' } = useParams<FeedbackFormParams>();
  const navigate = useNavigate();
  const { control, getValues, handleSubmit, formState: { errors, isDirty, isValidating, isValid, isSubmitted, isSubmitting } } = useForm<FeedbackFormData>();

  const onSubmit = useCallback( async ( data: FeedbackFormData ) => {
    await addFeedbackMessage( feedbackId, { ...data, severity } );
    navigate( '../form/thank-you' );
  }, [ feedbackId ] );

  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

  if( !( recipient && organization && location && practitioner ) ) return null;

  return (
    <Box sx={{
    }}>

      <Typography
        align='left'
        sx={{
          margin: '2rem 0',
          width: '100%',
          fontStyle: 'italic',
          fontSize: '120%',
          color: colorPrimary || 'primary',
        }}
        color='inherit'
      >
        What was missing or disappointing in your experience with us?
      </Typography>

      <form
        style={{
          width: '100%',
          marginBottom: '1rem',
        }}
      >

        <Controller
          name='message'
          control={control}
          rules={{
            maxLength: {
              value: 3000,
              message: 'Maximum of 3000 characters'
            }
          }}
          render={( { field } ) => (
            <TextField
              {...field}
              inputRef={field.ref}
              margin='dense'
              label='Feedback'
              fullWidth
              variant='filled'
              multiline
              minRows={6}
              error={!!errors?.message?.message}
              helperText={errors?.message?.message}
            />
          )}
        />

      </form>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant='contained'
          disabled={!isDirty || isValidating || isSubmitting || isSubmitted}
          onClick={handleSubmit( onSubmit, ( v ) => { console.log( errors, v ) } )}
          sx={{ fontSize: '90%' }}
          color='success'
        >
          Submit
        </Button>
      </Toolbar>
    </Box>

  );
}

