import { EditCalendar, EventAvailable, EventBusy } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import { FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHref, useNavigate, useOutletContext } from 'react-router-dom';
import { AppointmentContext, scrollDown } from './AppointmentContainer';
import { WideButtonWithIcon } from './WideButtonWithIcon';
import { isFuture } from 'date-fns';
import { cancelAppointment, confirmAppointment } from './profiles';
import { ConfirmDialog } from './components/ConfirmDialog'
import { AppointmentInterface, LocationInterface } from './types';
import { Box, Typography } from '@mui/material';
import { useSmartContext } from './SmartProvider';

export type IsActionable = ( a: Pick<AppointmentInterface, 'status' | 'startTime' | 'appointmentTypeCode'>, loc: LocationInterface ) => boolean;
// export const codesBlockedForRescheduling = [ '2576', '2757', '9336', '9346', '2819', '10298', '2816', '2818' ]; // New Patients, Mohs, Excision
export const isConfirmable: IsActionable = ( a ) => [ 'proposed', 'pending' ].includes( a.status ) && isFuture( new Date( a.startTime ) );
export const isReschedulable: IsActionable = ( a, loc ) => !!a.appointmentTypeCode
  && !!loc.appointmentTypeCodesEnabledForReschedule?.includes( a.appointmentTypeCode )
  && ( [ 'proposed', 'pending', 'cancelled', 'booked' ].includes( a.status )
    && isFuture( new Date( a.startTime ) )
    || [ 'cancelled', 'noshow' ].includes( a.status )
  );
export const isCancellable: IsActionable = ( a, loc ) => !!a.appointmentTypeCode
  && !!loc.appointmentTypeCodesEnabledForCancel?.includes( a.appointmentTypeCode )
  && [ 'proposed', 'pending', 'booked' ].includes( a.status )
  && isFuture( new Date( a.startTime ) );
// export const isSchedulable = ( loc: LocationInterface ) => !!loc.appointmentTypeCodesEnabledForSchedule;

export const Appointment: FC = () => {
  const { appointment, location, organization, colorPrimary, setNotify, setError, setStatus, isXSmall, status, startTime } = useOutletContext<AppointmentContext>();
  const navigate = useNavigate();
  const sectionButtons = useRef<HTMLDivElement>( null );
  const [ openCancelDialog, setOpenCancelDialog ] = useState( false );
  const hrefReschedule = useHref( 'reschedule' );
  const { configuration: smartConfiguration } = useSmartContext();
  const { brand } = smartConfiguration || {};
  const actionsBlockedByEmrBrand = brand && [ 'OpenEmr' ].includes( brand );

  const { appointmentTypeCode } = appointment;
  const [ confirmable, reschedulable, cancellable ] = useMemo( () => [
    isConfirmable( { status, startTime, appointmentTypeCode }, location ) && !actionsBlockedByEmrBrand,
    isReschedulable( { status, startTime, appointmentTypeCode }, location ) && !actionsBlockedByEmrBrand,
    isCancellable( { status, startTime, appointmentTypeCode }, location ) && !actionsBlockedByEmrBrand,
  ], [ status, startTime, appointmentTypeCode, location ] );

  const { telephone } = location;

  useEffect( () => scrollDown( sectionButtons ) );

  const onClickConfirm = useCallback( async () => {
    const errors = await confirmAppointment( appointment.id );
    if( errors ) {
      setError( errors[ 0 ] );
      return;
    }
    setStatus( 'booked' );
    navigate( 'confirmed' );
  }, [ navigate, appointment ] );

  const onCancel = useCallback( async () => {
    const errors = await cancelAppointment( appointment.id );
    if( errors ) {
      setError( errors[ 0 ] );
      return;
    }
    setStatus( 'cancelled' );
    navigate( 'cancelled' );
  }, [ navigate, appointment ] );


  return (
    <Grid container
      ref={sectionButtons}
    >

      <Grid item container key="buttons"
        justifyContent="space-between"
        alignItems='flex-start'
        rowSpacing={4}
        direction='row'
        xs={12}
        sm={8}
        sx={{
          margin: '0 auto',
          // border: '1px solid purple',
        }}
      >
        {confirmable &&
          <Grid item key='1' xs={12}//  sm={'auto'}
          >
            <WideButtonWithIcon
              onClick={onClickConfirm}
              icon={<EventAvailable />}
              color={colorPrimary}
            >
              Confirm
            </WideButtonWithIcon>
          </Grid>
        }
        {reschedulable &&
          <Grid item key='2' xs={12} // sm={'auto'}
          >
            <WideButtonWithIcon
              href={hrefReschedule}
              icon={<EditCalendar />}
              color={colorPrimary}
            >
              Reschedule
            </WideButtonWithIcon>
          </Grid>
        }
        {cancellable &&
          <Grid item key='3' xs={12} // sm={'auto'}
          >
            <WideButtonWithIcon
              onClick={() => setOpenCancelDialog( true )}
              icon={<EventBusy />}
              color={colorPrimary}
            >
              Cancel
            </WideButtonWithIcon>
          </Grid>
        }
        {!cancellable && !reschedulable &&
          <Grid item key='4' xs={12} // sm={'auto'}
            sx={{
              width: '110%',
              padding: '1rem',
              paddingBottom: '2rem',
              marginTop: '2rem',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'colorPrimary',
              '& span': {
                whiteSpace: 'nowrap',
              }
            }}
          >
            <Typography fontSize='100%' fontWeight='bold' color={colorPrimary} align='center'>
              To reschedule your appointment, please call our office at <span>{telephone}</span>.
            </Typography>
            <Typography fontSize='100%' fontWeight='bold' color={colorPrimary} align='center'>
              We love our patients at {organization.name || location.name}!
            </Typography>
          </Grid>
        }


        <ConfirmCancelDialog
          open={openCancelDialog}
          close={() => setOpenCancelDialog( false )}
          onYes={onCancel}
          fullScreen={isXSmall}
        />

      </Grid>

    </Grid >
  );
}

export interface ConfirmCancelDialogProps {
  open: boolean;
  close: () => void;
  onYes: () => Promise<void>;
  fullScreen?: boolean;
}

export const ConfirmCancelDialog: FC<ConfirmCancelDialogProps> = props => {
  const { open, close, onYes, fullScreen } = props;

  return (
    <ConfirmDialog
      open={open}
      title={'Cancel appointment'}
      message="We've made it easy to reschedule an apppointment. Are you sure you want to cancel?"
      confirmButton='Yes'
      cancelButton='No'
      onClose={async ( isCancelConfirmed ) => {
        close();
        if( isCancelConfirmed ) {
          await onYes();
        }
      }}
      fullScreen={fullScreen}
    />

  );
}
