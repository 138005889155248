/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import { fhirclient } from 'fhirclient/lib/types';
import { DetailedHTMLProps, useRef, FC } from 'react';
import { Option } from './components/NonProfitSearch';
import { isGuid, isObjectId } from './lib/helpers';
import { ProfileCreateFormData } from './ProfileCreate';
import { ProfileSearchFormData } from './ProfileSearch';
import { AddressComponentsInterface, AppointmentInterface, AssetInterface, FeedbackInterface, LocationInterface, OrganizationInterface, PractitionerInterface, PractitionerSummary, RecipientInterface, ReputationServiceInterface, TagInterface, WeekdaysHours } from './types';

const {
  REACT_APP_API_SERVER: apiServer = '', // 'http://localhost:3400',
  ////  REACT_APP_IMAGE_SERVER: imageServer = apiServer,  // 'https://gunter.analoginformation.com/api/v1/image';
} = process.env;

export interface ProfileData {
  profileId: string;
  recipient: RecipientInterface;
  recipients: RecipientInterface[];
  recipientType: string;
  organization: OrganizationInterface;
  assets: AssetInterface[];
  countryCode: string;
  appointments: AppointmentInterface[];
  locations: LocationInterface[];
  practitioners: PractitionerSummary[];
}

const apiPath = `${ apiServer }/api/v1/data`;
//// const imagePath = `${ imageServer }/api/v1/image`;
////  
//// export const titleAlt = ( label: string ): { title: string, alt: string } => ( { title: label, alt: label } );
////  
//// export const Image: FC<DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>> = ( props ) => {
////   const { src, ...rest } = props;
////   const imgEl = useRef<HTMLImageElement>( null );
////   if( !src ) return null;
////   return <img ref={ imgEl } src = { buildImageUrl.call( imgEl.current, src ) } {...rest } />
//// };
////  
////  
//// // export const buildImageUrl = ( imageId: string, size?: { w: number, h: number } ): string => {
//// //   if( size ) return `${ imagePath }/${ imageId }?w=${ Math.round( size.w ) }&h=${ Math.round( size.h ) }`;
//// //   return `${ imagePath }/${ imageId }`;
//// // }
//// export const buildImageUrl = function( this: HTMLImageElement | null | undefined | void, imageId: string, size?: { w: number, h: number } ): string {
////   if( this ) {
////     const { width, height } = getComputedStyle( this );
////     const [ ww, hh ] = [ width, height ].map( x => Math.round( parseFloat( x ) * window.devicePixelRatio ) );
////     return `${ imagePath }/${ imageId }?w=${ ww }&h=${ hh }`;
////   }
////   if( size ) return `${ imagePath }/${ imageId }?w=${ Math.round( size.w ) }&h=${ Math.round( size.h ) }`;
////   return `${ imagePath }/${ imageId }`;
//// }

// export const getProfileId = async ( profileId: string | undefined ) => {
//   const s = localStorage.getItem( 'smartTokenResponse' );
//   let tokenResponse: fhirclient.TokenResponse = {};
//   try {
//     tokenResponse = s ? JSON.parse( s ) as fhirclient.TokenResponse : undefined;
//   } catch( e ) {
//     tokenResponse = {};
//   }
//   if( !isGuid( profileId ) || !tokenResponse ) return;
//   const { access_token, patient } = tokenResponse;
//   return patient;
// }

export const fetchProfileApiErrors = async <T>( path: string, init: RequestInit = {} ): Promise<T | undefined> => {
  const s = localStorage.getItem( 'smartTokenResponse' );
  let tokenResponse: fhirclient.TokenResponse = {};
  try {
    tokenResponse = s ? JSON.parse( s ) as fhirclient.TokenResponse : {};
  } catch( e ) {
    tokenResponse = {};
  }
  const { access_token } = tokenResponse;
  const { headers: initHeaders, ...rest } = init;
  const response = await fetch( `${ apiPath }/${ path }`, {
    ... {
      cache: 'no-cache',
      headers: {
        ...{ 'content-type': 'application/json' },
        ...( access_token ? { authorization: `Bearer ${ access_token }` } : {} ),
        ...( initHeaders || {} ),
      }
    },
    ...rest
  } );
  if( response.headers.get( 'content-length' ) == '0' ) return;
  const body = await response.json();
  if( body.errors ) return body.errors;
}

export const fetchProfileApi = async <T>( path: string, init: RequestInit = {} ): Promise<T & { errors: unknown } | undefined> => {
  const s = localStorage.getItem( 'smartTokenResponse' );
  let tokenResponse: fhirclient.TokenResponse = {};
  try {
    tokenResponse = s ? JSON.parse( s ) as fhirclient.TokenResponse : {};
  } catch( e ) {
    tokenResponse = {};
  }
  // if( !isGuid( profileId ) || !tokenResponse ) return;
  const { access_token, patient } = tokenResponse;
  const { headers: initHeaders, ...rest } = init;
  const response = await fetch( `${ apiPath }/${ path }`, {
    ... {
      cache: 'no-cache',
      headers: {
        ...{ 'content-type': 'application/json' },
        ...( access_token ? { authorization: `Bearer ${ access_token }` } : {} ),
        ...( initHeaders || {} ),
      }
    },
    ...rest
  } );
  return await response.json();
}

export const fetchProfileApiList = async <T>( path: string, init: RequestInit = {} ): Promise<T[]> => {
  const result = await fetchProfileApi( path, init );
  return Array.isArray( result ) ? result : [];
}



export interface SmartConfiguration {
  fhirSystemUrl?: string;
  clientId?: string;
  brand?: string;
}

export const getSmartConfiguration = async (): Promise<SmartConfiguration & { errors: unknown } | undefined> => {
  const response = await fetch( `${ apiPath }/smart`, { cache: 'no-cache' } );
  return await response.json();
}

interface GetPatientOpts {
  patient?: string;
  access_token?: string;
}

export const getProfile = async ( profileId: string | undefined ): Promise<ProfileData & { errors: unknown } | undefined> => {
  return fetchProfileApi( `profiles/${ profileId }` );
}

export type CommunicationTypeFieldNames = 'disableAppointmentReminders' |
  'disableAppointmentRealTimeUpdates' |
  'disableLabUpdates' |
  'disableOfficeCommunication' |
  'disablePractitionerCommunication' |
  'disablePrePostOpCareInstructions' |
  'disableBirthdays' |
  'disableMarketingUpdates' |
  'disableReviewReminders';

export interface PatientContactMethodFormData {
  value?: string;
  system?: string; // 'email' or for phone: phone or sms
}

export interface PatientRecipientFormData extends Pick<RecipientInterface, 'fullName' | 'recipientType' | CommunicationTypeFieldNames> {
  phone?: string;
  email?: string;
  preference?: string; // phone, sms, email
}

export function isPatientContactMethodFormDataField( field: keyof PatientContactMethodFormData | string ): field is keyof PatientContactMethodFormData {
  return !!( field as keyof PatientContactMethodFormData );
}
export function isPatientRecipientFormDataField( field: keyof PatientRecipientFormData | string ): field is keyof PatientRecipientFormData {
  return !!( field as keyof PatientRecipientFormData );
}

export interface ServerError {
  type: string;
  message: string;
}

export type PatientContactMethodFormDataHandlerResponse = undefined | Record<keyof PatientContactMethodFormData, ServerError>;
export type PatientContactMethodFormDataHandler = ( data: PatientContactMethodFormData ) => Promise<PatientContactMethodFormDataHandlerResponse>;

export type PatientRecipientFormDataHandlerResponse = undefined | Record<keyof PatientRecipientFormData, ServerError>;
export type PatientRecipientFormDataHandler = ( data: PatientRecipientFormData ) => Promise<PatientRecipientFormDataHandlerResponse>;

// export type ErrorResponse = { error: boolean; errors: Record<string, string>[] };  // TODO rework this to work with ServerError or GlobalError from react-hook-form

export type AddContactMethod = ( profileId: string | undefined, contactMethod: { system: 'phone' | 'email' | 'sms', value?: string } ) => Promise<PatientContactMethodFormDataHandlerResponse>;
export const addContactMethod: AddContactMethod = async ( profileId, { system, value } ) => {
  if( !profileId ) return;
  // console.log( system, value, use );
  if( value ) {
    return await fetchProfileApiErrors( `profiles/${ profileId }/contact-methods`, {
      method: 'POST',
      headers: {
        accept: 'application/json, */*;q=0.5',
      },
      body: JSON.stringify( { system, value } ),
    } );
    // const result = await fetch( `${ apiPath }/profiles/${ profileId }/contact-methods`, {
    //   method: 'POST',
    //   headers: { 'content-type': 'application/json', accept: 'application/json, */*;q=0.5' },
    //   body: JSON.stringify( { system, value } ),
    // } );
    // if( result.headers.get( 'content-length' ) == '0' ) return;
    // const body = await result.json();
    // console.log( body );
    // if( body.errors ) return body.errors;
  }
}

export const deleteContactMethod = async ( profileId: string | undefined, contactMethodId: string | undefined ) => {
  if( !profileId || !contactMethodId ) return;
  await fetchProfileApiErrors( `profiles/${ profileId }/contact-methods/${ contactMethodId }`, { method: 'DELETE' } );
}

export const unsubscribeAllContactMethods = async ( profileId?: string ) => {
  if( !profileId ) return;
  await fetchProfileApiErrors( `profiles/${ profileId }/contact-methods`, { method: 'DELETE' } );
}


export const moveContactMethod = async ( profileId: string | undefined, contactMethodId: string | undefined, move: 'up' | 'down' ): Promise<void> => {
  if( !profileId || !contactMethodId ) return;
  await fetchProfileApiErrors( `profiles/${ profileId }/contact-methods/${ contactMethodId }/move/${ move }`, { method: 'POST' } );
}

export const activateContactMethod = async ( profileId: string | undefined, contactMethodId: string | undefined, active: boolean ): Promise<void> => {
  if( !profileId || !contactMethodId ) return;
  await fetchProfileApiErrors( `profiles/${ profileId }/contact-methods/${ contactMethodId }/active/${ active ? 'on' : 'off' }`, { method: 'POST' } );
}

export const updateCommunicationType = async ( profileId: string | undefined, commType: CommunicationTypeFieldNames, value: boolean ) => {
  await fetchProfileApiErrors( `profiles/${ profileId }/communication-types`, {
    method: 'PATCH',
    body: JSON.stringify( { [ commType ]: value } ),
  } );
}

export const unsubscribeAllCommunicationTypes = async ( profileId: string | undefined ) => {
  await fetchProfileApiErrors( `profiles/${ profileId }/communication-types`, {
    method: 'PATCH',
    body: JSON.stringify( { disableAll: true } ),
  } );
}


export const addRecipient = async ( profileId: string | undefined, recipient: PatientRecipientFormData ): Promise<PatientRecipientFormDataHandlerResponse> => {
  if( !profileId || !recipient ) return;
  return await fetchProfileApiErrors( `profiles/${ profileId }/recipients`, {
    method: 'POST',
    body: JSON.stringify( recipient ),
  } );
}

export const updateRecipient = async ( profileId: string | undefined, recipientId: string | undefined, recipient: PatientRecipientFormData ): Promise<PatientRecipientFormDataHandlerResponse> => {
  if( !profileId || !recipientId || !recipient ) return;
  return await fetchProfileApiErrors( `profiles/${ profileId }/recipients/${ recipientId }`, {
    method: 'PATCH',
    body: JSON.stringify( recipient ),
  } );
}

export const deleteRecipient = async ( profileId: string | undefined, recipientId: string ) => {
  if( !profileId || !recipientId ) return;
  await fetchProfileApiErrors( `profiles/${ profileId }/recipients/${ recipientId }`, { method: 'DELETE' } );
}

export const unsubscribeAllRecipients = async ( profileId: string | undefined ) => {
  if( !profileId ) return;
  await fetchProfileApiErrors( `profiles/${ profileId }/recipients/unsubscribe`, { method: 'POST' } );
}

export const deleteAllRecipients = async ( profileId: string | undefined ) => {
  if( !profileId ) return;
  await fetchProfileApiErrors( `profiles/${ profileId }/recipients`, { method: 'DELETE' } );
}


export interface FeedbackData {
  feedback: FeedbackInterface;
  recipient: RecipientInterface;
  practitioner: PractitionerInterface;
  recipientType: string;
  location: LocationInterface,
  organization: OrganizationInterface;
  assets: AssetInterface[];
  reputationServices: ReputationServiceInterface[];
  countryCode: string;
  selectedOption?: Option;
}

export const getFeedback = async ( feedbackId: string | undefined ): Promise<FeedbackData | undefined> => {
  if( !isObjectId( feedbackId ) ) return;

  const response = await fetch( `${ apiPath }/feedback/${ feedbackId }`, { cache: 'no-cache' } );
  return await response.json();
}

export interface FeedbackFormData {
  message: string;
  severity: string;
}
export type FeedbackFormDataHandlerResponse = undefined | Record<keyof FeedbackFormData, ServerError>;
export type FeedbackFormDataHandler = ( data: FeedbackFormData ) => Promise<FeedbackFormDataHandlerResponse>;

export const addFeedbackMessage = async ( feedbackId: string | undefined, data: FeedbackFormData ): Promise<FeedbackFormDataHandlerResponse> => {
  if( !feedbackId || !data ) return;
  const result = await fetch( `${ apiPath }/feedback/${ feedbackId }/messages`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify( data ),
  } );
  if( result.headers.get( 'content-length' ) == '0' ) return;
  const body = await result.json();
  if( body.errors ) return body.errors;
}



export interface LocationData {
  location: LocationInterface,
  organization: OrganizationInterface;
  assets: AssetInterface[];
  commonTags: TagInterface[];
  countryCode: string;
}

export const getLocation = async ( locationId: string | undefined ): Promise<LocationData | undefined> => {
  // if( !isObjectId( locationId ) ) return;

  const response = await fetch( `${ apiPath }/locations/${ locationId }` );
  return await response.json();
}

export interface LocationsData {
  locations: LocationInterface[],
  organization: OrganizationInterface;
  assets: AssetInterface[];
  commonTags: TagInterface[];
  countryCode: string;
}

export const getLocations = async (): Promise<LocationsData | undefined> => {
  const response = await fetch( `${ apiPath }/locations` );
  return await response.json();
}

export interface Retail {
  id: string;
  name: string;
  categories?: string[];
  address?: string;
  phone?: string;
  website?: string;
  rating?: number;
  ratingCount?: number;
  pricesLevel?: number;
  weekdaysHours?: WeekdaysHours;
  images?: string[];
  distance?: string;
  addressComponents?: AddressComponentsInterface;
  location: {
    coordinates: number[];
  }
}

export const getNearbys = async ( location: string | LocationInterface | undefined ): Promise<Record<string, Retail[]>> => {
  if( !location ) return {};
  const locationId = typeof location == 'string' ? location : location.id;
  const response = await fetch( `${ apiPath }/locations/${ locationId }/nearbys` );
  return await response.json();
}

export interface ProfileAppointment {
  appointment: AppointmentInterface;
  practitioner: PractitionerSummary;
  location: LocationInterface;
}

export const getProfileAppointments = async ( profileId: string | undefined ): Promise<ProfileAppointment[]> => {
  if( !profileId ) return [];
  return fetchProfileApiList( `profiles/${ profileId }/appointments` );
}

export interface AppointmentData {
  appointment: AppointmentInterface;
  patient: Pick<RecipientInterface, 'firstName' | 'lastName'>;
  practitioner: PractitionerSummary;
  location: LocationInterface;
  organization: OrganizationInterface;
  assets: AssetInterface[];
  commonTags: TagInterface[];
  countryCode: string;
}

export const getAppointment = async ( appointmentId: string | undefined ): Promise<AppointmentData | undefined> => {
  if( !appointmentId ) return;

  const response = await fetch( `${ apiPath }/appointments/${ appointmentId }` );
  return await response.json();
}

export const getAppointmentProfile = async ( appointmentId: string | undefined ): Promise<string | undefined> => {
  if( !appointmentId ) return;

  const response = await fetch( `${ apiPath }/appointments/${ appointmentId }/profile` );
  return await response.json();
}

export interface ProfileScheduleSlot {
  id: string;
  schedule: {
    display: string;
  },
  status: string;
  start: string;
  end: string;
  location: string;
  practitioner: string;
  appointmentTypeCode: string;
}

export const getProfileScheduleSlots = async ( profileId: string | undefined ): Promise<ProfileScheduleSlot[]> => {
  if( !profileId ) return [];

  const response = await fetch( `${ apiPath }/profiles/${ profileId }/schedule` );
  return await response.json();
}

export type LocationScheduleSlot = ProfileScheduleSlot;

export interface LocationScheduleData {
  slots: LocationScheduleSlot[];
  practitioners: PractitionerSummary[];
}

export const getLocationScheduleSlots = async ( profileId: string | undefined, locationId: string | undefined ): Promise<LocationScheduleData[]> => {
  if( !profileId || !locationId ) return [];

  const response = await fetch( `${ apiPath }/profiles/${ profileId }/locations/${ locationId }/schedule` );
  return await response.json();
}

export interface AppointmentRescheduleSlot {
  id: string;
  schedule: {
    display: string;
  },
  status: string;
  start: string;
  end: string;
}

export const getAppointmentRescheduleSlots = async ( appointmentId: string | undefined ): Promise<AppointmentRescheduleSlot[]> => {
  if( !appointmentId ) return [];

  const response = await fetch( `${ apiPath }/appointments/${ appointmentId }/reschedule` );
  return await response.json();
}

export const cancelAppointment = async ( appointmentId: string ): Promise<string | undefined> => {
  const response = await fetch( `${ apiPath }/appointments/${ appointmentId }/cancel`, { method: 'POST' } );
  if( response.headers.get( 'content-length' ) == '0' ) return;
  const body = await response.json();
  if( body.errors ) return body.errors;
}

export const confirmAppointment = async ( appointmentId: string ): Promise<string | undefined> => {
  const response = await fetch( `${ apiPath }/appointments/${ appointmentId }/confirm`, { method: 'POST' } );
  if( response.headers.get( 'content-length' ) == '0' ) return;
  const body = await response.json();
  if( body.errors ) return body.errors;
}

export const rescheduleAppointment = async ( appointmentId: string, start: string, end: string ): Promise<string | undefined> => {
  const response = await fetch( `${ apiPath }/appointments/${ appointmentId }/reschedule`, {
    method: 'POST',
    headers: { 'content-type': 'application/json', accept: 'application/json, */*;q=0.5' },
    body: JSON.stringify( { start, end } ),
  } );
  if( response.headers.get( 'content-length' ) == '0' ) return;
  const body = await response.json();
  if( body.errors ) return body.errors;
}

export interface ScheduleAppointmentOpts {
  location: string;
  practitioner: string;
  appointmentTypeCode: string;
  start: string;
  end: string;
}

export const scheduleAppointment = async ( profileId: string, opts: ScheduleAppointmentOpts ): Promise<string | undefined> => {
  const response = await fetch( `${ apiPath }/profiles/${ profileId }/schedule`, {
    method: 'POST',
    headers: { 'content-type': 'application/json', accept: 'application/json, */*;q=0.5' },
    body: JSON.stringify( opts ),
  } );
  if( response.headers.get( 'content-length' ) == '0' ) return;
  const body = await response.json();
  if( body.errors ) return body.errors;
}

export type MaskedOptionId = string;
export type MaskedOptionValue = string;
export type ProfileSearchFormResponse = Record<MaskedOptionId, MaskedOptionValue>;

// export type ProfileSearchFormDataHandlerResponse = undefined | ProfileSearchFormResponse;

export const searchProfiles = async ( data: ProfileSearchFormData ): Promise<ProfileSearchFormResponse | undefined> => {
  if( !data ) return;
  const result = await fetch( `${ apiPath }/profiles/search`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify( data ),
  } );
  if( result.headers.get( 'content-length' ) == '0' ) return;
  return await result.json();
}

export const searchProfilesOption = async ( option: string ): Promise<{ profileId: string; } | undefined> => {
  if( !option ) return;
  const result = await fetch( `${ apiPath }/profiles/search/${ option }`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
  } );
  if( result.headers.get( 'content-length' ) == '0' ) return;
  return await result.json();
}

export type ProfileOptionsResponse = Record<MaskedOptionId, MaskedOptionValue>;

export const getProfileOptions = async ( profileId: string ): Promise<ProfileOptionsResponse | undefined> => {
  const result = await fetch( `${ apiPath }/profiles/${ profileId }/options` );
  if( result.headers.get( 'content-length' ) == '0' ) return;
  return await result.json();
}

export interface SelectProfileOptionResponse {
  deviceId?: string;
  preAuthSessionId?: string;
}

export const selectProfileOption = async ( profileId: string, option: string ): Promise<SelectProfileOptionResponse | undefined> => {
  const result = await fetch( `${ apiPath }/profiles/${ profileId }/options/${ option }`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
  } );
  if( result.headers.get( 'content-length' ) == '0' ) return;
  return await result.json();
}

export type ProfileCreateFormResponse = RecipientInterface & { errors?: Record<string, string> };

export const createProfile = async ( data: ProfileCreateFormData ): Promise<ProfileCreateFormResponse> => {
  const response = await fetch( `${ apiPath }/profiles`, {
    method: 'POST',
    headers: { 'content-type': 'application/json', accept: 'application/json, */*;q=0.5' },
    body: JSON.stringify( data ),
  } );
  // if( response.headers.get( 'content-length' ) == '0' ) return;
  return await response.json();
}

export interface FormDefinitionData {
  id: string;
  name: string;
  json: string;
  updatedAt: string;
}

export const getFormDefinition = async ( formId: string | undefined ): Promise<FormDefinitionData | undefined> => {
  if( !formId ) return;

  const response = await fetch( `${ apiPath }/forms/${ formId }` );
  return await response.json();
}

export enum FormStatus {
  REQUESTED = 'Requested',
  STARTED = 'Started',
  SUBMITTED = 'Submitted',
  // REJECTED = 'Rejected',
  // ACCEPTED = 'Accepted',
  EXPORTED = 'Exported', // pdf
  SIGNED = 'Signed',
}

export interface ProfileForm {
  id: string;

  status: FormStatus;

  form: string; // FormDefinitionData;
  formName: string;
  formJson?: string; // FormDefinitionData;

  submission: Record<string, unknown>;
  signedAt?: Date;
  processedAt?: Date;

  email?: string;
  phone?: string; // e164

  recipient: RecipientInterface;
  appointment: AppointmentInterface;
  practitioner: PractitionerSummary;
  location: LocationInterface;
}

export const getProfileForms = async ( profileId: string | undefined ): Promise<ProfileForm[]> => {
  if( !profileId ) return [];
  return fetchProfileApiList( `profiles/${ profileId }/forms` );
}


// OBSOLETE Don't use this
export const postFormSubmission = async ( formId: string | undefined, data: Record<string, unknown> ): Promise<FormDefinitionData | undefined> => {
  if( !formId ) return;

  const response = await fetch( `${ apiPath }/forms/${ formId }/submission`, {
    method: 'POST',
    headers: { 'content-type': 'application/json;charset=UTF-8', accept: 'application/json, */*;q=0.5' },
    body: JSON.stringify( data ),
  } );
  return await response.json();
}


export const updateProfileFormSubmission = async ( profileId: string | undefined, formId: string | undefined, data: Record<string, unknown> ): Promise<boolean | undefined> => {
  if( !profileId || !formId ) return;

  return await fetchProfileApiErrors( `profiles/${ profileId }/forms/${ formId }`, {
    method: 'PATCH',
    headers: { 'content-type': 'application/json;charset=UTF-8', accept: 'application/json, */*;q=0.5' },
    body: JSON.stringify( data ),
  } );
}

