import { Card, CardContent, CardHeader, CardMedia } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmailPasswordComponentsOverrideProvider } from 'supertokens-auth-react/recipe/emailpassword';
import { PasswordlessComponentsOverrideProvider } from 'supertokens-auth-react/recipe/passwordless';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import { PasswordlessPreBuiltUI } from 'supertokens-auth-react/recipe/passwordless/prebuiltui';
import { AuthPage as OgAuthPage, AuthRecipeComponentsOverrideContextProvider } from 'supertokens-auth-react/ui';


//     redirectOnSessionExists?: boolean;
//     onSessionAlreadyExists?: () => void;
//     preBuiltUIList: RecipeRouter[];
//     factors?: (typeof FactorIds)[keyof Omit<typeof FactorIds, 'TOTP'>][];
//     useSignUpStateFromQueryString?: boolean;
//     isSignUp?: boolean;
//     navigate?: Navigate;
//     userContext?: UserContext;

export const AuthPage: FC = () => {
  const navigate = useNavigate();

  return (
    <OgAuthPage
      // factors={ [] }
      preBuiltUIList={[ EmailPasswordPreBuiltUI, PasswordlessPreBuiltUI ]}
      navigate={navigate}
      redirectOnSessionExists
      // useSignUpStateFromQueryString
      isSignUp={false}
      userContext={{
      }}
    />
  );
}


export const SupertokensUiCustomization: FC<PropsWithChildren<Record<string, unknown>>> = ( { children } ) => {

  return (
    <AuthRecipeComponentsOverrideContextProvider
      components={{}}
    >
      <PasswordlessComponentsOverrideProvider
        components={{
          PasswordlessUserInputCodeFormHeader_Override: ( { DefaultComponent, ...props } ) => {
            return (
              <Card

                raised={false}
                sx={{
                  padding: '2rem 0 4rem',
                  // marginTop: '6rem',
                }}
              >
                <CardMedia
                  // sx={ {
                  //   '& .MuiCardMedia-img': {
                  //     minHeight: '120px',
                  //     backgroundSize: 'contain',
                  //     backgroundPosition: 'center',
                  //     backgroundRepeat: 'no-repeat',
                  //     // border: '1px red solid',
                  //   }
                  // } }
                  component="img"
                  height="140"
                  image="/AIC_Logo.png"
                />
                <CardHeader
                  title='Analog Concierge&trade;'
                  titleTypographyProps={{
                    align: 'center',
                    // size: 'small',
                    sx: {
                      fontSize: '1.6rem',
                      margin: '2rem 0 1rem !important',
                    }
                  }}
                  sx={{
                    '& .MuiCardHeader-content': {
                      border: '1px solid purple',
                      // margin: '1rem 0 -1rem',
                      margin: '2rem 0 1rem !important',
                    }
                  }}
                />
                <CardContent
                  sx={{
                    margin: '1rem 0',
                    // border: '1px solid blue',
                  }}
                >

                  <DefaultComponent {...props} />
                </CardContent>
              </Card>
            );
          },
        }}>
        {children}
      </PasswordlessComponentsOverrideProvider>
    </AuthRecipeComponentsOverrideContextProvider>
  )
}

