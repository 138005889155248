import { EditCalendar, EventAvailable, EventBusy } from '@mui/icons-material';
import { Box, Button, darken, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { titleize } from 'inflection';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useNavigate, useOutletContext, useRevalidator, useRouteLoaderData } from 'react-router-dom';
import { ConfirmCancelDialog, isCancellable, isConfirmable, isReschedulable } from './Appointment';
import { getProperName, prettyShortDate, prettyShortDateTime, prettyTime } from './AppointmentContainer';
import { ProfileContext } from './ProfileContainer';
import { cancelAppointment, confirmAppointment, ProfileAppointment } from './profiles';
import { useSmartContext } from './SmartProvider';


export const AppointmentsIndex: FC = () => {
  const orgContext = useOutletContext<ProfileContext>();
  const { profileId, colorPrimary, colorSecondary, fontBody, fontHeader, setNotify, setError, setTitle, isXSmall, isSmall, isMedium, organization, assets, recipient: patient, locations } = orgContext;
  const appointments = useRouteLoaderData( 'appointments' ) as ProfileAppointment[];
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const { configuration: smartConfiguration } = useSmartContext();
  const { brand } = smartConfiguration || {};
  const actionsBlockedByEmrBrand = brand && [ 'OpenEmr' ].includes( brand );

  const theme = useTheme();

  const [ showAll, setShowAll ] = useState( false );
  const [ openCancel, setOpenCancel ] = useState<string | undefined>();

  const isSchedulable = useMemo( () => !actionsBlockedByEmrBrand && !!( locations || [] ).find( l => ( l.appointmentTypeCodesEnabledForSchedule || [] ).length > 0 ), [ locations ] );

  const onClickConfirm = useCallback( ( id: string ) => async () => {
    const errors = await confirmAppointment( id );
    if( errors ) {
      setError( errors[ 0 ] );
      return;
    }
    setNotify( 'Your appointment has been confirmed.' );
    revalidator.revalidate();
  }, [ setNotify, setError, revalidator ] );

  const onCancel = useCallback( ( id: string ) => async () => {
    const errors = await cancelAppointment( id );
    if( errors ) {
      setError( errors[ 0 ] );
      return;
    }
    setNotify( 'Your appointment has been cancelled.' );
    revalidator.revalidate();
  }, [ setNotify, setError, revalidator ] );

  const isCompact = isSmall;
  const compact = 'md';

  const { firstName, lastName: lastInitial } = patient;
  const patientName = `${ firstName } ${ lastInitial }`;

  // DEBUG
  // DEBUG
  // DEBUG
  const isTestingReschedule = useMemo( () => !!( profileId == '11111111-1111-1111-1111-111111111111' && organization.name.match( /(Linda |Primary Derm)/ ) ), [ organization.name, profileId ] );
  // DEBUG
  // DEBUG
  // DEBUG


  if( !appointments ) return null;

  return (
    <Box id='appointments-index'>
      <Grid key='header'
        container
        alignItems='baseline'
        justifyContent='space-between'
      >
        <Grid key='title' item>
          <h3
            style={{
              fontFamily: fontHeader,
            }}
          >
            Appointments for <span className='translated'>{patientName}</span>
          </h3>
        </Grid>

        {isSchedulable &&
          <Grid key='button' item
            xs={12} sm='auto'
          >
            <Button
              variant='outlined'
              color='success'
              sx={{
                fontWeight: 'bold',
                fontSize: '1.1rem',
                marginBottom: { xs: '2rem', sm: 'inherit' },
              }}
              onClick={() => navigate( 'schedule' )}
              fullWidth={isCompact}
            >
              New Appointment
            </Button>

          </Grid>
        }
      </Grid>
      <Paper
        key='table'
        sx={{
          padding: '1rem',
        }}
      >
        <Table key='table' size='small' padding='none'
          sx={{
            '& .MuiTableBody-root .MuiTableRow-root': {
              '&:hover:not(.past-appointment),&:hover:not(.past-appointment) + .MuiTableRow-root.buttonRow, &:not(.buttonRow):has(+ .MuiTableRow-root.buttonRow:hover )': {
                backgroundColor: theme.palette.grey[ 50 ],
              },
              '&.past-appointment .MuiTableCell-body': {
                backgroundColor: theme.palette.grey[ 100 ],
              },
              '&.cancelled-appointment .MuiTableCell-body': {
                color: theme.palette.grey[ 500 ],
              },
              '&.cancelled-appointment.past-appointment .MuiTableCell-body': {
                color: darken( theme.palette.error.main, 0.3 ),
              },
              '&:not(.buttonRow):has(+ .MuiTableRow-root.buttonRow ) .MuiTableCell-body': {
                borderBottomWidth: 0,
              },
            },
            '& .MuiTableCell-body,& .MuiTableCell-head': {
              fontSize: '1rem',
              padding: '0.5rem 1rem',
              verticalAlign: 'top',
            },
            '& .MuiTableCell-head': {
              textTransform: 'uppercase',
              letterSpacing: '1.1',
              fontFamily: fontHeader,
            },
            '& .MuiButton-root': {
              width: { xs: '100%', [ compact ]: '10rem' },
              fontSize: { xs: '1rem', [ compact ]: '0.9rem' },
              margin: { xs: '0 0 2rem', [ compact ]: '0 3rem 1rem 0' },
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell key='status'>Status</TableCell>
              {isCompact ? <TableCell key='time'>Time/Date</TableCell> : <TableCell>Date/Time</TableCell>}

              {!isCompact && <TableCell key='prac'>Practitioner</TableCell>}
              <TableCell key='loc'>Location</TableCell>
              {!isCompact && <TableCell key='type'>Type</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {appointments.length == 0
              ? <TableRow >
                <TableCell colSpan={10} sx={{ height: '8rem' }}>
                  No appointments found
                </TableCell>
              </TableRow>
              : appointments // .filter( o => o.appointment.status != 'cancelled' )
                .map( ( o, idx ) => {
                  const { appointment, location } = o;
                  const { id, appointmentTypeDisplay, startTime, status, appointmentTypeCode } = appointment;
                  const { name, nickname } = location;
                  const locationName = nickname || name;
                  const timeZoneName = location.timeZoneName || appointment.timeZoneName;
                  const isPast = Date.parse( startTime ) < Date.now();
                  const isFirstPast = id == appointments.find( o => Date.parse( appointment.startTime ) < Date.now() )?.appointment.id;
                  const isFirst = id == appointments[ 0 ].appointment.id;
                  const isCancelled = [ 'cancelled', 'noshow' ].includes( status );
                  const isMostRecentPastAndCancelled = isFirst && isCancelled;

                  const [ confirmable, reschedulable, cancellable ] = useMemo( () => [
                    isConfirmable( { status, startTime, appointmentTypeCode }, location ) && !actionsBlockedByEmrBrand,
                    // DEBUG
                    // DEBUG
                    // DEBUG
                    isTestingReschedule || isReschedulable( { status, startTime, appointmentTypeCode }, location ) && !actionsBlockedByEmrBrand,
                    // DEBUG
                    // DEBUG
                    // DEBUG
                    isCancellable( { status, startTime, appointmentTypeCode }, location ) && !actionsBlockedByEmrBrand,
                  ], [ status, startTime, appointmentTypeCode, location ] );


                  if( isPast && !showAll && !isMostRecentPastAndCancelled ) return null;

                  return (
                    <Fragment key={idx}>
                      <TableRow
                        key={idx}
                        onClick={() => navigate( id )}
                        className={`${ isCancelled ? 'cancelled-appointment' : '' } ${ isPast ? 'past-appointment' : '' }`}
                      >
                        <TableCell key='status' >{!isPast ?
                          titleize( status == 'booked' ? 'confirmed' : status )
                          : titleize( [ 'cancelled' ].includes( status ) ? status : status == 'noshow' ? 'no show' : 'completed' )
                        }</TableCell>
                        {isCompact || !isCompact
                          ? (
                            <TableCell key='time'>
                              {prettyTime( startTime, timeZoneName )}
                              <br />
                              {prettyShortDate( startTime, timeZoneName )}
                            </TableCell>
                          )
                          : (
                            <TableCell key='time'>
                              {prettyShortDateTime( startTime, timeZoneName )}
                            </TableCell>
                          )}
                        {!isCompact && <TableCell key='prac' >{getProperName( o.practitioner )}</TableCell>}
                        <TableCell key='loc'>{locationName}</TableCell>
                        {!isCompact && <TableCell key='type'>{appointmentTypeDisplay}</TableCell>}

                      </TableRow>
                      {
                        ( !isPast || ( isPast && isMostRecentPastAndCancelled ) ) && (
                          <TableRow className='buttonRow' key={`B${ idx }`}>
                            <TableCell colSpan={10} align='right'>
                              {confirmable &&
                                <Button
                                  key='confirm'
                                  variant='outlined'
                                  startIcon={<EventAvailable />}
                                  onClick={onClickConfirm( id )}
                                >
                                  Confirm
                                </Button>
                              }
                              {reschedulable &&
                                <Button
                                  key='resched'
                                  variant={isCompact || isCancelled ? 'outlined' : undefined}
                                  startIcon={<EditCalendar />}
                                  onClick={( e ) => { e.stopPropagation(); navigate( `${ id }/reschedule` ); }}
                                  color={isTestingReschedule ? 'error' : undefined}
                                >
                                  Reschedule
                                </Button>
                              }
                              {cancellable &&
                                <Button
                                  key='cancel'
                                  variant={isCompact ? 'outlined' : undefined}
                                  startIcon={<EventBusy />}
                                  onClick={( e ) => {
                                    e.stopPropagation();
                                    setOpenCancel( id );

                                  }}
                                >
                                  Cancel
                                </Button>
                              }
                            </TableCell>
                          </TableRow>
                        )
                      }
                    </Fragment>
                  )
                } )}
          </TableBody>
        </Table>
        {appointments.length
          ? <Button
            key='show-all'
            onClick={() => setShowAll( !showAll )}
          >
            {showAll ? 'Less' : 'More'}
          </Button>
          : null
        }
      </Paper>
      <ConfirmCancelDialog
        key='confirm'
        open={!!openCancel}
        close={() => setOpenCancel( undefined )}
        onYes={onCancel( openCancel! )}
        fullScreen={isXSmall}
      />
    </Box >
  );
}
