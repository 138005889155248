/*eslint @typescript-eslint/no-unused-vars: "off"*/
import '@fontsource/montserrat';
import '@fontsource/open-sans';
import '@fontsource/roboto';
import '@fontsource/lato';
import '@fontsource/merriweather';
import '@fontsource/noto-sans';
import '@fontsource/noto-serif';
import '@fontsource/nunito-sans';
import '@fontsource/oswald';
import '@fontsource/raleway';
import '@fontsource/source-sans-pro';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { IconButton, List, ListItem, ListItemText, Link as MuiLink, Breadcrumbs, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getDistance } from 'geolib';
import { default as _, entries, groupBy, sortBy, get } from 'lodash';
import { Dispatch, ReactElement } from 'react';
import { FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Outlet, ScrollRestoration, useLoaderData, useLocation, useMatches, useNavigate, useOutletContext } from 'react-router-dom';
import { getCommonTagId } from './components/AmenityChoices';
import { PageFooter } from './components/page-footer';
import { getLocations, LocationsData } from './profiles';
import { SeoTags } from './SeoTags';
import styles from './styles/Home.module.css';
import { AssetInterface, LocationInterface, OrganizationInterface, OrganizationStyling } from './types';
import { LanguageSwitcher } from './LanguageSwitcher';



export const useLocTitle = ( root = '' ) => {
  const matches = useMatches() as LocMatch[];

  return useMemo<string>( () => {
    const breadcrumbs = matches
      // first get rid of any matches that don't have handle and crumb
      .filter( ( match ) => !!get( match, 'handle.breadcrumb' ) )
      // now map them into an array of elements, passing the loader
      // data to each one
      .map( ( match, idx, matches ) => {
        const { pathname: to, handle: { breadcrumb } = {} } = match;
        const label = typeof breadcrumb == 'function' ? breadcrumb( { ...match, parent: idx > 0 ? matches[ idx - 1 ] : undefined } ) : breadcrumb;
        return { to, label };
      } );

    return [ ...breadcrumbs.reverse().map( b => b.label ), root ].filter( a => a ).join( ' - ' );
  }, [ matches ] );

}

// export type Match = ReturnType<typeof useMatches>[ number ];

export interface LocMatch<T extends Record<string, unknown> = Record<string, unknown>> extends Omit<ReturnType<typeof useMatches>[ number ], 'handle'> {
  data: T;
  parent?: LocMatch;
  handle?: {
    breadcrumb?: ReactElement | string | ( ( match: LocMatch & { parent?: LocMatch } ) => string );
  };
}

export const LocBreadcrumbs: FC = () => {
  const matches = useMatches() as LocMatch[];
  const breadcrumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter( ( match ) => !!get( match, 'handle.breadcrumb' ) )
    // now map them into an array of elements, passing the loader
    // data to each one
    .map( ( match, idx, matches ) => {
      const { pathname: to, handle: { breadcrumb } = {} } = match;
      const label = typeof breadcrumb == 'function'
        ? (
          <Typography component='span' className='translated'>
            {breadcrumb( { ...match, parent: idx > 0 ? matches[ idx - 1 ] : undefined } )}
          </Typography>
        )
        : breadcrumb;
      return { to, label };
    } );

  if( !matches[ 0 ].pathname.match( /^\/app/ ) ) {
    breadcrumbs.unshift( { label: 'Home', to: 'https://analoginformation.com' } );
  }

  return (
    <Breadcrumbs aria-label="breadcrumb"
      sx={{
        marginBottom: '1rem',
      }}
    >
      {breadcrumbs.map( ( breadcrumb, idx, breadcrumbs ) => {
        const { label, to } = breadcrumb;
        const last = idx === breadcrumbs.length - 1;


        return last
          ? (
            <Typography color="text.primary" key={to}>
              {label}
            </Typography>
          )
          : (
            <Link
              color="inherit"
              to={to}
              key={to}
              style={{
                color: 'inherit',
                textDecorationLine: 'none',
              }}
            >
              {label}
            </Link>
          );
      } )}
    </Breadcrumbs>


  );
}

export const Locs: FC = () => {
  const { locations, organization, assets, commonTags, countryCode } = useLoaderData() as LocationsData;
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

  const routerLocation = useLocation();
  const pathnames = routerLocation.pathname.split( '/' ).filter( ( x ) => x );

  const pageTitle = useLocTitle( organization.name );
  const [ title, setTitle ] = useState( '' );
  const [ position, _setPosition ] = useState<GeolocationPosition>();

  // Google Analytics
  // useEffect(() => {ga('send', 'pageview');}, [routerLocation]);

  useEffect( () => {
    if( position?.timestamp ) return;
    // navigator.geolocation.getCurrentPosition( ( p ) => {
    //   if( p.timestamp && p.timestamp != position?.timestamp ) {
    //     // console.log( p );
    //     setPosition( p );
    //   }
    // }, undefined, { maximumAge: 60000 /* Infinity */, timeout: Infinity, enableHighAccuracy: false } )
  }, [] );

  const { logoAsset, logoTitle, colorPrimary, colorSecondary, fontBody, fontHeader, iconProps } = useMemo( () => {
    if( !organization ) return {};
    // const { amenityTags } = organization;
    const { iconStyle = 'default', amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader } = ( organization || {} ) as unknown as OrganizationStyling;
    const iconProps = {
      // htmlColor: [ 'default', 'primary', 'secondary' ].includes( iconStyle ) ? undefined : iconStyle,
      '& svg': {
        color: [ 'primary', 'secondary' ].includes( iconStyle )
          ? ( iconStyle == 'primary' ? colorPrimary : colorSecondary )
          : 'inherit',
        fontSize: '2.25rem', // 'large',
      }
    };
    const logoAsset = ( assets ).find( asset => ( asset.tags || [] ).includes( getCommonTagId( 'square' ) ) );
    const logoTitle = [ organization.name, logoAsset?.subject || 'logo' ].join( ' ' );
    return { logoAsset, logoTitle, iconProps, amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader };
  }, [ assets, organization ] );

  const closestLocation = useMemo<LocationInterface | undefined>( () => {
    const { latitude, longitude } = position?.coords || {};
    if( !( typeof latitude == 'number' && typeof longitude == 'number' ) ) return;
    const ref = { latitude, longitude };
    // console.log( 'position.coords', ref );
    return _( locations )
      .sortBy( loc => {
        const { lat, lng } = loc;
        if( !( typeof lat == 'number' && typeof lng == 'number' ) ) return Infinity;
        return getDistance( ref, { lat, lng } );
      } )
      .value()[ 0 ];
  }, [ locations, position?.coords ] );


  return (
    <Box
      id='locations'
      sx={{
        padding: '0 1.5rem',
        maxWidth: '960px',
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: '1.2rem',
        backgroundColor: '#f9fafb',
        '& .MuiListItemText-root .MuiTypography-root': {
          fontFamily: fontBody,
        },

      }}>


      <SeoTags title={pageTitle} organization={organization} logoAsset={logoAsset} />
      <ScrollRestoration />

      <Grid item container direction="row"
        sx={{
          margin: { xs: '0 -1.3em', md: 'inherit' },
          width: { xs: '120%', md: 'inherit' },
          // border: '1px solid blue',
        }}
        justifyContent='space-between'
      >
        <Grid item xs={12} sm={5}
          sx={{
            // border: '1px solid red',

          }}
        >
          <Typography variant='h1'
            align={isXSmall ? 'center' : 'left'}
            style={{
              fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
              color: colorPrimary,
              // color: 'white',
              width: '100%',
              padding: '0rem 1.0rem',
              fontWeight: 'bold', //'600',
              fontSize: 'medium',
              fontSmooth: 'always',
            }}
            className='translated'
          >
            AIC Analog Concierge &trade;
            { /* transparent button used to match height of Location header bar */}
            <IconButton sx={{ color: 'transparent' }}>
              <ArrowBackIosNew />
            </IconButton>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5}
          sx={{
            width: { xs: '100%', sm: undefined },
            display: 'flex',
          }}
        >
          <Box sx={{ flexGrow: 1, display: { xs: undefined, sm: 'none' } }} />
          <LanguageSwitcher />
          <Box sx={{ flexGrow: 1, display: { xs: undefined, sm: 'none' } }} />
        </Grid>
        <Grid xs={12}>
          <MuiLink
            href='/locations'
            sx={{
              width: '100%',
              textDecorationLine: 'none',
            }}
          >
            <Typography variant='h1'
              align={isXSmall ? 'center' : 'left'}
              style={{
                fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
                backgroundColor: colorPrimary,
                color: 'white',
                width: '100%',
                padding: '0.5rem 1.0rem',
                fontWeight: 'bold', //'600',
                fontSize: 'x-large',
                fontSmooth: 'always',
              }}
              className='translated'
            >
              {title}
              { /* transparent button used to match height of Location header bar */}
              <IconButton sx={{ color: 'transparent' }}>
                <ArrowBackIosNew />
              </IconButton>
            </Typography>
          </MuiLink>
        </Grid>
      </Grid >

      <LocBreadcrumbs />



      <Outlet
        context={{
          locations, organization, assets, commonTags, countryCode,
          closestLocation, fontBody, fontHeader, colorPrimary, colorSecondary,
          setTitle,
        }}
      />
      <PageFooter {...{ organization }} />
    </Box >
  );
}

export interface LocationsOutlet extends LocationsData {
  setTitle: Dispatch<SetStateAction<string>>;
  fontBody: string;
  fontHeader: string;
  colorPrimary: string;
  colorSecondary: string;
  closestLocation?: LocationInterface;
}

export const LocsIndex: FC = () => {
  const {
    locations, organization,
    closestLocation, fontBody, fontHeader, colorPrimary, colorSecondary,
    setTitle,
  } = useOutletContext() as LocationsOutlet;
  const theme = useTheme();

  useEffect( () => setTitle( organization.name ), [ organization.name ] );

  const locationsByState = useMemo<[ string, LocationInterface[] ][]>( () => {
    const byState = groupBy( locations, ( l ) => l.addressComponents?.long?.state || 'Other' );
    const azSorted = sortBy( entries( byState ), ( [ state ] ) => state );
    // if( locations.length < 4 || azSorted.length < 2 ) return;
    const { state: closestLocationState } = closestLocation?.addressComponents?.long || {};
    if( !closestLocationState ) return azSorted;
    // console.log( 'closestLocationState', closestLocationState );
    const idxState = azSorted.findIndex( ( [ state ] ) => state == closestLocationState );
    if( idxState < 1 ) return azSorted;
    const closestState = azSorted.splice( idxState, 1 )[ 0 ];
    const idxLocation = closestState[ 1 ].findIndex( ( loc ) => loc.id == closestLocation?.id );
    // console.log( 'idxLocation', idxLocation, closestLocation!.name );
    closestState[ 1 ].splice( idxLocation, 1 )[ 0 ];
    closestState[ 1 ].unshift( closestLocation! );
    azSorted.unshift( closestState );
    return azSorted;
  }, [ locations, closestLocation ] );


  const LocationCard: FC<{ location: LocationInterface }> = ( { location } ) => {
    const { slug } = location || {};
    const loc = slug ?? location.id;
    return (
      <a href={`/locations/${ loc }`}
        // className={`translated ${ styles.card }`}
        className={styles.card}
        key={loc}
      >
        <Typography
          variant='h2'
          sx={{
            fontFamily: fontBody,
            color: colorSecondary,
          }}
        >
          {location.name}
        </Typography>
        <Typography
          component='div'
          variant="body1"
          sx={{
            fontFamily: fontBody,
            color: '#aaa',
          }}
        >
          <Grid container direction='column'>
            {( location.addressComponents?.lines || [] ).map( ( line: string, idx: number ) => (
              <Typography key={`line${ idx }`} sx={{ fontFamily: fontBody }} > {line} </Typography>
            ) )}
            {!location.addressComponents?.lines &&
              <Typography > {location.humanAddress} </Typography>
            }
          </Grid>
        </Typography>

      </a>
    );
  }

  return (
    <Box
    >
      {
        locationsByState.map( ( [ state, locations ] ) =>
          <Grid item container key={state} direction='column' pt={1}>
            <Grid item>
              <Typography variant='h2'
                style={{
                  fontFamily: fontHeader,
                  color: colorPrimary,
                  // color: 'white',
                  // width: '100%',
                  // padding: '1rem',
                  fontWeight: 'bold', // '300',
                  fontSize: 'x-large',
                  fontSmooth: 'always',
                  textTransform: 'uppercase',
                }}
                className='translated'
              >
                {state}
              </Typography>
            </Grid>
            <Grid item>
              <List
                dense
                disablePadding
                sx={{
                  '& .MuiListItem-root:hover': {
                    backgroundColor: alpha( theme.palette.text.primary, 0.04 ),
                  }
                }}
              >
                {locations.map( location => {
                  if( !location.slug ) return null;
                  return (
                    <Link
                      key={location.slug}
                      to={location.slug}
                      style={{
                        color: 'inherit',
                        textDecorationLine: 'none',
                      }}
                      className='translated'
                    >
                      <ListItem
                        key={location.id}
                        disableGutters
                        // onClick={() => {
                        //   console.log( location.name )
                        //   location.slug && navigate( `${ location.slug }` );
                        // }}
                        // secondaryAction={
                        //   <IconButton edge="end" aria-label='About us'>
                        //     <ArrowForwardIos />
                        //   </IconButton>
                        // }
                        sx={{
                          paddingLeft: '1rem',
                          color: 'inherit',
                          '& .MuiListItemIcon-root': {
                            minWidth: 40,
                          }
                        }}
                      >
                        <ListItemText
                          primary={location.nickname || location.name}
                          primaryTypographyProps={{ variant: 'h6' }}
                        // secondary={location.addressComponents?.short?.city ?? undefined}
                        />


                      </ListItem>
                    </Link>
                  );
                } )}
              </List>
            </Grid>
          </Grid>
        )
      }

    </Box>

  )
}
