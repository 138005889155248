import { AccessibilityNew, CardGiftcard, CreditCard, Fastfood, Group, HowToReg, Info, LocalFlorist, LocalParking, LocalPharmacy, ImportContacts as Magazine, SupportAgent, Wc, Wifi } from '@mui/icons-material';
import { ReactElement } from 'react';

export const getCommonTagId = ( name: string ): string => {
  switch( name ) {
    case 'checkin': return '613914872ab88d000b62d19e';
    case 'checkout': return '613915d127ea940aa8edb6d0';
    case 'giftshop': return '6172fa866214baaf2b5ac346';
    case 'meetingRooms': return '6139175027ea940aa8edb79f';
    case 'onsite': return '613915eb27ea940aa8edb6ec';
    case 'parkAlternative': return getCommonTagId( 'parking' );
    case 'parkBest': return getCommonTagId( 'parking' );
    case 'parking': return '6102f0cc3b3fd28628ca935e';
    case 'pharmacy': return '6139170827ea940aa8edb74f';
    case 'restrooms': return '6139173127ea940aa8edb781';
    case 'vending': return '613a7e50d2de29000bb1638c';
    case 'waitingAreas': return '6139174027ea940aa8edb790';
    case 'wifi': return '613a0120550946000bce1b44';
    case 'charitableGiving': return '613a00ea550946000bce1b27';
    case 'accessibility': return '613a28e2c573f8000afe1e08';
    case 'information': return '613a2944c573f8000afe1e2c';
    case 'square': return '6102ee87db029d83f10886fd';
    case 'banner': return '6102f0c13b3fd28628ca935a';
    case 'photo': return '6102e8c4ac15a717ce15fedd';
    default: return '';
  }
}

export interface AssetProps {
  name: string;
  page: string;
  label: string;
  labelImage?: string;
  placeholder?: string;
  helperText?: string;
  isFacility?: boolean;
  isOnsite?: boolean;
  isAmenity: boolean;
  icon?: ReactElement;
}

export const assetProps: AssetProps[] = [
  {
    isAmenity: true,  // May change when Transportation input enabled
    name: 'parking',
    page: 'Transportation',
    label: 'Parking',
    placeholder: 'When you arrive by car, ...',
    helperText: 'Describe the parking process',
    icon: <LocalParking />,
  },
  {
    isAmenity: true,
    name: 'checkin',
    page: 'Process',
    label: 'Check In',
    placeholder: 'When you arrive, ...',
    helperText: 'Describe the check in process',
    // icon: // stethascope
    icon: <HowToReg />,
  },
  {
    isAmenity: true,
    name: 'waitingAreas',
    page: 'Process',
    label: 'Waiting Areas',
    placeholder: 'Waiting areas can be found...',
    helperText: 'Describe where',
    isFacility: true,
    icon: <Magazine />
  },
  {
    isAmenity: true,
    name: 'checkout',
    page: 'Process',
    label: 'Check Out',
    placeholder: 'Before you depart, ...',
    helperText: 'Describe the check out process',
    icon: <CreditCard />,
  },
  {
    isAmenity: true,
    name: 'restrooms',
    page: 'Amenities',
    label: 'Restrooms',
    placeholder: 'Restrooms are located ...',
    helperText: 'Describe where',
    isFacility: true,
    icon: <Wc />,
  },
  {
    isAmenity: true,
    name: 'wifi',
    page: 'Amenities',
    label: 'Wi-Fi',
    placeholder: '',
    helperText: 'Include network name, password and any special instructions',
    isFacility: true,
    icon: <Wifi />,
  },
  {
    isAmenity: true,
    name: 'vending',
    page: 'Amenities',
    label: 'Vending',
    placeholder: '',
    helperText: 'Describe where and what',
    isFacility: true,
    icon: <Fastfood />,
  },
  {
    isAmenity: true,
    name: 'pharmacy',
    page: 'Onsite',
    label: 'Pharmacy',
    placeholder: 'The onsite pharmacy is...',
    helperText: 'Describe where, hours',
    isFacility: true,
    isOnsite: true,
    icon: <LocalPharmacy />
  },
  {
    isAmenity: true,
    name: 'giftshop',
    page: 'Onsite',
    label: 'Gift Shop',
    placeholder: 'Out gift shop is...',
    helperText: 'Describe where, hours',
    isFacility: true,
    isOnsite: true,
    icon: <LocalFlorist />
  },
  {
    isAmenity: true,
    name: 'meetingRooms',
    page: 'Onsite',
    label: 'Meeting Rooms',
    placeholder: 'Meeting rooms are located ...',
    helperText: 'Describe where',
    isFacility: true,
    icon: <Group />,
  },
  {
    isAmenity: true,
    name: 'charitableGiving',
    page: 'Other',
    label: 'Charitable Giving',
    placeholder: 'Our facility is supported by ...',
    helperText: 'Describe what and how',
    isFacility: true,
    icon: <CardGiftcard />
  },

  {
    isAmenity: true,
    name: 'accessibility',
    page: 'Other',
    label: 'Accessibility',
    placeholder: 'Our facility features accessible ...',
    helperText: 'Describe what and how',
    isFacility: true,
    icon: <AccessibilityNew />,
  },
  {
    isAmenity: true,
    name: 'information',
    page: 'Other',
    label: 'Information',
    placeholder: 'If you need any information or assistance, ...',
    helperText: 'Describe what and how',
    isFacility: true,
    icon: <Info />,
  },
  {
    isAmenity: true,
    name: 'onsite',
    page: 'Other',
    label: 'Onsite Services',
    placeholder: 'If you need any information or assistance, ...',
    helperText: 'Describe what and how',
    isFacility: true,
    icon: <SupportAgent />, // ContactEmergency
  },
  {
    isAmenity: false,
    name: 'banner',
    page: 'Organization',
    label: 'Main Banner',
    placeholder: 'If you need any information or assistance, ...',
    helperText: 'Describe what and how',
    isFacility: true,
  },
  {
    isAmenity: false,
    name: 'square',
    page: 'Organization',
    label: 'Square Logo',
    placeholder: 'If you need any information or assistance, ...',
    helperText: 'Describe what and how',
    isFacility: true,
  },
  {
    isAmenity: false,
    name: 'photo',
    page: 'Organization',
    label: 'Photos',
    placeholder: 'If you need any information or assistance, ...',
    helperText: 'Describe what and how',
    isFacility: true,
  },
];

export interface AmenityChoice {
  id: string;
  name: string;
  tag: string;
  isFacility: boolean;
  icon?: ReactElement;
}

export const amenityChoices: AmenityChoice[] = assetProps
  .filter( props => props.isAmenity )
  .map( props => {
    const { name: tag, label: name, isFacility = false, icon } = props;
    const id = getCommonTagId( tag );
    return { id, name, tag, isFacility, icon };
  } ).filter( c => !!c.id );

export const assetChoices: AmenityChoice[] = assetProps
  .filter( props => !props.isAmenity )
  .map( props => {
    const { name: tag, label: name, isFacility = false, icon } = props;
    const id = getCommonTagId( tag );
    return { id, name, tag, isFacility, icon };
  } ).filter( c => !!c.id );

export const assetPages: string[] = assetProps
  .map( a => a.page )
  .reduce( ( pages: string[], page: string ): string[] => pages.includes( page ) ? pages : [ ...pages, page ], [] );


