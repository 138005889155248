import { alpha, Box, Button, Grid, Slide, Stack, Typography, useTheme } from '@mui/material';
import { uniq, uniqBy } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLoaderData, useNavigate, useOutletContext, useParams, useRevalidator, useRouteLoaderData } from 'react-router-dom';
import { getProperName, parseableDateTz, prettyDate, prettyShortDate, prettyTime, scrollDown } from './AppointmentContainer';
import { sample } from './AppointmentReschedule';
import { ConfirmDialog } from './components/ConfirmDialog';
import { ProfileContext } from './ProfileContainer';
import { LocationScheduleData, LocationScheduleSlot, LocationsData, ProfileScheduleSlot, scheduleAppointment } from './profiles';
import { PractitionerSummary } from './types';

export type AppointmentLocationScheduleParams = { locationId: string };




export const AppointmentLocationSchedule: FC = () => {
  const { profileId, recipient, colorPrimary, colorSecondary, fontBody, fontHeader, setNotify, setError, setTitle, isXSmall, isSmall, isMedium, countryCode } = useOutletContext<ProfileContext>();
  const { locations, organization } = useRouteLoaderData( 'appointment-locations' ) as LocationsData;
  const { locationId } = useParams<AppointmentLocationScheduleParams>();
  const { slots, practitioners } = useLoaderData() as LocationScheduleData;

  const theme = useTheme();
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  const sectionPractitioners = useRef<HTMLDivElement>( null );
  const sectionSlots = useRef<HTMLDivElement>( null );
  const sectionDays = useRef<HTMLDivElement>( null );
  const sectionTimes = useRef<HTMLDivElement>( null );

  const location = useMemo( () => locations.find( l => l._id == locationId ), [ locations, locationId ] );
  const [ openScheduleDialog, setOpenScheduleDialog ] = useState<LocationScheduleSlot | undefined>();

  useEffect( () => setTitle( location?.name || organization.name ), [ location?.name, organization.name ] );

  const [ practitioner, setPractitioner ] = useState<PractitionerSummary | undefined>();

  const timeZoneName = useMemo( () => {
    return location?.timeZoneName ?? recipient.timeZoneName ?? locations.slice( -1 )[ 0 ]?.timeZoneName ?? 'America/Chicago' // 'America/Los_Angeles';
  }, [ recipient, location, locations ] );

  const [ date, setDate ] = useState<Date | undefined>();

  const isDaySelectedDate = useCallback( ( day: string ) => {
    return day == parseableDateTz( date, timeZoneName );
  }, [ date ] );

  type ShowTimes = 'Morning' | 'Afternoon' | 'Any Time';
  const allShowTimes: ShowTimes[] = [ 'Morning', 'Afternoon', 'Any Time' ]
  const [ showTimes, setShowTimes ] = useState<ShowTimes | undefined>();
  const [ moreTimes, setMoreTimes ] = useState( false );

  useEffect( () => scrollDown( sectionSlots ) );

  const onClickPractitioner = useCallback( ( newPractitioner: PractitionerSummary ) => () => {
    setPractitioner( practitioner == newPractitioner ? undefined : newPractitioner )
    setShowTimes( undefined );
    setDate( undefined );
    setMoreTimes( false );
    scrollDown( sectionPractitioners );
  }, [ sectionSlots.current?.offsetTop, setPractitioner, setDate, setMoreTimes ] );

  const onClickShowTimes = useCallback( ( newShowTimes: ShowTimes ) => () => {
    setShowTimes( showTimes == newShowTimes ? undefined : newShowTimes )
    setDate( undefined );
    setMoreTimes( false );
    scrollDown( sectionDays );
  }, [ sectionDays.current?.offsetTop, showTimes, setDate, setMoreTimes ] );

  const onClickDate = useCallback( ( d: string ) => () => {
    setDate( new Date( d ) );
    setMoreTimes( false );
    scrollDown( sectionTimes );
  }, [ sectionTimes.current?.offsetTop, showTimes, setMoreTimes, setDate ] );

  const isMorning = ( d: string ): boolean => prettyTime( d, timeZoneName ).includes( 'AM' );

  const availablePractitioners = useMemo( () => {
    if( !slots || !location ) return [];
    const available = uniq( slots
      .filter( slot => slot.location == location._id )
      .filter( slot => showTimes == 'Any Time' || ( showTimes == 'Morning' ? isMorning( slot.start ) : !isMorning( slot.start ) ) )
      .map( slot => slot.practitioner ) );
    return practitioners.filter( p => available.includes( p.id ) );
  }, [ slots, showTimes, location, practitioners ] );

  const availableDates = useMemo( () => {
    if( !slots || !location || !practitioner ) return [];
    const available = slots
      .filter( slot => slot.location == location.id && slot.practitioner == practitioner.id )
      .filter( slot => showTimes == 'Any Time' || ( showTimes == 'Morning' ? isMorning( slot.start ) : !isMorning( slot.start ) ) )
      .map( slot => ( { label: prettyShortDate( slot.start, timeZoneName ), day: parseableDateTz( slot.start, timeZoneName ) } ) );
    return uniqBy( available, a => a.label );
  }, [ slots, showTimes, location?.id, practitioner?.id ] );

  const filteredSlots = useMemo( () => {
    if( !slots || !date || !showTimes || !location || !practitioner ) return [];
    const day = prettyDate( date, timeZoneName );
    const available = slots
      .filter( slot => slot.location == location.id && slot.practitioner == practitioner.id )
      .filter( slot => prettyDate( slot.start, timeZoneName ) == day )
      .filter( slot => showTimes == 'Any Time' || ( showTimes == 'Morning' ? isMorning( slot.start ) : !isMorning( slot.start ) ) )
    return available;
  }, [ slots, date, showTimes, location?.id, practitioner?.id, timeZoneName ] );

  const sampledSlots = useMemo( () => sample( filteredSlots, 19 ), [ filteredSlots ] );

  const onSchedule = useCallback( async ( slot: ProfileScheduleSlot ) => {
    if( !location || !practitioner ) return;
    const errors = await scheduleAppointment( profileId, slot );
    if( errors ) {
      setError( errors[ 0 ] );
      return;
    }
    // setStatus( 'booked' );
    // setStartTime( slot.start );
    setNotify( 'Your appointment has been scheduled.' );
    revalidator.revalidate();
    navigate( '../..' );
  }, [ navigate, profileId, location, practitioner ] );


  const isAnyMorning = useMemo( () => !!slots?.find( slot => isMorning( slot.start ) ), [ 'slots' ] );
  const isAnyAfternoon = useMemo( () => !!slots?.find( slot => !isMorning( slot.start ) ), [ 'slots' ] );

  return (
    <div id='appointment-schedule'>
      <h3
        style={{
          fontFamily: fontHeader,
        }}
      >
        New Appointment - Practitioner / Date & Time
      </h3>
      <Box display='none' className='translated' >
        <Typography>{timeZoneName}</Typography>
        <Typography>{location?.name ?? ''}</Typography>
        <Typography>{practitioner?.fullName ?? ''}</Typography>
        <Typography>{showTimes}</Typography>
        <Typography>{parseableDateTz( date, timeZoneName )}</Typography>
      </Box>

      <Stack mb={2}
      >
        <Box
          sx={{
            textAlign: 'center',
            // border: '1px solid purple',
            // width: '100%'
          }}
        >

          {/***********  PRACTITIONERS *****************/}

          {location &&
            <>
              <Box
                ref={sectionDays}
                sx={{
                  // border: '1px solid red',
                  margin: '2rem auto 0',
                  '& .MuiTypography-body1': {
                    // color: 'red',
                    margin: '1.25rem 1rem 1rem 0',
                    fontWeight: 'bold',
                    width: '16rem',
                    display: 'inline-block',
                  },
                }}
              >
                <Typography
                  component='span'
                  sx={{
                  }}
                  align='center'
                >
                  Who is your preferred practitioner?
                </Typography>

              </Box>


              <Grid container
                direction='row'
                // justifyContent='space-between'
                alignItems='flex-start'


                sx={{
                  // height: '22rem',
                  // border: '1px solid purple'
                  '& .MuiButton-outlined': {
                    backgroundColor: alpha( theme.palette.primary.main, 0.08 ),
                    color: theme.palette.primary.main,
                    '&:hover,&:active': {
                      backgroundColor: theme.palette.primary.main,
                      color: 'white',
                    }
                  }
                }}
              >
                {availablePractitioners.map( prac => (
                  <Grid
                    item
                    key={prac.id}
                    xs={12} sm={'auto'}
                  >

                    <Button
                      variant={practitioner?.id == prac.id ? 'contained' : 'outlined'}
                      size='small'
                      onClick={onClickPractitioner( prac )}
                      fullWidth={isXSmall}
                      sx={{
                        margin: { xs: '0.5rem 0', sm: '0.5rem' },
                        // width: { xs: undefined, sm: '10rem', md: '9rem' },
                        '& .MuiTypography-root': {
                          margin: 'inherit',
                        },
                      }}
                      disableRipple
                      className='translated'
                    >
                      <Typography
                        component='span'
                        sx={{
                          margin: '0.5rem 1rem 0 0',
                        }}>
                        {getProperName( prac )}
                      </Typography>
                    </Button>

                  </Grid>
                ) )
                }
              </Grid>
            </>
          }


          {/***********  Time Slots *****************/}


          {practitioner &&
            <>
              <Box
                ref={sectionSlots}
                sx={{
                  // border: '1px solid red',
                  // margin: '0 auto',
                  '& .MuiTypography-body1': {
                    margin: '1.25rem 1rem 1rem 0',
                    fontWeight: 'bold',
                    display: 'inline-block',
                  },
                }}
              >
                <Typography
                  component='span'
                  sx={{
                  }}
                  align='center'
                >
                  What is your preferred time of day?
                </Typography>

              </Box>

              <Grid container
                direction={isXSmall ? 'column' : 'row'}
                justifyContent='space-between'
                columnSpacing={isXSmall ? undefined : 2}

                sx={{
                  // border: '1px solid purple',
                  // marginLeft: { xs: '-16px', sm: 0 },
                  '& .MuiButton-outlined': {
                    backgroundColor: alpha( theme.palette.primary.main, 0.08 ),
                    color: theme.palette.primary.main,
                    '&.Mui-selected, &.Mui-selected:hover': {
                      backgroundColor: theme.palette.primary.main,
                      color: 'white',
                    },
                    '&:hover': {
                      backgroundColor: alpha( theme.palette.primary.main, 0.18 ),
                    },
                  }
                }}
              >
                {allShowTimes.map( slot => (
                  <Grid
                    item
                    key={slot}
                    xs={10} sm={Math.floor( 12 / allShowTimes.length )}
                    sx={{
                      marginBottom: { xs: '1rem', sm: 0 },
                    }}
                  >

                    <Button
                      variant={showTimes == slot ? 'contained' : 'outlined'}
                      size='small'
                      onClick={onClickShowTimes( slot )}
                      fullWidth
                      sx={{
                        // border: '1px solid red',
                        padding: '1rem 0',
                        // margin: { xs: '0.5rem 0', sm: '0.5rem' },

                        width: { xs: undefined, sm: '100%' },
                        '& .MuiTypography-root': {
                          margin: 'inherit',
                        },
                      }}
                      disableRipple
                      disabled={slot == 'Morning' ? !isAnyMorning : slot == 'Afternoon' ? !isAnyAfternoon : false}
                      className='translated'
                    >
                      <Typography
                        component='span'
                        sx={{
                          margin: '0.5rem 1rem 0 0',
                        }}>
                        {slot}
                      </Typography>
                    </Button>

                  </Grid>
                ) )
                }
              </Grid>
            </>
          }
        </Box>



        {/***********  Dates *****************/}


        {showTimes &&
          <>
            <Box
              ref={sectionDays}
              sx={{
                // border: '1px solid red',
                margin: '2rem auto 0',
                '& .MuiTypography-body1': {
                  // color: 'red',
                  margin: '1.25rem 1rem 1rem 0',
                  fontWeight: 'bold',
                  width: '16rem',
                  display: 'inline-block',
                },
              }}
            >
              <Typography
                component='span'
                sx={{
                }}
                align='center'
              >
                Available Dates for "{showTimes}"
              </Typography>

            </Box>


            <Slide in={!!showTimes} direction='up' >
              <Grid container
                // direction={isXSmall ? 'column' : 'row'}
                // justifyContent='space-between'
                alignItems='flex-start'


                sx={{
                  // height: '22rem',
                  // border: '1px solid purple',
                  '& .MuiButton-outlined': {
                    backgroundColor: alpha( theme.palette.primary.main, 0.08 ),
                    color: theme.palette.primary.main,
                    '&.Mui-selected, &.Mui-selected:hover': {
                      backgroundColor: theme.palette.primary.main,
                      color: 'white',
                    },
                    '&:hover': {
                      backgroundColor: alpha( theme.palette.primary.main, 0.18 ),
                    },
                  }

                }}
              >
                {
                  availableDates.length
                    ? availableDates.map( availableDate => (
                      <Grid
                        item
                        key={availableDate.label}
                        xs={12} sm={'auto'}
                      >

                        <Button
                          variant={isDaySelectedDate( availableDate.day ) ? 'contained' : 'outlined'}
                          size='small'
                          onClick={onClickDate( availableDate.day )}
                          fullWidth={isXSmall}
                          sx={{
                            // border: '1px solid red',
                            margin: { xs: '0.5rem 0', sm: '0.5rem' },

                            width: { xs: undefined, sm: '10rem', md: '9rem' },
                            '& .MuiTypography-root': {
                              margin: 'inherit',
                            },
                          }}
                          disableRipple
                          className='translated'
                        >
                          <Typography
                            component='span'
                            sx={{
                              margin: '0.5rem 1rem 0 0',
                            }}>
                            {availableDate.label}
                          </Typography>
                        </Button>

                      </Grid>
                    ) )
                    : (
                      <Typography pt={2}>No available slots</Typography>
                    )
                }
              </Grid>
            </Slide>
          </>
        }


        {/***********  Times *****************/}


        {date &&
          <>
            <Box
              ref={sectionTimes}
              sx={{
                // border: '1px solid red',
                margin: '2rem auto 0',
                '& .MuiTypography-body1': {
                  // color: 'red',
                  margin: '1.25rem 1rem 1rem 0',
                  fontWeight: 'bold',
                  // width: '16rem',
                  display: 'block',
                },

              }}
            >
              <Typography
                align='center'
              >
                Available Times for  {prettyDate( date, timeZoneName )}
              </Typography>

            </Box>


            <Slide in={!!date} direction='up' >
              <Grid container
                direction='row'
                // justifyContent='space-between'
                alignItems='flex-start'


                sx={{
                  // height: '22rem',
                  // border: '1px solid purple'
                  '& .MuiButton-outlined': {
                    backgroundColor: alpha( theme.palette.primary.main, 0.08 ),
                    color: theme.palette.primary.main,
                    '&:hover,&:active': {
                      backgroundColor: theme.palette.primary.main,
                      color: 'white',
                    }
                  }
                }}
              >
                {filteredSlots.length
                  ? ( moreTimes ? filteredSlots : sampledSlots ).map( slot => (
                    <Grid
                      item
                      key={slot.id}
                      xs={12} sm={'auto'}
                    >
                      <Button
                        key={slot.id}
                        variant='outlined'
                        size='small'
                        onClick={() => setOpenScheduleDialog( slot )}
                        fullWidth={isXSmall}
                        sx={{
                          margin: { xs: '0.5rem 0', sm: '0.5rem' },
                          width: { xs: undefined, sm: '10rem', md: '9rem' },
                          '& .MuiTypography-root': {
                            margin: 'inherit',
                          },
                        }}
                        disableRipple
                        className='translated'
                      >
                        <Typography
                          component='span'
                          sx={{
                            margin: '0.5rem 1rem 0 0',
                          }}>
                          {prettyTime( slot.start, timeZoneName )}
                        </Typography>
                      </Button>
                    </Grid>
                  ) )
                  : (
                    <Typography pt={2}>No available slots</Typography>
                  )
                }
                {filteredSlots.length > sampledSlots.length &&
                  <Grid item key='more'>
                    <Button
                      variant='text'
                      size='medium'
                      onClick={() => setMoreTimes( !moreTimes )}
                      sx={{
                        margin: '0.5em',
                        width: { xs: '12rem', sm: '10rem', md: '9rem' },
                        // '& .MuiTypography-root': {
                        //   margin: 'inherit',
                        // },
                      }}
                      disableRipple
                      className='translated'
                    >
                      <Typography component='span' sx={{ margin: '0.5rem 1rem 0 0' }}>
                        {moreTimes ? 'less' : 'more'}
                      </Typography>
                    </Button>
                  </Grid>
                }
              </Grid>

            </Slide>
            <ConfirmDialog
              open={!!openScheduleDialog}
              title={'Book appointment'}
              message={`Do you want to book the appointment for ${ prettyTime( openScheduleDialog?.start, timeZoneName ) } on ${ prettyDate( openScheduleDialog?.start, timeZoneName ) } ?`}
              confirmButton='Yes'
              cancelButton='No'
              onClose={async ( yes ) => {
                const slot = openScheduleDialog;
                setOpenScheduleDialog( undefined );
                if( slot && yes ) {
                  await onSchedule( slot );
                }
              }}
            />
          </>
        }

        <Box>

        </Box>

      </Stack>
    </div >
  );
}




