/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import { ArrowBackIosNew } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC } from 'react';
import { useHref, useOutletContext } from 'react-router-dom';
import { FeedbackContext } from './FeedbackContainer';
import { NonProfitTicker } from './components/NonProfitTicker';
import styles from './styles/Home.module.css';
import { LocationInterface, OrganizationInterface } from './types';

export type FeedbackParams = { feedbackId: string };

export const Feedback: FC = () => {
  const { feedbackId, recipient, location, practitioner, organization, disableCharities,
    // colorPrimary, colorSecondary, fontBody, fontHeader, setNotify, setError, setTitle, isXSmall, isSmall, isMedium, countryCode: regionCode = 'US'
  } = useOutletContext<FeedbackContext>();

  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

  const hrefNext = disableCharities ? useHref( '../reviews' ) : useHref( '../nonprofits' );


  if( !( recipient && organization && location && practitioner ) ) return null;

  return (
    <Box sx={{
    }}>
      <Grid item key="content">
        <Typography variant='h6' className={styles.description}
          sx={{ margin: '0.5em 0' }}
        >
          EXCELLENCE IN HEALTH CARE
        </Typography>

        <p>{recipient.firstName}{ /* ({recipientType}) */}, </p>
        <p>Thank you, again, for visiting us at <b className='translated'>{location.name}</b> and practitioner <b className='translated'>{practitioner.fullName}</b>.</p>
        <p>
          To help others, please leave a review of our practice on public websites such as Google and Yelp.
        </p>
        {!disableCharities &&
          <>
            <p>
              As a token of our gratitude for your feedback, we will make donation to a non-profit
              of your choice. On the next page you can select your favorite charity.

            </p>

            {/* <h4>
            WERE THERE ANY PROBLEMS WITH YOUR VISIT?
            </h4>
            <p>
            If there is an issue or you have had any difficulty working with us, please
            give us the chance to make it right before your evaluation.
            Our phone number is <a href={`tel:${ location.telephone }`}>{location.telephone}</a> or
            email us at <a href={`mailto:${ location.email }`}>{location.email}</a>.
            </p>
            <p>
            We sincerely care about your experience and proving you with the best
            healthcare.
            </p>
          */}
            {/* <p>
            As a token of our gratitude for your feedback, we will make donation to a non-profit
            of your choice.
            On the next page you can select your favorite charity.
            </p> */}

            <Grid item>
              <NonProfitTicker />
            </Grid>

            <Typography variant="h6" align="center" mt={2}>
              GRATITUDE & CHARITY MAKE THE WORLD BETTER
            </Typography>
          </>
        }
      </Grid>


      <Grid item container key="nextButton" justifyContent="flex-end" sx={{ marginTop: '2em' }}>
        <Button variant="contained" size="large" href={hrefNext} color="success" fullWidth={isXSmall}>
          Next
        </Button>
      </Grid>
    </Box>
  )
}

export interface TopSectionProps {
  location?: LocationInterface;
  organization?: OrganizationInterface;
  fontHeader?: string;
  colorPrimary?: string;
  isXSmall?: boolean;
}

export const TopSection: FC<TopSectionProps> = props => {
  const { location, organization, fontHeader, colorPrimary, isXSmall } = props;
  return (
    <>
      <Grid item container direction="column"
        sx={{
          // padding: { xs: '0 -2em', md: 'inherit' },
          // width: { xs: '120%', md: '100%' },
        }}
      >
        <Link
          href='/'
          sx={{
            margin: { xs: '0 -2.5em', md: 'inherit' },
            width: { xs: '120%', md: '100%' },
            textDecorationLine: 'none',
          }}
        >
          <Typography variant='h1'
            align={isXSmall ? 'center' : 'left'}
            sx={{
              fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
              backgroundColor: colorPrimary,
              color: 'white',
              width: '100%',
              padding: { xs: '0.5rem 3.0rem', md: '0.5rem 1.0rem' },
              fontWeight: 'bold', //'600',
              fontSize: 'large',
              fontSmooth: 'always',
            }}
            className='translated'
          >
            AIC Analog Concierge &trade;
            { /* transparent button used to match height of Location header bar */}
            <IconButton sx={{ color: 'transparent' }}>
              <ArrowBackIosNew />
            </IconButton>
          </Typography>
        </Link>
      </Grid>

      <Link
        key='name'
        href={`/locations/${ location?.slug || '' }`} sx={{ width: '100%', textDecorationLine: 'none' }}>
        <Typography variant='h1'
          align={ /* isXSmall ? 'center' : */ 'left'}
          style={{
            fontFamily: !isXSmall ? fontHeader : undefined,
            // backgroundColor: colorPrimary,
            color: colorPrimary, // 'white',
            width: '100%',
            padding: '0.5rem 0',
            fontWeight: 'bold', //'600',
            fontSize: 'xx-large',
            fontSmooth: 'always',
          }}
          className='translated'
        >
          {location?.name || organization?.name}
        </Typography>
      </Link>
    </>
  );
}
