import { FC } from 'react';
import { useRouteError } from 'react-router-dom';

type NormalError = { statusText?: string, message?: string };

export const ErrorPage: FC = () => {
  const error = useRouteError();
  console.error( error );
  const typedError = error as NormalError;

  return (
    <div id='error-page'>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{typedError?.statusText || typedError?.message}</i>
      </p>
    </div>
  );
}
