import { Typography } from '@mui/material';
import { FC } from 'react';

export const AppointmentConfirmed: FC = () => {

  return (
    <Typography
      // align='center'
      sx={{
        margin: '2rem 0',
        width: '100%',
        fontStyle: 'italic',
        fontSize: '120%',
      }}
    // color={colorPrimary || 'primary'}
    >

      Thank you for confirming your appointment!
      We look forward to seeing you.

    </Typography>
  );
}
