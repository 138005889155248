import { Box, Button, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Toolbar, Typography } from '@mui/material';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { validate as validateEmail } from 'email-validator';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { prettyTimezone, scrollDown } from './AppointmentContainer';
import { normalizePhone, validatePhoneNumber } from './components/AwesomePhoneField';
import { states } from './components/states';
import { createProfile, ProfileCreateFormResponse } from './profiles';
import { ProfileSearchContext } from './ProfileSearch';

dayjs.extend( utc );


export const ProfileCreate: FC = () => {
  const data = useOutletContext() as ProfileSearchContext;
  const { name, birthDate, setError, setNotify, setTitle, setHeader, isXSmall } = data;
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect( () => setHeader( 'New Appointment - Create New Patient' ), [ setHeader ] );

  const [ response, setResponse ] = useState<ProfileCreateFormResponse | undefined>();

  const onSubmit = useCallback( async ( data: ProfileCreateFormData ) => {
    if( !executeRecaptcha ) return;
    const recaptcha = await executeRecaptcha( 'createProfile' );
    const profile = { ...data, recaptcha };
    // console.log( JSON.stringify( profile ) );
    const response = await createProfile( profile );
    const { errors } = response;
    if( errors ) {
      setError( errors[ 0 ] );
      return errors;
    }
    setResponse( response );
  }, [ executeRecaptcha, setResponse ] );

  useEffect( () => {
    if( !response || response.errors || !response.profileId ) return;
    setNotify( 'New patient profile created successfully' );
    navigate( `/app/profiles/${ response.profileId }/appointments/locations` );
  }, [ response ] );

  if( !( name && birthDate ) ) return null;

  return (


    <Box>
      <ProfileCreateForm
        data={{
          name,
          birthDate,
          timeZoneName: prettyTimezone(),
        }}
        onSubmit={onSubmit}
      />

    </Box>
  );
}


export interface ProfileCreateFormData {
  name: string;
  birthDate: Date;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip?: string;
  gender: 'male' | 'female' | 'unknown';
  phone: string;
  email: string;
  timeZoneName?: string;
  recaptcha?: string;
}

export interface ProfileCreateFormProps {
  data: Partial<ProfileCreateFormData>;
  countryCode?: string;
  onSubmit: ( data: ProfileCreateFormData ) => void; // TODO
  onClose?: () => void;
}

export const ProfileCreateForm: FC<ProfileCreateFormProps> = ( props ) => {
  const { countryCode = 'US', onSubmit, data: defaultValues = {} } = props;
  const { control, getFieldState, getValues, handleSubmit, formState: { errors, isDirty, isValidating, isValid, isSubmitted, isSubmitting } } = useForm<ProfileCreateFormData>( { defaultValues } );
  const sectionAddress = useRef<HTMLHRElement>( null );
  const sectionContact = useRef<HTMLHRElement>( null );

  const [ section, setSection ] = useState( 0 );



  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} >
      < Box
        component='form'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& .MuiTextField-root, & .MuiInputBase-root, & .MuiDivider-root': {
            width: { xs: '100%', sm: '20rem' },
          },
          '& .MuiDivider-root': {
            marginTop: '4rem',
            marginBottom: '1rem',
          },
          '& .MuiToolbar-root': {
            marginTop: '1rem',
          },
          '& .MuiButton-root': {
            width: { xs: '100%', sm: '10rem' },
          },
        }
        }
      >

        <Controller
          name='name'
          control={control}
          rules={{
            required: 'Name is required',
            pattern: {
              value: /\S{2}\s+\S{2}/,
              message: 'First and last names are required',
            },
          }}
          render={( { field } ) => (
            <TextField
              {...field}
              inputRef={field.ref}
              required
              margin='normal'
              label='Name'
              placeholder='First Last'
              // fullWidth
              variant='filled'
              error={!!errors?.name?.message}
              helperText={errors?.name?.message}
            />
          )}
        />

        <Controller
          name='birthDate'
          control={control}
          rules={{
            required: 'Birth date is required',
            // valueAsDate: true,
          }}
          render={( { field } ) => {
            const { value, ...rest } = field;
            return (
              <DateField
                {...rest}
                value={dayjs( value )}
                timezone='UTC'
                onChange={( e ) => e && field.onChange( e )}
                inputRef={field.ref}
                required
                margin='normal'
                label='Birth date'
                // fullWidth
                variant='filled'
              // error={!!errors?.birthDate?.message}
              // helperText={errors?.birthDate?.message}
              />
            )
          }}
        />

        <Box mt='3rem' mb='1rem' width='20rem'>
          <Typography fontSize='1.2rem'>
            {`We did not find a matching patient so let's set up a new patient.`}
          </Typography>
        </Box>

        <Controller
          name='gender'
          control={control}
          render={( { field } ) => (
            <FormControl
              margin='normal'
              required
            >
              <FormLabel id="demo-controlled-radio-buttons-group">Birth Sex</FormLabel>
              <RadioGroup
                {...field}
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                row
              >
                <FormControlLabel value="male" control={<Radio />} label="Male" />
                <FormControlLabel value="female" control={<Radio />} label="Female" />
                <FormControlLabel value="unknown" control={<Radio />} label="Unknown" />
              </RadioGroup>
            </FormControl>
          )}
        />

        {
          section == 0 &&
          <Toolbar >
            <Button
              variant='contained'
              // @ts-ignore:2345
              disabled={[ 'gender' ].reduce( ( tot, f ) => tot || ( !getFieldState( f ).isDirty || getFieldState( f ).invalid ), false )}
              onClick={() => {
                setSection( 1 );
                scrollDown( sectionAddress );
              }}
              disableRipple
            >
              Next
            </Button>
          </Toolbar>
        }
        {
          section >= 1 &&
          <>
            <Divider textAlign='center' ref={sectionAddress}> Address </Divider>

            <Controller
              name='address1'
              control={control}
              rules={{
                required: 'Street is required',
              }}
              render={( { field } ) => (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  autoFocus
                  required
                  margin='normal'
                  label='Street'
                  placeholder='123 Main St.'
                  variant='filled'
                  error={!!errors?.address1?.message}
                  helperText={errors?.address1?.message}
                />
              )}
            />

            <Controller
              name='address2'
              control={control}
              render={( { field } ) => (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  margin='normal'
                  label=''
                  placeholder='Apt 100'
                  variant='filled'
                  error={!!errors?.address2?.message}
                  helperText={errors?.address2?.message}
                />
              )}
            />

            <Controller
              name='city'
              control={control}
              rules={{
                required: 'Street is required',
              }}
              render={( { field } ) => (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  required
                  margin='normal'
                  label='City'
                  placeholder=''
                  // fullWidth
                  variant='filled'
                  error={!!errors?.city?.message}
                  helperText={errors?.city?.message}
                />
              )}
            />
            { /*
        <Controller
          name='state'
          control={control}
          rules={{
            required: 'State is required',
          }}
          render={( props ) => (
            <Autocomplete
              {...props}
              options={states.map( s => ( { id: s.name, label: s.name } ) )}
              // getOptionLabel={s => s.name}
              // renderOption={s => <span>{s.name}</span>}
              // autoComplete
              autoSelect
              autoHighlight
              selectOnFocus
              disableClearable
              onChange={( _e, value ) => { props.field.onChange( value?.id || '' ) }}
              onInputChange={( _e, value ) => props.field.onChange( value )}
              isOptionEqualToValue={( option, value ) => option.id.toLowerCase() == value.toLowerCase() || option.label.toLowerCase() == value.toLowerCase()}
              renderInput={( params ) => (
                <TextField
                  {...params}
                  inputRef={props.field.ref}
                  required
                  margin='normal'
                  label='State'
                  placeholder=''
                  fullWidth
                  variant='filled'
                  autoComplete='off'
                />
              )}
            // required
            // margin='normal'
            // label='State'
            // placeholder=''
            // fullWidth
            // variant='filled'
            // error={!!errors?.state?.message}
            // helperText={errors?.name?.message}
            />
          )}
        />
           */}

            <FormControl
              fullWidth
              variant='filled'
              margin='normal'
            >
              <InputLabel id='state-select-label'>State</InputLabel>
              <Controller
                name='state'
                control={control}
                rules={{
                  required: 'State is required',
                }}
                render={( { field } ) => (
                  <Select
                    labelId='state-select-label'
                    {...field}
                    // getOptionLabel={s => s.name}
                    // renderOption={s => <span>{s.name}</span>}
                    // autoComplete
                    // open={stateOpen}
                    onChange={( e ) => { field.onChange( e.target.value ) }}
                    required
                    // margin='normal'
                    label='State'
                    // placeholder=''
                    fullWidth
                    variant='filled'
                    error={!!errors?.state?.message}
                    // helperText={errors?.state?.message}
                    MenuProps={{
                      sx: {
                        maxHeight: '16rem',
                        // left: '105px',
                      }
                    }}

                  >
                    {states.map( s =>
                      <MenuItem
                        value={s.abbreviation}
                        className='translated'
                      >
                        {s.name}
                      </MenuItem>
                    )}
                  </Select>
                )}
              />
              <FormHelperText
                id="my-helper-text"
                error={!!errors?.state?.message}
              >
                {errors?.state?.message}
              </FormHelperText>
            </FormControl>

            <Controller
              name='zip'
              control={control}
              rules={{
                pattern: {
                  value: /^[0-9]{5}(-?[0-9]{4})?$/,
                  message: 'Zip can be 5 or 9 digits only',
                }
              }}
              render={( { field } ) => (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  margin='normal'
                  label='Zip'
                  placeholder='99999'
                  // fullWidth
                  variant='filled'
                  error={!!errors?.zip?.message}
                  helperText={errors?.zip?.message}
                />
              )}
            />
          </>
        }

        {
          section == 1 &&
          <Toolbar >
            <Button
              variant='contained'
              // @ts-ignore:2345
              disabled={[ 'address1', 'city', 'state' ].reduce( ( tot, f ) => tot || ( !getFieldState( f ).isDirty || getFieldState( f ).invalid ), false )}
              onClick={() => {
                setSection( 2 );
                scrollDown( sectionContact );
              }}
              disableRipple
            >
              Next
            </Button>
          </Toolbar>
        }

        {
          section >= 2 &&
          <>
            <Divider textAlign='center' ref={sectionContact} > Contact </Divider>

            <Controller
              name='email'
              control={control}
              rules={{
                required: 'Email is required',
                validate: {
                  valid: ( email ) => !email || validateEmail( email ) || 'Must be an email address',
                }
              }}
              render={( { field } ) => (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  onChange={( e ) => field.onChange( e.target.value.toLowerCase() )}
                  margin='dense'
                  label='Email'
                  type='email'
                  fullWidth
                  required
                  variant='filled'
                  error={!!errors?.email?.message}
                  helperText={errors?.email?.message}
                />
              )}
            />

            <Controller
              name='phone'
              control={control}
              rules={{
                required: 'Phone is required',
                validate: {
                  valid: ( phone ) => !phone || validatePhoneNumber( countryCode )( phone ) || 'Must be a phone number',
                },
              }}
              render={( { field } ) => {
                // field.onChange( normalizePhone( countryCode ) )
                return (
                  <TextField
                    {...field}
                    inputRef={field.ref}
                    onChange={( e ) => field.onChange( normalizePhone( countryCode )( e.target.value ) )}
                    margin='dense'
                    label='Phone'
                    fullWidth
                    required
                    variant='filled'
                    error={!!errors?.phone?.message}
                    helperText={errors?.phone?.message}
                  />
                );
              }}
            />


            <Toolbar >
              <Button
                variant='contained'
                disabled={!isDirty || isValidating // || isSubmitting
                  // || isSubmitted
                }
                onClick={handleSubmit( onSubmit, ( v ) => { console.log( errors, v ) } )}
                disableRipple
              >
                Create
              </Button>
            </Toolbar>
          </>
        }
      </Box >
    </LocalizationProvider >
  );


}


