import TextField, { FilledTextFieldProps, TextFieldProps } from '@mui/material/TextField';
import { parsePhoneNumber, getExample, getAsYouType } from 'awesome-phonenumber';
import { ChangeEventHandler, FC, useState } from 'react';

export const renderPhoneNumber = ( phone?: string, regionCode = 'US' ): string => {
  return phone ? parsePhoneNumber( phone, { regionCode } ).number?.national || phone : '';
}

export const cleanPhoneNumber = ( phone?: string, regionCode = 'US' ): string => {
  return phone ? parsePhoneNumber( phone, { regionCode } ).number?.significant || phone : '';
}

export const validatePhoneNumber = ( regionCode: string ) => ( value?: string ): boolean => {
  if( !value ) return false;
  const number = parsePhoneNumber( value, { regionCode } );
  if( !number.possible ) return false; // weed out too short to slice
  if( number.number?.e164.slice( 2, 5 ) == '555' ) return true; // allow area code 555
  return number.valid;
}

export const normalizePhone = ( regionCode: string ) => ( value: string ) => {
  if( !value ) return value;
  const example = getExample( regionCode, 'fixed-line-or-mobile' ).number?.significant;
  const onlyNums = value.replace( /[^\d]/g, '' ).slice( 0, example?.length || 12 );
  const ayt = getAsYouType( regionCode );
  return ayt.reset( onlyNums );
}


export interface AwesomePhoneFieldProps extends FilledTextFieldProps {
  regionCode?: string;
  errorText?: string;
}

export const AwesomePhoneField: FC<AwesomePhoneFieldProps> = props => {
  const { regionCode = 'US', variant, onChange: onChangeGiven, error: isError, errorText, ...rest } = props;
  const [ error, setError ] = useState( false );

  const handleChange: ChangeEventHandler<HTMLInputElement> = ( event ) => {
    normalizePhone( event );
    onChangeGiven && onChangeGiven( event );
  }

  const normalizePhone: ChangeEventHandler<HTMLInputElement> = ( event ) => {
    const { value } = event.target;
    if( !value ) return value;
    const example = getExample( regionCode, 'fixed-line-or-mobile' ).number?.significant;
    const onlyNums = value.replace( /[^\d]/g, '' ).slice( 0, example?.length || 12 );
    const ayt = getAsYouType( regionCode );
    event.target.value = ayt.reset( onlyNums );
  }

  const validate: TextFieldProps[ 'onBlur' ] = ( event ) => {
    const { value } = event.target;
    if( !value ) return;
    const number = parsePhoneNumber( value, { regionCode } );
    if( number.number?.e164.slice( 2, 5 ) == '555' ) return; // allow area code 555
    if( !number.valid ) {
      setError( true );
    }
  }
  //  
  // const formatNumber: AwesomePhoneFieldProps[ 'format' ] = ( value?: string ) => {
  //   return !value ? undefined : parsePhoneNumber( value, countryCode ).getNumber( 'national' );
  // }

  const placeholder = (): AwesomePhoneFieldProps[ 'placeholder' ] => {
    return getExample( regionCode, 'fixed-line-or-mobile' ).number?.national;
  }

  return (
    <TextField
      variant='filled'
      onChange={handleChange}
      onBlur={validate}
      placeholder={placeholder()}
      helperText={error && 'Must be a valid phone number.' || isError && errorText}
      error={error || isError}
      {...rest} />
  );

}
