/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import { DetailedHTMLProps, FC, useMemo, useRef } from 'react';

const {
  REACT_APP_API_SERVER: apiServer = '', // 'http://localhost:3400',
  REACT_APP_IMAGE_SERVER: imageServer = apiServer,  // 'https://gunter.analoginformation.com/api/v1/image';
} = process.env;

const imagePath = `${ imageServer }/api/v1/image`;

export const titleAlt = ( label: string ): { title: string, alt: string } => ( { title: label, alt: label } );

export interface ImageProps extends DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  fit?: 'contain' | 'cover' | 'fill' | 'inside' | 'outside';
  position?: 'north' | 'northeast' | 'southeast' | 'south' | 'southwest' | 'west' | 'northwest' | 'east' | 'center' | 'centre';
}

export const Image: FC<ImageProps> = ( props ) => {
  const { src, width, height, fit, position, ...rest } = props;
  const url = useMemo<string>( () => {
    if( !src || !width || !height ) return '';
    const [ w, h ] = [ width, height ].map( x => Math.round( parseFloat( x.toString() ) * window.devicePixelRatio ) );
    return buildImageUrl( src, { w, h, fit, position } )
  }, [ src, width, height ] );

  if( !src ) return null;

  return <img src={url} width={width} height={height} {...rest} />
};


export interface BuildImageSize {
  w: number;
  h: number;
  fit?: 'contain' | 'cover' | 'fill' | 'inside' | 'outside';
  position?: 'north' | 'northeast' | 'southeast' | 'south' | 'southwest' | 'west' | 'northwest' | 'east' | 'center' | 'centre';
}

export const buildImageUrl = ( imageId: string, size?: BuildImageSize ): string => {

  if( size ) {
    const params = [
      [ 'w', Math.round( size.w ) ],
      [ 'h', Math.round( size.h ) ]
    ];
    if( size.fit ) {
      params.push( [ 'fit', size.fit ] );
      if( size.position ) params.push( [ 'position', size.position ] );
    }
    const query = params.map( p => p.join( '=' ) ).join( '&' );
    return `${ imagePath }/${ imageId }.webp?${ query }`;
  }
  return `${ imagePath }/${ imageId }.jpg`;
}

// export const buildImageUrl = function( this: HTMLImageElement | null | undefined | void, imageId: string, size?: { w: number, h: number } ): string {
//   if( this ) {
//     const { width, height } = getComputedStyle( this );
//     const [ ww, hh ] = [ width, height ].map( x => Math.round( parseFloat( x ) * window.devicePixelRatio ) );
//     return `${ imagePath }/${ imageId }?w=${ ww }&h=${ hh }`;
//   }
//   if( size ) return `${ imagePath }/${ imageId }?w=${ Math.round( size.w ) }&h=${ Math.round( size.h ) }`;
//   return `${ imagePath }/${ imageId }`;
// }

