import { Box, Button, CircularProgress, Toolbar } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { CompleteEvent, Model } from 'survey-core';
// import 'survey-core/defaultV2.min.css';
import 'survey-core/modern.min.css';
import { SurveyPDF } from 'survey-pdf';
import { Survey } from 'survey-react-ui';
import { ProfileFormContext } from './ProfileFormContainer';
import { postFormSubmission } from './profiles';


export const ProfileFormUpdate: FC = () => {
  const profileFormContext = useOutletContext<ProfileFormContext>();
  const { profileForm, setError } = profileFormContext || {};
  const { form: formId, formName, formJson, submission } = profileForm || {};

  const surveyComplete = useCallback( async ( sender: Model, options: CompleteEvent ) => {
    const data = {
      submission: JSON.stringify( sender.data ),
      status: 'Submitted',
      phone: sender.data.mphone || sender.data.hphone || undefined,
      email: sender.data.email,
    };
    await postFormSubmission( formId, data );
  }, [ formId ] );

  const form = useMemo( () => {
    if( !formJson ) return;
    try {
      return JSON.parse( formJson );
    } catch( e ) {
      setError( 'Error reading form data' );
    }
  }, [ formJson ] );

  const survey = useMemo( () => {
    if( !form ) return;
    const survey = new Model( form );
    survey.mergeData( submission );

    survey.onComplete.add( surveyComplete );

    const savePdf = ( surveyData: unknown ) => {
      const surveyPdf = new SurveyPDF( form, {
        fontSize: 10,
        // haveCommercialLicense: true,
        applyImageFit: true,
      } );
      surveyPdf.data = surveyData;
      surveyPdf.save( `${ formName }.pdf` );
    };
    survey.addNavigationItem( {
      id: 'pdf-export',
      title: 'Save as PDF',
      action: () => savePdf( survey.data )
    } );
    return survey;
  }, [ form ] );


  if( !survey ) return <CircularProgress />;

  return (
    <Box>
      { /*
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          color='primary'
          startIcon={<Save />}
          onClick={() => savePdf()}
        >
          Save as PDF
        </Button>
      </Toolbar>
      */}
      <Survey model={survey} id="surveyContainer" />
    </Box>
  );
};

