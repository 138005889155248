import { FC, useMemo } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { ProfileContext } from './ProfileContainer';
import { ProfileFormsContext } from './ProfileFormsContainer';
import { ProfileForm } from './profiles';

export interface ProfileFormContext extends ProfileFormsContext {
  profileForm?: ProfileForm;
}

export const ProfileFormContainer: FC = () => {
  const { formId } = useParams();
  const profileFormsContext = useOutletContext<ProfileFormsContext>();
  const profileForm = useMemo( () => ( profileFormsContext?.profileForms || [] ).find( f => f.id == formId ), [ profileFormsContext ] );

  const context = {
    ...profileFormsContext,
    profileForm,
  };

  return (
    <>
      <Outlet
        context={context}
      />

    </>

  );
}
