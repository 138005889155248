import { Clear as ClearIcon } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import throttle from 'lodash/throttle';
import { CSSProperties, FC, useEffect, useMemo, useState } from 'react';

export interface Option {
  name: string;
  id: string;
  logo_url: string;
  mission: string;
  website_url: string;
  city: string;
  region: string;
  country: string;
}

const apiServer = process.env.REACT_APP_API_SERVER || '';
const apiPath = process.env.REACT_APP_API_PATH || '/api/v1/data';

export interface NonProfitSearchProps {
  initialValue?: Option;
  token: string;
  style?: CSSProperties;
  onChange?: ( value: Option | null ) => void;
}

export const NonProfitSearch: FC<NonProfitSearchProps> = ( props ) => {
  const { token, onChange, initialValue } = props;
  const [ value, setValue ] = useState<Option | null>( initialValue || null );
  const [ inputValue, setInputValue ] = useState<string>( '' );
  const [ options, setOptions ] = useState<readonly Option[]>( [] );
  const [ topOptions, setTopOptions ] = useState<readonly Option[]>( [] );
  const isMedium = useMediaQuery( '(min-width:600px)' );

  const fetchOptions = useMemo( () => throttle(
    async ( input: string ): Promise<Option[]> => {
      const response = await fetch( `${ apiServer }${ apiPath }/nonprofits?q=${ encodeURIComponent( input ) }&t=${ token }` );
      if( !response.ok ) return [];
      return ( await response.json() ) as Option[];
    }, 200 ), [ token ] );

  const fetchTopOptions = useMemo( () => async ( limit = 10 ): Promise<Option[]> => {
    const response = await fetch( `${ apiServer }${ apiPath }/nonprofits?t=${ token }` );
    if( !response.ok ) return [];
    return ( ( await response.json() ) as Option[] ).slice( 0, limit );
  }, [ token ] );

  const handleChange = async ( _event: unknown, newValue: Option | null ) => {
    setValue( newValue );
    setOptions( newValue ? [ newValue, ...options ] : options );
    await fetch( `${ apiServer }${ apiPath }/nonprofits/select`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( { t: token, id: newValue?.id || '' } ),
    } );
    console.log( 'selected', newValue?.name, newValue?.id );
    onChange && onChange( newValue );
  };

  const handleClick = ( newValue: Option | null ) => async ( event: unknown ) => {
    handleChange( event, newValue );
  };

  useEffect( () => {
    ( async () => {
      const options = await fetchOptions( inputValue );
      if( options ) setOptions( options );
    } )();
  }, [ value, inputValue, fetchOptions ] );

  useEffect( () => {
    ( async () => {
      const options = await fetchTopOptions( isMedium ? 9 : 6 );
      if( options ) setTopOptions( options );
    } )();
  }, [ fetchTopOptions, isMedium ] );


  return (
    <>

      {value?.name
        ? (
          <>
            <Card sx={{ margin: '2em 0', padding: '1em', position: 'relative' }} elevation={4}>
              <IconButton
                aria-label="reset"
                onClick={handleClick( null )}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  zIndex: 10,
                }}
              >
                <ClearIcon />
              </IconButton>
              <Grid container justifyContent='space-between' alignItems='flex-start' rowSpacing={2} >
                <Grid item xs={12} md={3}>
                  <CardMedia
                    component="img"
                    image={value.logo_url}
                    alt={`${ value.name } logo`}
                    sx={{
                      maxWidth: '200px',
                      maxHeight: '200px',

                    }}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <CardHeader title={value.name} subheader={value.city} sx={{ paddingTop: 0, paddingBottom: 0 }} className='translated' />
                  <CardContent className='translated'              >
                    <Typography gutterBottom component="div" variant="body2" color="text.secondary" sx={{
                      // https://css-tricks.com/line-clampin/
                      display: '-webkit-box',
                      WebkitLineClamp: '4', WebkitBoxOrient: 'vertical', overflow: 'hidden',
                    }}
                    >
                      {value.mission}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <Link href={value.website_url} target="_blank" rel="noopener">More info</Link>
                    </Typography>
                  </CardContent>
                </Grid>

              </Grid>

            </Card>
          </>
        )
        : (
          <>
            <Autocomplete
              id="nonprofit-select"
              sx={{ maxWidth: 500, marginBottom: '1.5rem' }}
              getOptionLabel={( option ) =>
                typeof option === 'string' ? option : option.name
              }
              isOptionEqualToValue={( option, value ) => {
                return typeof option === 'string' ? option === value : option.name === value.name
              }}
              filterOptions={( x ) => x}
              value={value}
              inputValue={inputValue}
              options={options}
              autoHighlight
              autoComplete
              onChange={handleChange}
              onInputChange={( _event, newInputValue ) => {
                setInputValue( newInputValue );
              }}
              renderOption={( props, option ) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <img
                    loading="lazy"
                    width="80"
                    src={option.logo_url}
                    srcSet={`${ option.logo_url } 2x`}
                    alt=""
                  />
                  {option.name}
                </Box>
              )}
              renderInput={( params ) => (
                <TextField
                  {...params}
                  label="Search other non-profit organizations"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <Grid container key="topOptions" columns={10} justifyContent="space-between" >
              {topOptions.map( option => (

                <Grid item xs={6} sm={3} sx={{}} key={option.id}>
                  <Button
                    onClick={handleClick( option )}
                    sx={{
                      height: '12em',
                      width: '100%',
                    }}
                  >
                    <Card
                      sx={{
                        p: '0.5em',
                        height: '8em',
                        width: '100%',
                        lineHeight: '110%',
                        overflow: 'hidden',
                        '& > img': { display: 'block', margin: 'auto', textAlign: 'center', mb: '8px' }
                      }}
                      elevation={option.id == value?.id ? 6 : 1}
                      className='translated'
                    >
                      <img
                        loading="lazy"
                        height="55%"
                        src={option.logo_url}
                        srcSet={`${ option.logo_url } 2x`}
                        alt=""
                      />
                      <Typography
                        sx={{
                          fontSize: { xs: 10, md: 14 },
                        }}

                      >
                        {option.name}
                      </Typography>
                    </Card>
                  </Button>
                </Grid>
              ) )
              }
            </Grid>
          </>
        )}
    </>
  );
};


