/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import { ArrowBackIosNew } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHref, useOutletContext, useParams } from 'react-router-dom';
import { getCommonTagId } from './components/AmenityChoices';
import { NonProfitTicker } from './components/NonProfitTicker';
import { PageFooter } from './components/page-footer';
import { getFeedback } from './profiles';
import styles from './styles/Home.module.css';
import { AssetInterface, LocationInterface, OrganizationInterface, OrganizationStyling, PractitionerInterface, RecipientInterface } from './types';
import { FeedbackContext } from './FeedbackContainer';

export type FeedbackParams = { feedbackId: string };

export const FeedbackFormThankYou: FC = () => {
  const { feedbackId, feedback, recipient, location, practitioner, organization,
    colorPrimary, colorSecondary, fontBody, fontHeader,
    disableCharities, selectedOption, reputationServices,
    // setNotify, setError, setTitle, isXSmall, isSmall, isMedium,
    countryCode: regionCode = 'US'
  } = useOutletContext<FeedbackContext>();
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );
  const hrefReviews = useHref( '../reviews', { relative: 'path' } );
  const hrefVeryLikely = useHref( '../nonprofits' );
  const hrefSlightly = useHref( '../form' );
  const hrefNotAtAll = useHref( '../form' );


  if( !( recipient && organization && location && practitioner ) ) return null;

  return (
    <Box sx={{
    }}>
      <Typography
        align='left'
        sx={{
          margin: '2rem 0',
          width: '100%',
          fontStyle: 'italic',
          fontSize: '120%',
          color: colorPrimary || 'primary',
        }}
        color='inherit'
      >
        We appreciate your feedback.
      </Typography>

    </Box>
  )
}

export interface TopSectionProps {
  location?: LocationInterface;
  organization?: OrganizationInterface;
  fontHeader?: string;
  colorPrimary?: string;
  isXSmall?: boolean;
}

export const TopSection: FC<TopSectionProps> = props => {
  const { location, organization, fontHeader, colorPrimary, isXSmall } = props;
  return (
    <>
      <Grid item container direction="column"
        sx={{
          // padding: { xs: '0 -2em', md: 'inherit' },
          // width: { xs: '120%', md: '100%' },
        }}
      >
        <Link
          href='/'
          sx={{
            margin: { xs: '0 -2.5em', md: 'inherit' },
            width: { xs: '120%', md: '100%' },
            textDecorationLine: 'none',
          }}
        >
          <Typography variant='h1'
            align={isXSmall ? 'center' : 'left'}
            sx={{
              fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
              backgroundColor: colorPrimary,
              color: 'white',
              width: '100%',
              padding: { xs: '0.5rem 3.0rem', md: '0.5rem 1.0rem' },
              fontWeight: 'bold', //'600',
              fontSize: 'large',
              fontSmooth: 'always',
            }}
          >
            AIC Analog Concierge &trade;
            { /* transparent button used to match height of Location header bar */}
            <IconButton sx={{ color: 'transparent' }}>
              <ArrowBackIosNew />
            </IconButton>
          </Typography>
        </Link>
      </Grid>

      <Link
        key='name'
        href='/' sx={{ width: '100%', textDecorationLine: 'none' }}>
        <Typography variant='h1'
          align={ /* isXSmall ? 'center' : */ 'left'}
          style={{
            fontFamily: !isXSmall ? fontHeader : undefined,
            // backgroundColor: colorPrimary,
            color: colorPrimary, // 'white',
            width: '100%',
            padding: '0.5rem 0',
            fontWeight: 'bold', //'600',
            fontSize: 'xx-large',
            fontSmooth: 'always',
          }}
        >
          {location?.name || organization?.name}
        </Typography>
      </Link>
    </>
  );
}
