import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { DirectionsBus as BusIcon, DirectionsCar as CarIcon, DirectionsRailway as TrainIcon } from '@mui/icons-material';
import { FC, ReactElement } from 'react';

const uberClientId = process.env.UberClientId || 'IVTVKt6Lja1l45P9rrONfTTeBMvhrL32'; // robert@justons.org on developer.uber.com

export interface Coordinates {
  latitude: number,
  longitude: number,
}

export const parseLatLng = ( latLng: string ): Coordinates => {
  const [ latitude, longitude ] = latLng.split( ',' ).map( l => parseFloat( l ) );
  return { latitude, longitude };
}

export const escapeAddress = ( address?: string ): ( string | undefined ) => (
  address ? encodeURIComponent( address.trim().replaceAll( /\n/g, '' ) ) : undefined
)

export type GoogleMapsDirectionsMode = 'driving' | 'walking' | 'transit';

export const googleMapsUrlAddress = ( mode: GoogleMapsDirectionsMode, address?: string ): string => {
  if( !address ) return '';
  return `https://maps.google.com/maps?daddr=${ escapeAddress( address ) }&directionsmode=${ mode }`; // comgooglemaps://maps.google.com/maps
  // URL( string: "https://maps.google.com/maps?saddr=Google+Inc,+8th+Avenue,+New+York,+NY&daddr=John+F.+Kennedy+International+Airport,+Van+Wyck+Expressway,+Jamaica,+New+York&directionsmode=transit" )!
}

export const googleMapsUrl = ( mode: GoogleMapsDirectionsMode, latLng: string ): string => {
  return `https://maps.google.com/maps?daddr=${ latLng }&directionsmode=${ mode }`;
}

export interface RideshareLinkProps {
  dropoff: string,
  latLng: string,
  // className/classes    // TODO HERE use to set height
}

export const getUberUrl = ( dropoff: string, latLng: string ): string => {
  const { latitude, longitude } = parseLatLng( latLng );
  let url = 'https://m.uber.com/ul/';  // var string = 'https://m.uber.com/ul/'
  url += `?client_id=${ uberClientId }`;
  url += `&action=setPickup`;
  const nickname = escapeAddress( dropoff );
  if( nickname ) {
    url += `&dropoff[nickname]=${ nickname }`;
  }
  if( latitude && longitude ) {
    url += `&dropoff[latitude]=${ latitude }`;
    url += `&dropoff[longitude]=${ longitude }`;
  } else {
    url += '&pickup=my_location';
  }
  return url;
}

export const getLyftUrl = ( dropoff: string, latLng: string ): string => {
  const { latitude, longitude } = parseLatLng( latLng );

  let url = 'https://lyft.com/ride';  // var string = 'lyft://ridetype'
  url += `?id=lyft`;
  // string += "?partner=\( LyftClientId )"
  url += `&action=setPickup`;
  const nickname = escapeAddress( dropoff );
  if( nickname ) {
    url += `&destination[nickname]=${ nickname }`;
  }
  if( latitude && longitude ) {
    url += `&destination[latitude]=${ latitude }`;
    url += `&destination[longitude]=${ longitude }`;
  } else {
    url += '&pickup=my_location';
  }
  return url;
}



export const RideShareLinks: FC<RideshareLinkProps> = props => {
  const { dropoff, latLng } = props;

  const items: { name: string, image: string, getUrl: ( dropoff: string, latLng: string ) => string }[] = [
    { name: 'Uber', image: '/uber.png', getUrl: getUberUrl },
    { name: 'Lyft', image: '/lyft.png', getUrl: getLyftUrl },
  ]

  return (
    <List>
      {items.map( ( { name, image, getUrl } ) => (
        <ListItem key={name} button component="a" href={getUrl( dropoff, latLng )} target="_blank" rel="noopener" >
          <ListItemIcon>
            <img src={image} alt={`${ name } logo`} height="80" width="80" aria-label={`${ name } logo`} />
          </ListItemIcon>
          <ListItemText primary={`Request a ride with ${ name }`} sx={{ paddingLeft: '1em' }} />
        </ListItem>
      ) )
      }
    </List>
  );
}


export const TransitLinks: FC<RideshareLinkProps> = props => {
  const { latLng } = props;

  const items: { name: string, icon: ReactElement, mode: GoogleMapsDirectionsMode }[] = [
    { name: 'Car', icon: <CarIcon />, mode: 'driving' },
    { name: 'Bus', icon: <BusIcon />, mode: 'transit' },
    { name: 'Train', icon: <TrainIcon />, mode: 'transit' }, // TODO
  ]

  return (
    <List>
      {items.map( ( { name, icon, mode } ) => (
        <ListItem key={name} button component="a" href={googleMapsUrl( mode, latLng )} >
          <ListItemIcon aria-label={`${ name } icon`}>
            {icon}
          </ListItemIcon>
          <ListItemText primary={`Direction by ${ name }`} />
        </ListItem>
      ) )
      }
    </List>
  );
}
