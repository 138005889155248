import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { getYear } from 'date-fns/getYear';
import { FC } from 'react';
import styles from '../styles/Home.module.css';


export interface PageFooterProps {
  organization?: { name: string; webUrl?: string };
}

export const PageFooter: FC<PageFooterProps> = () => (
  <footer className={styles.footer} >
    <Typography variant="caption" color="textSecondary"  >
      <span className='translated'>&copy;2021-{getYear( new Date() ).toString().slice( 2 )}</span>
      &nbsp;
      <Link href="https://analoginformatics.com/" target="_blank" rel="noopener" color="inherit" >
        Analog Informatics Corporation
      </Link>
    </Typography>
  </footer>

);


