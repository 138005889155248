import { CircularProgress } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import { CompleteEvent, Model, SurveyModel } from 'survey-core';
// import 'survey-core/defaultV2.min.css';
import 'survey-core/modern.min.css';
import { Survey } from 'survey-react-ui';
import { FormDefinitionData, postFormSubmission } from './profiles';
import { SurveyPDF } from 'survey-pdf';

const surveyJson = {
  elements: [
    {
      name: 'FirstName',
      title: 'Enter your first name:',
      type: 'text'
    }, {
      name: 'LastName',
      title: 'Enter your last name:',
      type: 'text'
    }
  ]
};

const SURVEY_ID = 1;

// const saveSurveyResults = ( url: string, json: Record<string, unknown> ) => {
//   fetch( url, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json;charset=UTF-8'
//     },
//     body: JSON.stringify( json )
//   } )
//     .then( response => {
//       if( response.ok ) {
//         // Handle success
//       } else {
//         // Handle error
//       }
//     } )
//     .catch( error => {
//       // Handle error
//     } );
// }

export const SurveyExample: FC = () => {
  const definition = useLoaderData() as FormDefinitionData | undefined;

  const survey = useMemo( () => {
    const alertResults = ( sender: SurveyModel ) => {
      const results = JSON.stringify( sender.data );
      alert( results );
    };
    if( definition?.json ) {
      try {
        const form = JSON.parse( definition.json );
        const savePdf = ( surveyData: unknown ) => {
          const surveyPdf = new SurveyPDF( form, {
            fontSize: 10,
            // commercial: true,
            // haveCommercialLicense: true,
            applyImageFit: true,
          } );
          surveyPdf.data = surveyData;
          surveyPdf.save();
        };

        // const values = {
        //   'first name': 'First',
        //   'last name': 'Last',
        //   panel12: {
        //     'date of birth': '04/01/2001',
        //     'Social Security Number': '123456789',
        //     'language': 'English',
        //   },
        //   panel13: {
        //     'ethnicity': 'Prefer not to answer',
        //     'question1': 'Female',
        //   },
        //   Address: {
        //     'street': '123 Main',
        //     'city': 'Central',
        //     'state': 'tx',
        //     'zip': '78777',
        //   },
        //   panel11: {
        //     'contact method': 'Item 1',
        //     'leave message': false,
        //   },
        //   panel2: {
        //     'mphone': '2122222222',
        //   },
        // };
        //  
        // for( const [ k, v ] of Object.entries( values ) ) {
        //   for( const page of form.pages ) {
        //     const idx = ( page.elements as { name: string, value: unknown }[] ).findIndex( e => e.name == k );
        //     if( idx >= 0 ) {
        //       form.pages[ 0 ].elements[ idx ].defaultValue = v;
        //     }
        //   }
        // }

        const survey = new Model( form );
        // survey.onComplete.add( alertResults );
        const formId = definition.id;
        const surveyComplete = useCallback( async ( sender: Model, options: CompleteEvent ) => {
          const data = {
            submission: JSON.stringify( sender.data ),
            status: 'Submitted',
            phone: sender.data.mphone || sender.data.hphone || undefined,
            email: sender.data.email,
          };
          await postFormSubmission( formId, data );
        }, [] );

        survey.onComplete.add( surveyComplete );
        survey.addNavigationItem( {
          id: 'pdf-export',
          title: 'Save as PDF',
          action: () => savePdf( survey.data )
        } );
        return survey;
        // eslint-disable-next-line no-empty
      } catch( e ) { }
    }
    const savePdf = ( surveyData: unknown ) => {
      const surveyPdf = new SurveyPDF( surveyJson, {
        fontSize: 12
      } );
      surveyPdf.data = surveyData;
      surveyPdf.save();
    };
    const survey = new Model( surveyJson );

    survey.mode = 'display';

    //survey.onComplete.add( alertResults );
    survey.addNavigationItem( {
      id: 'pdf-export',
      title: 'Save as PDF',
      action: () => savePdf( survey.data )
    } );
    return survey;
  }, [ definition ] );

  // const surveyComplete = useCallback( async ( sender: Model, options: CompleteEvent ) => {
  //   if( !definition?.id ) return;
  //   await postFormSubmission( definition.id, sender.data );
  //   // saveSurveyResults(
  //   //   "https://your-web-service.com/" + SURVEY_ID,
  //   //   sender.data
  //   // )
  // }, [] );
  //  
  // survey.onComplete.add( surveyComplete );


  return (
    <Survey model={survey} id="surveyContainer" />
  );
};

export const SurveyForm: FC = () => {
  const definition = useLoaderData() as FormDefinitionData;

  const survey = useMemo( () => {
    const alertResults = ( sender: SurveyModel ) => {
      const results = JSON.stringify( sender.data );
      alert( results );
    };
    if( definition?.json ) {
      try {
        const form = JSON.parse( definition.json );
        const survey = new Model( form );
        survey.onComplete.add( alertResults );
        return survey;
        // eslint-disable-next-line no-empty
      } catch( e ) { }
    }
  }, [ definition ] );

  if( !survey ) return <CircularProgress />;

  return (
    <Survey model={survey} />
  );
};

