import { FC } from 'react';
import { Outlet, useLoaderData, useOutletContext } from 'react-router-dom';
import { ProfileContext } from './ProfileContainer';
import { ProfileForm } from './profiles';

export interface ProfileFormsContext extends ProfileContext {
  profileForms: ProfileForm[];
}


export const ProfileFormsContainer: FC = () => {
  const profileContext = useOutletContext<ProfileContext>();
  const profileForms = useLoaderData() as ProfileForm[];

  const context = {
    ...profileContext,
    profileForms,
  }

  return (
    <Outlet context={context} />
  );
}

