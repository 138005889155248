import { Typography } from '@mui/material';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';


export const AppointmentCancel: FC = () => {

  return (
    <>
      <Typography
        sx={{
          margin: '2rem 0',
          width: '100%',
          fontStyle: 'italic',
          fontSize: '120%',
        }}

      >

        Your appointment is now cancelled.
      </Typography>
      <Typography
        sx={{
          margin: '2rem 0',
          width: '100%',
          fontStyle: 'italic',
          fontSize: '120%',
        }}

      >

        Thank you for letting us know!
        We look forward to seeing you again soon.

      </Typography>
    </>
  );
}
