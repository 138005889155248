import { alpha, Box, Button, Grid, Slide, Stack, Typography, useTheme } from '@mui/material';
import { uniqBy } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useRevalidator, useRouteLoaderData } from 'react-router-dom';
import { AppointmentContext, parseableDateTz, prettyDate, prettyShortDate, prettyTime, scrollDown } from './AppointmentContainer';
import { ConfirmDialog } from './components/ConfirmDialog';
import { AppointmentRescheduleSlot, rescheduleAppointment } from './profiles';


export const sample = <T,>( arr: T[], n: number ): T[] => {
  if( arr.length <= n ) return arr;
  const x = Math.floor( arr.length / n );
  if( x > 1 ) {
    return sample( arr.filter( ( _a, idx ) => idx % x == 0 ), n );
  }
  const y = Math.ceil( arr.length / ( arr.length - n ) );
  const b = arr.filter( ( _a, idx ) => idx % y != 0 );
  return [ ...b.slice( 0, n - 2 ), ...b.slice( -2 ) ];
}

export const AppointmentReschedule: FC = () => {
  const { appointment, isXSmall, setError, setNotify, setStartTime, setStatus, fontHeader } = useOutletContext() as AppointmentContext;
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const theme = useTheme();
  const sectionSlots = useRef<HTMLDivElement>( null );
  const sectionDays = useRef<HTMLDivElement>( null );
  const sectionTimes = useRef<HTMLDivElement>( null );
  const [ openRescheduleDialog, setOpenRescheduleDialog ] = useState<AppointmentRescheduleSlot | undefined>();

  const { timeZoneName } = appointment;
  const slots = useRouteLoaderData( 'appointment-reschedule' ) as AppointmentRescheduleSlot[];
  const [ date, setDate ] = useState<Date | undefined>();

  const isDaySelectedDate = useCallback( ( day: string ) => day == parseableDateTz( date, timeZoneName ), [ date, timeZoneName ] );

  type ShowTimes = 'Morning' | 'Afternoon' | 'Any Time';
  const allShowTimes: ShowTimes[] = [ 'Morning', 'Afternoon', 'Any Time' ]
  const [ showTimes, setShowTimes ] = useState<ShowTimes | undefined>();
  const [ moreTimes, setMoreTimes ] = useState( false );

  useEffect( () => scrollDown( sectionSlots ) );

  // const changeShowTimes = useCallback( ( _event: MouseEvent<HTMLElement>, newShowTimes: ShowTimes | null ) => {
  //   setShowTimes( newShowTimes || undefined );
  //   setDate( undefined );
  //   setMoreTimes( false );
  //   scrollDown( sectionDays );
  // }, [ sectionDays ] );

  const onClickShowTimes = useCallback( ( newShowTimes: ShowTimes ) => () => {
    setShowTimes( showTimes == newShowTimes ? undefined : newShowTimes )
    setDate( undefined );
    setMoreTimes( false );
    scrollDown( sectionDays );
  }, [ sectionDays.current?.offsetTop, showTimes, setDate, setMoreTimes ] );

  const onClickDate = useCallback( ( d: string ) => () => {
    setDate( new Date( d ) );
    setMoreTimes( false );
    scrollDown( sectionTimes );
  }, [ sectionTimes.current?.offsetTop, showTimes, setMoreTimes, setDate ] );

  const isMorning = ( d: string ): boolean => prettyTime( d, timeZoneName ).includes( 'AM' );

  const availableDates = useMemo( () => {
    if( !slots ) return [];
    const available = slots
      .filter( slot => showTimes == 'Any Time' || ( showTimes == 'Morning' ? isMorning( slot.start ) : !isMorning( slot.start ) ) )
      .map( slot => ( { label: prettyShortDate( slot.start, timeZoneName ), day: parseableDateTz( slot.start, timeZoneName ) } ) );
    return uniqBy( available, a => a.label );
  }, [ slots, showTimes ] );

  const filteredSlots = useMemo( () => {
    if( !slots ) return [];
    const day = prettyDate( date, timeZoneName );
    const available = slots
      .filter( slot => prettyDate( slot.start, timeZoneName ) == day )
      .filter( slot => showTimes == 'Any Time' || ( showTimes == 'Morning' ? isMorning( slot.start ) : !isMorning( slot.start ) ) )
    return available;
  }, [ slots, date, showTimes ] );

  const sampledSlots = useMemo( () => sample( filteredSlots, 19 ), [ filteredSlots ] );

  const onReschedule = useCallback( async ( slot: AppointmentRescheduleSlot ) => {
    const errors = await rescheduleAppointment( appointment.id, slot.start, slot.end );
    if( errors ) {
      setError( errors[ 0 ] );
      return;
    }
    setStatus( 'booked' );
    setStartTime( slot.start );
    setNotify( 'Your appointment has been rescheduled.' );
    revalidator.revalidate();
    navigate( '../..' ); // Was just "..", but update/add-new means sometimes the wrong data/breadcrumb
  }, [ navigate, appointment ] );


  const isAnyMorning = useMemo( () => !!slots?.find( slot => isMorning( slot.start ) ), [ 'slots' ] );
  const isAnyAfternoon = useMemo( () => !!slots?.find( slot => !isMorning( slot.start ) ), [ 'slots' ] );

  return (
    <>
      <h3
        style={{
          fontFamily: fontHeader,
        }}
      >
        Appointment Reschedule
      </h3>
      <div id='appointment-reschedule'>

        <Stack mb={2}

        >

          <Box
            sx={{
              textAlign: 'center',
              // border: '1px solid purple',
              // width: '100%'
            }}
          >

            <Box
              ref={sectionSlots}
              sx={{
                // border: '1px solid red',
                // margin: '0 auto',
                '& .MuiTypography-body1': {
                  margin: '1.25rem 1rem 1rem 0',
                  fontWeight: 'bold',
                  display: 'inline-block',
                },
              }}
            >
              <Typography
                component='span'
                sx={{
                }}
                align='center'
              >
                What is your preferred time of day?
              </Typography>

            </Box>


            <Grid container
              direction={isXSmall ? 'column' : 'row'}
              justifyContent='space-between'
              columnSpacing={isXSmall ? undefined : 2}

              sx={{
                // border: '1px solid purple',
                // marginLeft: { xs: '-16px', sm: 0 },
                '& .MuiButton-outlined': {
                  backgroundColor: alpha( theme.palette.primary.main, 0.08 ),
                  color: theme.palette.primary.main,
                  '&.Mui-selected, &.Mui-selected:hover': {
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                  },
                  '&:hover': {
                    backgroundColor: alpha( theme.palette.primary.main, 0.18 ),
                  },
                }
              }}
            >
              {allShowTimes.map( slot => (
                <Grid
                  item
                  key={slot}
                  xs={10} sm={Math.floor( 12 / allShowTimes.length )}
                  sx={{
                    marginBottom: { xs: '1rem', sm: 0 },
                  }}
                >

                  <Button
                    variant={showTimes == slot ? 'contained' : 'outlined'}
                    size='small'
                    onClick={onClickShowTimes( slot )}
                    fullWidth
                    sx={{
                      // border: '1px solid red',
                      padding: '1rem 0',
                      // margin: { xs: '0.5rem 0', sm: '0.5rem' },

                      width: { xs: undefined, sm: '100%' },
                      '& .MuiTypography-root': {
                        margin: 'inherit',
                      },
                    }}
                    disableRipple
                    disabled={slot == 'Morning' ? !isAnyMorning : slot == 'Afternoon' ? !isAnyAfternoon : !isAnyMorning && !isAnyAfternoon}
                  >
                    <Typography
                      component='span'
                      sx={{
                        margin: '0.5rem 1rem 0 0',
                      }}>
                      {slot}
                    </Typography>
                  </Button>

                </Grid>
              ) )
              }
            </Grid>
            {!slots.length &&
              <Box mt={3}>
                <Typography
                  component='span'
                  align='center'
                >
                  We could not find any open appointment slots at this time. Please contact the office.
                </Typography>
              </Box>
            }
          </Box>

          {showTimes &&
            <>
              <Box
                ref={sectionDays}
                sx={{
                  // border: '1px solid red',
                  margin: '2rem auto 0',
                  '& .MuiTypography-body1': {
                    // color: 'red',
                    margin: '1.25rem 1rem 1rem 0',
                    fontWeight: 'bold',
                    width: '16rem',
                    display: 'inline-block',
                  },
                }}
              >
                <Typography
                  component='span'
                  sx={{
                  }}
                  align='center'
                >
                  Available Dates for "{showTimes}"
                </Typography>

              </Box>


              <Slide in={!!showTimes} direction='up' >
                <Grid container
                  // direction={isXSmall ? 'column' : 'row'}
                  // justifyContent='space-between'
                  alignItems='flex-start'


                  sx={{
                    // height: '22rem',
                    // border: '1px solid purple',
                    '& .MuiButton-outlined': {
                      backgroundColor: alpha( theme.palette.primary.main, 0.08 ),
                      color: theme.palette.primary.main,
                      '&.Mui-selected, &.Mui-selected:hover': {
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                      },
                      '&:hover': {
                        backgroundColor: alpha( theme.palette.primary.main, 0.18 ),
                      },
                    }

                  }}
                >
                  {
                    availableDates.length
                      ? availableDates.map( availableDate => (
                        <Grid
                          item
                          key={availableDate.label}
                          xs={12} sm={'auto'}
                        >

                          <Button
                            variant={isDaySelectedDate( availableDate.day ) ? 'contained' : 'outlined'}
                            size='small'
                            onClick={onClickDate( availableDate.day )}
                            fullWidth={isXSmall}
                            sx={{
                              // border: '1px solid red',
                              margin: { xs: '0.5rem 0', sm: '0.5rem' },

                              width: { xs: undefined, sm: '10rem', md: '9rem' },
                              '& .MuiTypography-root': {
                                margin: 'inherit',
                              },
                            }}
                            disableRipple
                          >
                            <Typography
                              component='span'
                              sx={{
                                margin: '0.5rem 1rem 0 0',
                              }}>
                              {availableDate.label}
                            </Typography>
                          </Button>

                        </Grid>
                      ) )
                      : (
                        <Typography pt={2}>No available slots</Typography>
                      )
                  }
                </Grid>
              </Slide>
            </>
          }

          {date &&
            <>
              <Box
                ref={sectionTimes}
                sx={{
                  // border: '1px solid red',
                  margin: '2rem auto 0',
                  '& .MuiTypography-body1': {
                    // color: 'red',
                    margin: '1.25rem 1rem 1rem 0',
                    fontWeight: 'bold',
                    // width: '16rem',
                    display: 'block',
                  },

                }}
              >
                <Typography
                  align='center'
                >
                  Available Times for  {prettyDate( date, timeZoneName )}
                </Typography>

              </Box>


              <Slide in={!!date} direction='up' >
                <Grid container
                  direction='row'
                  // justifyContent='space-between'
                  alignItems='flex-start'


                  sx={{
                    // height: '22rem',
                    // border: '1px solid purple'
                    '& .MuiButton-outlined': {
                      backgroundColor: alpha( theme.palette.primary.main, 0.08 ),
                      color: theme.palette.primary.main,
                      '&:hover,&:active': {
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                      }
                    }
                  }}
                >
                  {filteredSlots.length
                    ? ( moreTimes ? filteredSlots : sampledSlots ).map( slot => (
                      <Grid
                        item
                        key={slot.id}
                        xs={12} sm={'auto'}
                      >
                        <Button
                          key={slot.id}
                          variant='outlined'
                          size='small'
                          onClick={() => setOpenRescheduleDialog( slot )}
                          fullWidth={isXSmall}
                          sx={{
                            margin: { xs: '0.5rem 0', sm: '0.5rem' },
                            width: { xs: undefined, sm: '10rem', md: '9rem' },
                            '& .MuiTypography-root': {
                              margin: 'inherit',
                            },
                          }}
                          disableRipple
                        >
                          <Typography
                            component='span'
                            sx={{
                              margin: '0.5rem 1rem 0 0',
                            }}>
                            {prettyTime( slot.start, timeZoneName )}
                          </Typography>
                        </Button>
                      </Grid>
                    ) )
                    : (
                      <Typography pt={2}>No available slots</Typography>
                    )
                  }
                  {filteredSlots.length > sampledSlots.length &&
                    <Grid item key='more'>
                      <Button
                        variant='text'
                        size='medium'
                        onClick={() => setMoreTimes( !moreTimes )}
                        sx={{
                          margin: '0.5em',
                          width: { xs: '12rem', sm: '10rem', md: '9rem' },
                          // '& .MuiTypography-root': {
                          //   margin: 'inherit',
                          // },
                        }}
                        disableRipple

                      >
                        <Typography component='span' sx={{ margin: '0.5rem 1rem 0 0' }}>
                          {moreTimes ? 'less' : 'more'}
                        </Typography>
                      </Button>
                    </Grid>
                  }
                </Grid>

              </Slide>
              <ConfirmDialog
                open={!!openRescheduleDialog}
                title={'Book appointment'}
                message={`Do you want to book the appointment for ${ prettyTime( openRescheduleDialog?.start, timeZoneName ) } on ${ prettyDate( openRescheduleDialog?.start, timeZoneName ) } ?`}
                confirmButton='Yes'
                cancelButton='No'
                onClose={async ( isRescheduleConfirmed ) => {
                  const slot = openRescheduleDialog;
                  setOpenRescheduleDialog( undefined );
                  if( slot && isRescheduleConfirmed ) {
                    await onReschedule( slot );
                  }
                }}
              />
            </>
          }


        </Stack>
      </div>

    </>
  );
}




