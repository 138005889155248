/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import { ArrowBackIosNew } from '@mui/icons-material';
import { Divider } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import get from 'lodash/get';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { getCommonTagId } from './components/AmenityChoices';
import { renderPhoneNumber } from './components/AwesomePhoneField';
import { PageFooter } from './components/page-footer';
import { getProfile, unsubscribeAllContactMethods } from './profiles';
import styles from './styles/Home.module.css';
import { AssetInterface, OrganizationInterface, OrganizationStyling, RecipientInterface } from './types';

type ProfileParams = { profileId: string };

export const Unsubscribe: FC = () => {
  const { profileId = '11111111-1111-1111-1111-111111111111' } = useParams<ProfileParams>();
  const navigate = useNavigate();
  const theme = useTheme();
  // const redirect =
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );
  const isSmall = useMediaQuery( theme.breakpoints.down( 'md' ) );
  const isMedium = useMediaQuery( theme.breakpoints.down( 'lg' ) );

  const [ isLoaded, setIsLoaded ] = useState( false );
  const [ recipient, setRecipient ] = useState<RecipientInterface>();
  const [ organization, setOrganization ] = useState<OrganizationInterface>();
  const [ assets, setAssets ] = useState<AssetInterface[]>( [] );
  const [ countryCode, setCountryCode ] = useState( 'US' );
  const [ notify, setNotify ] = useState( '' );

  useEffect( () => {
    ( async () => {
      if( !profileId || isLoaded ) return;
      const { recipient, recipients, organization, assets, countryCode } = ( await getProfile( profileId ) ) || {};
      if( !recipient ) return;
      setIsLoaded( true );
      setRecipient( recipient );
      setOrganization( organization )
      setAssets( assets || [] );
      setCountryCode( countryCode || 'US' );
    } )()
  }, [ profileId, isLoaded ] );

  const { logoAsset, logoTitle, colorPrimary, fontBody, fontHeader } = useMemo( () => {
    if( !organization ) return {};
    // const { amenityTags } = organization;
    const { iconStyle = 'default', amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader } = ( organization || {} ) as unknown as OrganizationStyling;
    const iconProps = {
      // htmlColor: [ 'default', 'primary', 'secondary' ].includes( iconStyle ) ? undefined : iconStyle,
      '& svg': {
        color: [ 'primary', 'secondary' ].includes( iconStyle )
          ? ( iconStyle == 'primary' ? colorPrimary : colorSecondary )
          : 'inherit',
        fontSize: '2.25rem', // 'large',
      }
    };
    const logoAsset = ( assets ).find( asset => ( asset.tags || [] ).includes( getCommonTagId( 'square' ) ) );
    const logoTitle = [ organization.name, logoAsset?.subject || 'logo' ].join( ' ' );
    return { logoAsset, logoTitle, iconProps, amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader };
  }, [ assets, organization ] );


  const isUnsubscribed = useMemo<boolean>( () => {
    if( !recipient || !isLoaded ) return true;
    // all of these must be true
    return [
      'disableAppointmentRealTimeUpdates',
      'disableLabUpdates',
      'disableOfficeCommunication',
      'disablePractitionerCommunication',
      'disablePrePostOpCareInstructions',
      'disableBirthdays',
      'disableMarketingUpdates',
      'disableReviewReminders',
    ].reduce( ( result, field ) => result && get( recipient, field, false ), true );
  }, [ recipient, isLoaded ] );

  const onUnsubscribeClick = useCallback( async () => {
    await unsubscribeAllContactMethods( profileId );
    setNotify( 'You are unsubscribed from all contact methods' );
    setIsLoaded( false );
  }, [ profileId ] );

  const onProfileClick = useCallback( () => {
    console.log( 'profile' );
    navigate( './..' );

  }, [ profileId ] );

  const TopSection: FC = () => {
    return (
      <>
        <Grid item container direction="column"
          sx={{
            // padding: { xs: '0 -2em', md: 'inherit' },
            // width: { xs: '120%', md: '100%' },
          }}
        >
          <Link
            href='/'
            sx={{
              margin: { xs: '0 -2.5em', md: 'inherit' },
              width: { xs: '120%', md: '100%' },
              textDecorationLine: 'none',
            }}
          >
            <Typography variant='h1'
              align={isXSmall ? 'center' : 'left'}
              sx={{
                fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
                backgroundColor: colorPrimary,
                color: 'white',
                width: '100%',
                padding: { xs: '0.5rem 3.0rem', md: '0.5rem 1.0rem' },
                fontWeight: 'bold', //'600',
                fontSize: 'large',
                fontSmooth: 'always',
              }}
            >
              AIC Analog Concierge &trade;
              { /* transparent button used to match height of Location header bar */}
              <IconButton sx={{ color: 'transparent' }}>
                <ArrowBackIosNew />
              </IconButton>
            </Typography>
          </Link>
        </Grid>

        <Link
          key='name'
          href='/' sx={{ width: '100%', textDecorationLine: 'none' }}>
          <Typography variant='h1'
            align={ /* isXSmall ? 'center' : */ 'left'}
            style={{
              fontFamily: !isXSmall ? fontHeader : undefined,
              // backgroundColor: colorPrimary,
              color: colorPrimary, // 'white',
              width: '100%',
              padding: '0.5rem 0',
              fontWeight: 'bold', //'600',
              fontSize: 'xx-large',
              fontSmooth: 'always',
            }}
            className='translated'
          >
            {organization?.name}
          </Typography>
        </Link>
      </>
    );
  }

  const BottomSection: FC = () => {

    const contactLink = useCallback( ( name: string, protocol: string, value?: string, formatted?: string ) => {
      if( !value ) return null;
      return <>{name} <Link underline='hover' href={`${ protocol }:${ value }`} className='translated'>{formatted}</Link></>;
    }, [] );

    const contactLinks = useMemo( () => {
      const { telephone: phone, email } = organization || {};
      if( !phone && !email ) return ( <>.</> );
      return (
        <>
          {' by '}
          {contactLink( 'phone', 'tel', phone, renderPhoneNumber( phone ) )}
          {phone && email ? ' or ' : ''}
          {contactLink( 'email', 'mailto', email, email )}
          <span className='translated'>.</span>
        </>
      );
    }, [ contactLink ] );

    return (
      <>
        <p>
          If you have any questions or concerns, please
          don&apos;t hesitate to get in touch with
          us {contactLinks}
        </p>
      </>
    );
  }

  const SimpleSnackbar: FC<{ open: boolean, onClose: () => void, message: string }> = ( props ) => {
    const { open, onClose, message } = props;

    return (
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          severity='success'
          variant='filled'
        >
          {message}
        </Alert>
      </Snackbar>
    );
  }


  // Fix 'Error: Hydration failed because the initial UI does not match what was rendered on the server'
  const [ mounted, setMounted ] = useState( false );
  useEffect( () => setMounted( true ), [] );
  if( !mounted ) return null;

  if( !organization || !recipient ) return null;

  return (
    <Box
      id='unsubscribe'
      sx={{
        padding: { xs: 0, md: '0 1.5rem' },
        maxWidth: '960px',
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: { xs: '1rem', md: '1.2rem' },
        backgroundColor: '#f9fafb',
        fontFamily: fontBody,

      }}>
      <Helmet>
        <title>Unsubscribe - Profile - {recipient.firstName}</title>
        <meta name='description' content={`Profile on ${ recipient.firstName }`} />
        <link rel='icon' href='/favicon.ico' />
      </Helmet>

      <main className={styles.main}
        style={{

        }}
      >
        <SimpleSnackbar
          open={!!notify}
          onClose={() => { setNotify( '' ) }}
          message={notify}
        />
        <TopSection />
        <Divider />
        <Grid container key='content' style={{ padding: isXSmall ? '1.5rem' : undefined }}
          direction='column'
          mt={2}
          spacing={2}
        >
          <Grid item >
            <Typography variant='h5'>
              Unsubscribe from all communication
            </Typography>
          </Grid>

          <Grid item>
            <Typography>
              We are sorry to see you go!
            </Typography>
          </Grid>

          <Grid item mt={2} mb={2} >
            <Button
              size='large'
              variant='contained'
              onClick={onUnsubscribeClick}
              disabled={isUnsubscribed}
            >
              Unsubscribe All
            </Button>
            {isUnsubscribed &&
              <Typography>
                You are unsubscribed from all commuication types.
              </Typography>
            }
          </Grid>

          <Grid item mt={2} mb={2} >
            <Button
              size='large'
              variant='outlined'
              onClick={onProfileClick}
            >
              View Full Profile
            </Button>
          </Grid>


          <BottomSection />
        </Grid>

        <PageFooter />

      </main>

    </Box>
  );
}
