import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Camera, CameraProps, CameraType } from 'react-camera-pro';
import { Box, BoxProps } from '@mui/material';

const controlSx: BoxProps[ 'sx' ] = {
  position: 'fixed',
  display: 'flex',
  right: 0,
  width: '20%',
  minWidth: '130px',
  minHeight: '130px',
  height: '100 %',
  background: 'rgba( 0, 0, 0, 0.8 )',
  zIndex: 10,
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '50px',
  boxSizing: 'border-box',
  flexDirection: 'column-reverse',

  '@media( max-aspect-ratio: 1/1 )': {
    flexDirection: 'row',
    bottom: 0,
    width: '100%',
    height: '20%',
  },

  '@media( max-width: 400px )': {
    padding: '10px',
  },
}

const Button = styled.button`
  outline: none;
  color: white;
  opacity: 1;
  background: transparent;
  background-color: transparent;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  padding: 0;
  text-shadow: 0px 0px 4px black;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
  filter: invert(100%);
  border: none;

  &:hover {
    opacity: 0.7;
  }
`;

const TakePhotoButton = styled( Button )`
  background: url('https://img.icons8.com/ios/50/000000/compact-camera.png');
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const ChangeFacingCameraButton = styled( Button )`
  background: url(https://img.icons8.com/ios/50/000000/switch-camera.png);
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  padding: 40px;
  &:disabled {
    opacity: 0;
    cursor: default;
    padding: 60px;
  }
  @media (max-width: 400px) {
    padding: 40px 5px;
    &:disabled {
      padding: 40px 25px;
    }
  }
`;

const ImagePreview = styled.div<{ image: string | null }>`
  width: 120px;
  height: 120px;
  ${ ( { image } ) => ( image ? `background-image:  url(${ image });` : '' ) }
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 400px) {
    width: 50px;
    height: 120px;
  }
`;

const FullScreenImagePreview = styled.div<{ image: string | null }>`
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  background-color: black;
  ${ ( { image } ) => ( image ? `background-image:  url(${ image });` : '' ) }
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  `;

export const CameraExample: FC = () => {
  const [ numberOfCameras, setNumberOfCameras ] = useState( 0 );
  const [ image, setImage ] = useState<string | null>( null );
  const [ showImage, setShowImage ] = useState<boolean>( false );
  const camera = useRef<CameraType>( null );
  const [ devices, setDevices ] = useState<MediaDeviceInfo[]>( [] );
  const [ activeDeviceId, setActiveDeviceId ] = useState<string | undefined>( undefined );

  useEffect( () => {
    ( async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter( ( i ) => i.kind == 'videoinput' );
      setDevices( videoDevices );
    } )();
  } );

  return (
    <Box
      key='wrapper'
      sx={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        zIndex: 1,
      }}>
      {showImage
        ? (
          <FullScreenImagePreview
            image={image}
            onClick={() => {
              setShowImage( !showImage );
            }}
          />
        )
        : (
          <Camera
            ref={camera}
            aspectRatio="cover"
            numberOfCamerasCallback={( i ) => setNumberOfCameras( i )}
            videoSourceDeviceId={activeDeviceId}
            errorMessages={{
              noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
              permissionDenied: 'Permission denied. Please refresh and give camera permission.',
              switchCamera:
                'It is not possible to switch camera to different one because there is only one video device accessible.',
              canvas: 'Canvas is not supported.',
            }}
            videoReadyCallback={() => {
              console.log( 'Video feed ready.' );
            }}
          />
        )}
      <Box key='control' sx={controlSx}>
        <select
          onChange={( event ) => {
            setActiveDeviceId( event.target.value );
          }}
        >
          {devices.map( ( d ) => (
            <option key={d.deviceId} value={d.deviceId}>
              {d.label}
            </option>
          ) )}
        </select>
        <ImagePreview
          image={image}
          onClick={() => {
            setShowImage( !showImage );
          }}
        />
        <TakePhotoButton
          onClick={() => {
            if( camera.current ) {
              const photo = camera.current.takePhoto( 'base64url' ) as string;
              console.log( photo );
              setImage( photo );
            }
          }}
        />
        <ChangeFacingCameraButton
          disabled={numberOfCameras <= 1}
          onClick={() => {
            if( camera.current ) {
              const result = camera.current.switchCamera();
              console.log( result );
            }
          }}
        />
      </Box>
    </Box>
  );
};

