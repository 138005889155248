import { SxProps } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { Theme } from '@mui/system';
import { cloneElement, FC, ReactElement } from 'react';

export interface WideButtonWithIconProps {
  children: ReactElement | string;
  icon: ReactElement;
  href?: ButtonProps[ 'href' ];
  onClick?: ButtonProps[ 'onClick' ];
  color: string;
}

export const WideButtonWithIcon: FC<WideButtonWithIconProps> = props => {
  const { children, onClick, href, color = 'primary', icon } = props;

  const buttonSx: SxProps<Theme> = {
    fontSize: '100%',
    color,
    borderColor: color,
    '& .MuiButton-startIcon .MuiSvgIcon-root': {
      fontSize: '36px',
    },
    '& span:not( .MuiButton-startIcon, .MuiTouchRipple-root )': {
      width: '10rem',
      textAlign: 'center',
    }
  };

  return (
    <Button
      variant="outlined"
      size="large"
      href={href}
      onClick={onClick}
      startIcon={cloneElement( icon, { fontSize: 'inherit' } )}
      fullWidth
      sx={buttonSx}
    >
      <span>
        {children}
      </span>
    </Button>
  );
}


