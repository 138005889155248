import { Article, NoteAlt } from '@mui/icons-material';
import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate, useOutletContext, useRouteLoaderData } from 'react-router-dom';
import { getProperName, prettyShortDate, prettyShortDateTime, prettyTime } from './AppointmentContainer';
import { ProfileContext } from './ProfileContainer';
import { ProfileForm } from './profiles';
import { WideButtonWithIcon } from './WideButtonWithIcon';


export const ProfileFormsIndex: FC = () => {
  const orgContext = useOutletContext<ProfileContext>();
  const { colorPrimary, fontHeader, isSmall, recipient: patient } = orgContext;
  const forms = useRouteLoaderData( 'forms' ) as ProfileForm[];
  const navigate = useNavigate();

  const [ showAll, setShowAll ] = useState( false );

  const isCompact = isSmall;
  const compact = 'md';

  const { firstName, lastName: lastInitial } = patient;
  const patientName = `${ firstName } ${ lastInitial }`;

  const previewStatuses = [ 'Signed', 'Submitted' ];

  if( !forms ) return null;

  return (
    <Box id='forms-index'>
      <Grid container
        alignItems='baseline'
        justifyContent='space-between'
      >
        <Grid item>
          <h3
            style={{
              fontFamily: fontHeader,
            }}
          >
            Forms for <span className='translated'>{patientName}</span>
          </h3>
        </Grid>

      </Grid>
      <Paper
        sx={{
          padding: '1rem',
        }}
      >
        <Table size='small' padding='none'
          sx={{
            '& .MuiTableBody-root .MuiTableRow-root': {
              '&:not(.buttonRow):has(+ .MuiTableRow-root.buttonRow ) .MuiTableCell-body': {
                borderBottomWidth: 0,
              },
            },
            '& .MuiTableCell-body,& .MuiTableCell-head': {
              fontSize: '1rem',
              padding: '0.5rem 1rem',
              verticalAlign: 'top',
            },
            '& .MuiTableCell-head': {
              textTransform: 'uppercase',
              letterSpacing: '1.1',
              fontFamily: fontHeader,
            },
            '& .MuiButton-root': {
              width: { xs: '100%', [ compact ]: '10rem' },
              fontSize: { xs: '1rem', [ compact ]: '0.9rem' },
              margin: { xs: '0 0 2rem', [ compact ]: '0 3rem 1rem 0' },
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell key='1' >Appointment</TableCell>
              <TableCell key='2' >Form</TableCell>
              <TableCell key='3' >Status</TableCell>
              <TableCell key='4' >Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {forms.length == 0
              ? <TableRow >
                <TableCell colSpan={10} sx={{ height: '8rem' }}>
                  No forms found
                </TableCell>
              </TableRow>
              : forms
                .map( o => {
                  const { id, status, appointment, location } = o;
                  const { appointmentTypeDisplay, startTime } = appointment || {};
                  const { name, nickname } = location;
                  const locationName = nickname || name;
                  const timeZoneName = location.timeZoneName || appointment.timeZoneName;
                  const rowClickTo = `${ id }${ previewStatuses.includes( status ) ? '' : '/update' }`;
                  const buttonIcon = previewStatuses.includes( status ) ? <Article /> : <NoteAlt />;

                  return (
                    <TableRow
                      key={id}
                      onClick={() => navigate( rowClickTo )}
                    >
                      {isCompact || !isCompact
                        ? (
                          <TableCell key='1' className='translated'>
                            {prettyShortDateTime( startTime, timeZoneName )}
                            <br />
                            {appointmentTypeDisplay}
                            <br />
                            {getProperName( o.practitioner )}
                            <br />
                            {locationName}
                          </TableCell>
                        )
                        : (
                          <TableCell key='1' className='translated'>
                            {prettyTime( startTime, timeZoneName )}
                            <br />
                            {prettyShortDate( startTime, timeZoneName )}
                          </TableCell>
                        )}

                      <TableCell key='2' >
                        {o.formName}
                        <br />
                        <br />
                        <span className='translated'>{o.form}</span>
                      </TableCell>

                      <TableCell key='3' >{status}</TableCell>
                      <TableCell key='4' >
                        <WideButtonWithIcon
                          icon={buttonIcon}
                          color={colorPrimary}
                        >
                          {previewStatuses.includes( status ) ? 'View' : 'Update'}
                        </WideButtonWithIcon>
                      </TableCell>

                    </TableRow>
                  )
                } )}
          </TableBody>
        </Table>
        {forms.length
          ? <Button
            onClick={() => setShowAll( !showAll )}
          >
            {showAll ? 'Less' : 'More'}
          </Button>
          : null
        }
      </Paper>
    </Box >
  );
}
