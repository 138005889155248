/*eslint quotes: 'off' */
/*eslint array-bracket-newline: 'off'*/
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import get from 'lodash/get';
import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { buildImageUrl } from './components/Image';
import { AssetInterface, LocationInterface, OrganizationInterface } from './types';

export interface SeoTagsProps {
  organization: OrganizationInterface;
  location?: LocationInterface;
  logoAsset?: AssetInterface;
  descriptionAsset?: AssetInterface;
  title?: string;
}

export const SeoTags: FC<SeoTagsProps> = ( props ) => {
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );
  const { organization, location, logoAsset, descriptionAsset, title = organization.name } = props;
  const { href } = window.location; // TODO slug
  const { city, country, state } = location?.addressComponents?.long || {};
  const { country: countryCode } = location?.addressComponents?.short || {};
  const { lat, lng, twitter, facebook } = location || {};
  const position = lat && lng ? `${ lat };${ lng }` : undefined;
  const placename = city ?? state;
  const region = country ? country + ( countryCode ? ` (${ countryCode })` : '' ) : '';
  const imageId = get( descriptionAsset, 'images.0', get( logoAsset, 'images.0' ) );
  const imageUrl = imageId && buildImageUrl( imageId, { w: 1200, h: 630 } ); // TODO consider using both

  // https://ogp.me
  // https://validator.schema.org
  // https://search.google.com/test/rich-results

  return (
    <Helmet prioritizeSeoTags>
      <title>{title}</title>
      <meta name="description" content={`The Analog Concierge &trade; App by Analog Informatics Corporation helps ${ organization.name } patients find and receive critical information about their appointments, facility, staff and amenities/`} />
      <link rel="icon" href="/favicon.ico" />
      <link rel="preconnect" href='https://fonts.googleapis.com' />
      <link rel="preconnect" href='https://fonts.gstatic.com' />
      {!isXSmall &&
        <style>
          @import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&&family=Open+Sans&display=swap);
        </style>
      }
      {descriptionAsset?.body &&
        <meta name="description" content={descriptionAsset.body} />
      }
      <link rel="canonical" href={href} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={location?.name ?? organization.name} />
      {descriptionAsset?.body &&
        <meta property="og:description" content={descriptionAsset.body} />
      }
      <meta property="og:url" content={href} />
      <meta property="og:site_name" content={organization.name} />
      {facebook &&
        <meta property="article:publisher" content={`https://www.facebook.com/${ facebook }/`} />
      }
      <meta property="article:modified_time" content="2022-09-26T16:59:45+00:00" />
      {imageUrl &&
        <>
          <meta property="og:image" content={imageUrl} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:type" content="image/webp" />
          <meta name="twitter:card" content="summary_large_image" />
        </>
      }
      {twitter &&
        <>
          <meta name="twitter:site" content={twitter} />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="1 minute" />
        </>
      }
      {placename &&
        <meta name="geo.placename" content={placename} />
      }
      {position &&
        <meta name="geo.position" content={position} />
      }
      {region &&
        <meta name="geo.region" content={region} />
      }
      <script type="application/ld+json" className="yoast-schema-graph">{JSON.stringify( getSchema( props ) )}</script>
    </Helmet>
  );
};

export const getSchema = ( props: SeoTagsProps ) => {
  const { organization, location, logoAsset, descriptionAsset } = props;
  const { href: url } = window.location; // TODO slug
  const { city, country, state } = location?.addressComponents?.long || {};
  const { country: countryCode } = location?.addressComponents?.short || {};
  const { lat, lng } = location || {};
  const position = lat && lng ? `${ lat };${ lng }` : undefined;
  const placename = city ?? state;
  const region = country ? country + ( countryCode ? ` (${ countryCode })` : '' ) : '';

  const name = location?.name ?? organization.name; // "Summit MD Dermatologist in Lancaster, CA | Skin Care Provider",
  const contentSize = { w: 1200, h: 630 };
  const thumbnailSize = { w: 600, h: 315 };
  const contentUrl = logoAsset?.images && logoAsset.images[ 0 ] ? buildImageUrl( logoAsset.images[ 0 ], contentSize ) : undefined;
  const thumbnailUrl = logoAsset?.images && logoAsset.images[ 0 ] ? buildImageUrl( logoAsset.images[ 0 ], thumbnailSize ) : undefined;
  const datePublished = location?.createdAt ?? organization.createdAt;
  const dateModified = location?.updatedAt ?? organization.updatedAt;
  const description = descriptionAsset?.body;
  const inLanguage = "en-US";

  return {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "@id": url, // "https://summitmddermatology.com/locations/summitmd-dermatology-lancaster/",
        url, // "https://summitmddermatology.com/locations/summitmd-dermatology-lancaster/",
        name, // "Summit MD Dermatologist in Lancaster, CA | Skin Care Provider",
        isPartOf: {
          "@id": "https://summitmddermatology.com/#website"
        },
        primaryImageOfPage: {
          "@id": "https://summitmddermatology.com/locations/summitmd-dermatology-lancaster/#primaryimage"
        },
        image: {
          "@id": "https://summitmddermatology.com/locations/summitmd-dermatology-lancaster/#primaryimage"
        },
        thumbnailUrl, // "https://summitmddermatology.com/wp-content/uploads/2020/02/AV.jpg",
        datePublished,
        dateModified,
        description, // "Dermatology treatments for all conditions from moles to skin cancer in Lancaster. Effective preventions and treatments for your skin conditions. Visit us!",
        breadcrumb: {
          "@id": `${ url }/#breadcrumb`, // "https://summitmddermatology.com/locations/summitmd-dermatology-lancaster/#breadcrumb"
        },
        inLanguage,
        potentialAction: [
          {
            "@type": "ReadAction",
            "target": [ url ] //"https://summitmddermatology.com/locations/summitmd-dermatology-lancaster/"
          }
        ]
      },

      // TODO This can be multiple images, map would be useful
      {

        "@type": "ImageObject",
        inLanguage,
        "@id": "https://summitmddermatology.com/locations/summitmd-dermatology-lancaster/#primaryimage",
        url: contentUrl, //  "https://summitmddermatology.com/wp-content/uploads/2020/02/AV.jpg",
        contentUrl, // "https://summitmddermatology.com/wp-content/uploads/2020/02/AV.jpg",
        ...contentSize, // "width": 1382, "height": 940,
        "caption": "SummitMD Dermatology - Map of AV Dermatology and Surgery Center"
      },


      {
        "@type": "BreadcrumbList",
        "@id": "https://summitmddermatology.com/locations/summitmd-dermatology-lancaster/#breadcrumb",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://summitmddermatology.com/"
          },
          {
            "@type": "ListItem",
            "position": 2,
            "name": "Locations",
            "item": "https://summitmddermatology.com/locations/"
          },
          {
            "@type": "ListItem",
            "position": 3,
            "name": "California",
            "item": "https://summitmddermatology.com/state/california/"
          },
          {
            "@type": "ListItem",
            "position": 4,
            "name": "SummitMD Dermatology Lancaster"
          }
        ]
      },


      {
        "@type": "WebSite",
        "@id": "https://summitmddermatology.com/#website",
        "url": "https://summitmddermatology.com/",
        "name": "SummitMD Dermatology",
        "description": "Medical, Surgical, &amp; Cosmetic",
        "publisher": {
          "@id": "https://summitmddermatology.com/#organization"
        },
        "potentialAction": [
          {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://summitmddermatology.com/?s={search_term_string}"
            },
            "query-input": "required name=search_term_string"
          }
        ],
        "inLanguage": "en-US"
      },


      {
        "@type": "Organization",
        "@id": "https://summitmddermatology.com/#organization",
        "name": "SummitMD Dermatology",
        "url": "https://summitmddermatology.com/",
        "sameAs": [
          "https://www.instagram.com/summitmddermatology/",
          "https://www.facebook.com/summitmddermatology/",
          "https://twitter.com/summitmdderma"
        ],
        "logo": {
          "@type": "ImageObject",
          "inLanguage": "en-US",
          "@id": "https://summitmddermatology.com/#/schema/logo/image/",
          "url": "https://summitmddermatology.com/wp-content/uploads/2019/12/summit-md-logo-e1638301104564.png",
          "contentUrl": "https://summitmddermatology.com/wp-content/uploads/2019/12/summit-md-logo-e1638301104564.png",
          "width": 301,
          "height": 101,
          "caption": "SummitMD Dermatology"
        },
        "image": {
          "@id": "https://summitmddermatology.com/#/schema/logo/image/"
        }
      },


      {
        "@type": "PostalAddress",
        "@id": "https://summitmddermatology.com/locations/summitmd-dermatology-lancaster/#local-branch-place-address",
        "streetAddress": "44215 15th St W STE 309",
        "addressLocality": "Lancaster",
        "postalCode": "93534",
        "addressRegion": "California",
        "addressCountry": "US"
      },


      {
        "@type": [
          "Organization",
          "Place",
          "Dermatology"
        ],
        "address": {
          "@id": "https://summitmddermatology.com/locations/summitmd-dermatology-lancaster/#local-branch-place-address"
        },
        "@id": "https://summitmddermatology.com/locations/summitmd-dermatology-lancaster/#local-branch-organization",
        "mainEntityOfPage": {
          "@id": "https://summitmddermatology.com/locations/summitmd-dermatology-lancaster/"
        },
        "logo": {
          "@id": "https://summitmddermatology.com/locations/summitmd-dermatology-lancaster/#local-branch-organization-logo"
        },
        "image": {
          "@id": "https://summitmddermatology.com/locations/summitmd-dermatology-lancaster/#local-branch-organization-logo"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "34.686642",
          "longitude": "-118.1585483"
        },
        "telephone": [
          "(661) 949-0004"
        ],
        "openingHoursSpecification": [
          {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday"
            ],
            "opens": "08:00",
            "closes": "17:00"
          },
          {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Saturday",
              "Sunday"
            ],
            "opens": "00:00",
            "closes": "00:00"
          }
        ],
        "email": "summitmdderm@gmail.com",
        "areaServed": "California",
        "url": "https://summitmddermatology.com/locations/av-dermatology-and-surgery-center/",
        "priceRange": "$$",
        "currenciesAccepted": "USD",
        "name": "SummitMD Dermatology Lancaster",
        "parentOrganization": {
          "@id": "https://summitmddermatology.com/#organization"
        }
      },

      {
        "@type": "ImageObject",
        "inLanguage": "en-US",
        "@id": "https://summitmddermatology.com/locations/summitmd-dermatology-lancaster/#local-branch-organization-logo",
        "url": "https://summitmddermatology.com/wp-content/uploads/2019/12/summit-md-logo-e1638301104564.png",
        "contentUrl": "https://summitmddermatology.com/wp-content/uploads/2019/12/summit-md-logo-e1638301104564.png",
        "width": 301,
        "height": 101,
        "caption": "SummitMD Dermatology Lancaster"
      }

    ]
  };
}
