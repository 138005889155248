import { ArrowBackIosNew } from '@mui/icons-material';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Link as MuiLink, Radio, RadioGroup, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ScrollRestoration, useNavigate } from 'react-router-dom';
import { setLoginAttemptInfo } from 'supertokens-auth-react/recipe/passwordless';
import { PageFooter } from './components/page-footer';
import { OrganizationContext } from './ProfileContainer';
import { getProfileOptions, ProfileOptionsResponse, selectProfileOption } from './profiles';
import { OrganizationInterface } from './types';


export interface ProfileAuthProps {
  profileId: string;
  organization: OrganizationInterface & OrganizationContext;
}

export const ProfileAuth: FC<ProfileAuthProps> = ( { profileId, organization } ) => {
  const { setError, setNotify, colorPrimary, colorSecondary, fontBody, fontHeader, isXSmall, isSmall, isMedium } = organization;
  const [ title, setTitle ] = useState( '' );
  const [ header, setHeader ] = useState( '' );
  const navigate = useNavigate();

  useEffect( () => setTitle( organization.name ), [ organization.name ] );
  useEffect( () => setHeader( 'New Appointment - Patient Lookup' ), [] );

  const [ options, setOptions ] = useState<ProfileOptionsResponse | undefined>();
  const hasOptions = useMemo( () => Object.keys( options || {} ).length > 0, [ options ] );
  const [ option, setOption ] = useState<string | undefined>();
  const [ selected, setSelected ] = useState( false );

  useEffect( () => {
    ( async () => {
      if( options ) return;
      const opts = await getProfileOptions( profileId );
      setOptions( opts );
    } )();
  }, [ options, setOptions ] );

  return (
    <Box
      id='profile'
      sx={{
        padding: { xs: '0 1.5rem' },
        maxWidth: { xs: '100%', md: '960px' },

        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: { xs: '1rem', md: '1.2rem' },
        backgroundColor: '#f9fafb',
        fontFamily: fontBody,
        '& .MuiListItemText-root .MuiTypography-root': {
          fontFamily: fontBody,
        },

      }}>

      <Helmet>
        <title>
          {header}
        </title>
        { /* <meta name='description' content={`Profile on ${ recipient.firstName }`} /> */}
        <link rel='icon' href='/favicon.ico' />
      </Helmet>


      { /* <SeoTags title={pageTitle} organization={organization} logoAsset={logoAsset} /> */}
      <ScrollRestoration />
      { /*
           <SimpleConfirmSnackbar
           open={!!notify}
           onClose={() => { setNotify( '' ) }}
           message={notify}
           />
           <SimpleErrorSnackbar
           open={!!error}
           onClose={() => { setError( '' ) }}
           message={error}
           />
         */}

      <Grid item container direction="column"
        sx={{
          margin: { xs: '0 -1.3em', md: 'inherit' },
          width: { xs: '120%', md: 'inherit' },
        }}
      >
        <Typography variant='h1'
          align={isXSmall ? 'center' : 'left'}
          style={{
            fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
            color: colorPrimary,
            // color: 'white',
            width: '100%',
            padding: '0rem 1.0rem',
            fontWeight: 'bold', //'600',
            fontSize: 'medium',
            fontSmooth: 'always',
          }}
        >
          AIC Analog Concierge &trade;
          { /* transparent button used to match height of Location header bar */}
          <IconButton sx={{ color: 'transparent' }}>
            <ArrowBackIosNew />
          </IconButton>
        </Typography>
        <MuiLink
          href='/locs'
          sx={{
            width: '100%',
            textDecorationLine: 'none',
          }}
        >
          <Typography variant='h1'
            align={isXSmall ? 'center' : 'left'}
            style={{
              fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
              backgroundColor: colorPrimary,
              color: 'white',
              width: '100%',
              padding: '0.5rem 1.0rem',
              fontWeight: 'bold', //'600',
              fontSize: 'x-large',
              fontSmooth: 'always',
            }}
          >
            {title}
            { /* transparent button used to match height of Location header bar */}
            <IconButton sx={{ color: 'transparent' }}> <ArrowBackIosNew /> </IconButton>
          </Typography>
        </MuiLink>
      </Grid>

      {/* <LocBreadcrumbs /> */}


      <Box>
        <Typography
          style={{
            fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
            width: '100%',
            padding: '0.5rem 0',
            fontSize: 'x-large',
            fontSmooth: 'always',
          }}
          gutterBottom
        >
          Patient Sign In
        </Typography>

        {options && hasOptions && !selected &&
          <>
            <Typography
              style={{
                fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
                width: '100%',
                padding: '0.5rem 0',
                fontSize: 'large',
                fontSmooth: 'always',
                marginBottom: '2rem',
              }}
              gutterBottom
            >
              To access your profile, we will send a sign-in code to an active email address or phone number (via sms/text) that you have on file with our office.
            </Typography>

            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Pick a phone or email to send your sign-in code
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={undefined}
                onChange={( _e, value: string | undefined ) => setOption( value )}
              >
                {Object.entries( options ).map( ( [ value, label ] ) => (
                  <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
                ) )}
                {/* <FormControlLabel value={''} control={<Radio />} label={'None of these'} /> */}
              </RadioGroup>
              <Button

                variant='contained'
                disabled={!option}
                disableRipple
                sx={{
                  marginTop: '1rem',
                  marginBottom: 2,
                }}
                onClick={async () => {
                  if( !option ) return;
                  setSelected( true );
                  const codeResponse = await selectProfileOption( profileId, option );
                  const { deviceId, preAuthSessionId } = codeResponse || {};
                  if( !deviceId || !preAuthSessionId ) return
                  await setLoginAttemptInfo( {
                    attemptInfo: {
                      deviceId,
                      preAuthSessionId,
                      flowType: 'USER_INPUT_CODE',
                      lastResend: Date.now(),
                      contactMethod: options[ option ].includes( '@' ) ? 'EMAIL' : 'PHONE',
                      contactInfo: options[ option ],
                    }
                  } );
                  navigate( `/auth?redirectToPath=${ encodeURIComponent( location.pathname ) }` );
                }}
              >
                Send
              </Button>
              {/* 
              <Button
              variant='outlined'
              // disabled={!option}
              onClick={() => setOptions( undefined )}
              disableRipple
              >
              Cancel
              </Button>
            */}
            </FormControl>
          </>
        }
        {selected && option && options && options[ option ] &&
          <Typography
            style={{
              fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
              width: '100%',
              padding: '0.5rem 0',
              fontSize: 'large',
              fontSmooth: 'always',
            }}
            gutterBottom
          >
            We've sent a sign-in code to "{options[ option ]}"
          </Typography>

        }

      </Box>
      <PageFooter {...{ organization }} />

    </Box >


  );
}


