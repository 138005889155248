import { Box, CircularProgress, Typography } from '@mui/material';
import { default as Smart } from 'fhirclient';
import { FC, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { SmartConfiguration } from './profiles';


export const SmartLaunch: FC = () => {
  const data = useLoaderData() as SmartConfiguration;
  const { fhirSystemUrl: iss, clientId } = data;

  useEffect( () => {
    if( !iss || !clientId ) return;
    Smart.oauth2.authorize( {

      // The iss should only specified here for tenants
      iss, // : 'https://robert.analoginfo.ngrok.io/apis/default/fhir',

      //
      clientId, // : 'lU97nuh34H_osFDvHc6rsP21uwbGYllzB-SAbzIXNd8', // 'smarthealthit1',
      redirectUri: '/app/patient',
      scope: 'launch launch/patient openid fhirUser',


      completeInTarget: true, // ???


    } );
  }, [ iss, clientId ] );

  return <CircularProgress />
  
  // return (
  //   <Box>
  //   <Typography>
  //   SmartLaunch
  //   </Typography>
  //   <CircularProgress />
  //   </Box>
  // );
}
