/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import { isObjectId } from './lib/helpers';
import { AssetInterface, OrganizationInterface, RecipientInterface } from './types';

const {
  REACT_APP_API_SERVER: apiServer = '', // 'http://localhost:3400',
} = process.env;

export interface ProfileData {
  profileId: string;
  recipient: RecipientInterface;
  recipients: RecipientInterface[];
  recipientType: string;
  organization: OrganizationInterface;
  assets: AssetInterface[];
  countryCode: string;
}

export interface AnomalyReportEntry {
  apptDate: string;
  apptFhirId: string;
  apptHumanDate: string;
  apptStatus: string;
  apptType: string;    // enum
  dob: string;
  locationName: string;
  newPatient: boolean;
  patientFullName: string;
  practitionerFullName: string;
  reason: string;
}

export interface AnomalyReport {
  _id: string;
  description: string;
  entry: AnomalyReportEntry[];
  reportId: string;
  sortByField: string;
}

export interface AnomalyReportDocument {
  id: string;
  comment: string;
  createdAt: string;
  name: string;
  fake?: boolean;
  reportType: string;
  reportIntervalType: 'Daily' | 'Weekly' | 'Monthly';
  reports: AnomalyReport[];
  appointmentStartTime?: Date;  // JSON Date for appointment scan start date
  humanAppointmentStartTime?: string; // human version of scan start date
  appointmentStopTime?: Date; // JSON Date for appointment scan stop date
  humanAppointmentStopTime?: string;  // human version of scan stop date
  totalAppointmentsAnalyzed: number;  // Number of appointments in the time range
  appointmentsWithErrors: number; // Number of appointments with specific issues
  errorPercentageString: string;  // Percentage of appointments with issues as a string
  jsonReportComment?: string;
  estimatedValueOfAppointment: number; // Economic value of an appointment
  estimatedDollarLossString: string;  // Calculated loss value for all mistakes
  sortByField: string;  // Field to sort data on
  sortByOrder: string;  // Sort acending or descending
}



const apiPath = `${ apiServer }/api/v1/data`;

export const getAnomalyReports = async ( token?: string | null ): Promise<Omit<AnomalyReportDocument, 'reports'>[]> => {
  const response = await fetch( `${ apiPath }/reports/anomaly${ token ? `?t=${ token }` : '' }` );
  return await response.json();
}

export const getAnomalyReport = async ( reportId: string | undefined, token?: string | null ): Promise<AnomalyReportDocument | undefined> => {
  if( !isObjectId( reportId ) ) return;
  // if( !isGuid( reportId ) ) return;

  const response = await fetch( `${ apiPath }/reports/anomaly/${ reportId }${ token ? `?t=${ token }` : '' }` );
  return await response.json();
}

