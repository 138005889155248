// Converted from https://github.com/sbayd/jotform-react/blob/main/lib/JotFormReact.js
import { constant } from 'lodash';
import PropTypes from 'prop-types';
import { CSSProperties, FC, useEffect, useRef, useState } from 'react';

export interface JotFormEmbedProps {
  formURL: string;
  formID?: string; // number | boolean;
  autoResize?: boolean;
  autoFocus?: boolean;
  heightOffset?: number;
  initialHeight?: number;
  onSubmit?: () => void;
  style?: CSSProperties;
}

const JotFormEmbed: FC<JotFormEmbedProps> = ( {
  formURL,
  autoResize,
  autoFocus,
  heightOffset = 15,
  initialHeight = 540,
  onSubmit,
  formID,
  style = {},
  ...rest
} ) => {
  const iframeRef = useRef<HTMLIFrameElement | null>( null );
  const [ componentStyles, setComponentStyles ] = useState<CSSProperties>( {
    height: initialHeight,
    overflow: 'hidden',
    border: 0,
    width: '100%'
  } );

  useEffect( () => {
    const handleMessages = ( e: MessageEvent ) => {
      if( !e ) { return; }
      // Check if submission is completed.
      if( typeof e.data === 'object' && e.data.action === 'submission-completed' && onSubmit ) {
        onSubmit();
        return;
      }

      const isPermitted = ( originUrl: string, whitelisted_domains: string[] ) => {
        const url = document.createElement( 'a' );
        url.href = originUrl;
        const hostname = url.hostname;
        let result = false;
        if( typeof hostname !== 'undefined' ) {
          whitelisted_domains.forEach( function( element ) {
            if( hostname.slice( ( -1 * element.length - 1 ) ) === '.'.concat( element ) || hostname === element ) {
              result = true;
            }
          } );
          return result;
        }
      };


      // From now on we only handle style related messages
      if( typeof e.data !== 'string' ) return;
      const args = e.data.split( ':' );
      const [ method, value, targetForm ] = args;

      // eslint-disable-next-line eqeqeq
      if( formID && targetForm && targetForm != formID ) { // If you want to use multiple form via embed you need to use formID
        return;
      }
      const valueInt = parseInt( value );

      console.log( 'method', method, 'value', value, 'valueInt', valueInt, 'targetForm', targetForm );

      switch( true ) {
        case method === 'scrollIntoView': // && autoFocus:
          if( iframeRef.current && typeof iframeRef.current.scrollIntoView === 'function' ) {
            iframeRef.current.scrollIntoView();
          }
          break;
        case method === 'setHeight' && !isNaN( valueInt ): // && autoResize:
          setComponentStyles( { ...componentStyles, height: valueInt + heightOffset } );
          break;
        case method === 'setMinHeight' && !isNaN( valueInt ): // && autoResize:
          setComponentStyles( { ...componentStyles, minHeight: valueInt + heightOffset } );
          break;
        case method === 'reloadPage':
          try {
            iframeRef.current?.contentWindow && iframeRef.current.contentWindow.location.reload();
          } catch( e ) {
            console.log( 'failed to reload', e );
          }
          break;
        case method === 'loadScript':
          console.log( 'method', method, 'value', value, 'targetForm', targetForm );

          if( !isPermitted( e.origin, [ 'jotform.com', 'jotform.pro' ] ) ) {
            break;
          }
          {
            let src = args[ 1 ];
            if( args.length > 3 ) {
              src = args[ 1 ] + ':' + args[ 2 ];
            }
            const script = document.createElement( 'script' );
            script.src = src;
            script.type = 'text/javascript';
            document.body.appendChild( script );
          }
          break;
        case method === 'exitFullscreen':
          if( window.document.exitFullscreen ) window.document.exitFullscreen();
          break;

        case method === 'setDeviceType':
          console.log( 'method', method, 'value', value, 'targetForm', targetForm );
          break;
        default:
          console.log( 'skipping method', method, 'value', value, 'targetForm', targetForm );
          break;
      }
    };

    window.addEventListener( 'message', handleMessages, true ); // Capture
    return () => {
      if( window.removeEventListener ) {
        window.removeEventListener( 'message', handleMessages, true );
      }
    };
  }, [ autoFocus, autoResize, componentStyles, formID, heightOffset, onSubmit ] );

  return (
    <iframe
      ref={iframeRef}
      src={formURL}
      title="JotForm Form"
      style={{
        ...componentStyles,
        ...style
      }}
      allowTransparency
      allowFullScreen
      allow="geolocation 'none'; microphone 'none'; camera 'none'"
      frameBorder={0}
      // scrolling="no"
      onLoad={() => window.parent.scrollTo( 0, 0 )}

      {...rest}
    />
  );
};

JotFormEmbed.propTypes = {
  formURL: PropTypes.string.isRequired,
  formID: PropTypes.string,
  autoResize: PropTypes.bool,
  autoFocus: PropTypes.bool,
  heightOffset: PropTypes.number,
  initialHeight: PropTypes.number,
  onSubmit: PropTypes.func,
  style: PropTypes.shape( {} )
};

JotFormEmbed.defaultProps = {
  autoResize: true,
  autoFocus: true,
  heightOffset: 15,
  initialHeight: 540,
  style: {}
};


export default JotFormEmbed;
