/*eslint @typescript-eslint/no-unused-vars: "off"*/
/*eslint array-bracket-newline: "off"*/
import '@fontsource/montserrat';
import '@fontsource/open-sans';
import '@fontsource/roboto';
import '@fontsource/lato';
import '@fontsource/merriweather';
import '@fontsource/noto-sans';
import '@fontsource/noto-serif';
import '@fontsource/nunito-sans';
import '@fontsource/oswald';
import '@fontsource/raleway';
import '@fontsource/source-sans-pro';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { IconButton, List, ListItem, ListItemText, Link as MuiLink, Breadcrumbs, alpha, ListItemIcon } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getDistance } from 'geolib';
import { default as _, entries, groupBy, sortBy, get } from 'lodash';
import { Dispatch, ReactElement } from 'react';
import { FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Outlet, ScrollRestoration, useLoaderData, useLocation, useMatches, useNavigate, useOutletContext } from 'react-router-dom';
import { getCommonTagId } from './components/AmenityChoices';
import { PageFooter } from './components/page-footer';
import { getLocations, LocationsData } from './profiles';
import { SeoTags } from './SeoTags';
import styles from './styles/Home.module.css';
import { AssetInterface, LocationInterface, OrganizationInterface, OrganizationStyling } from './types';
import { Image, titleAlt } from './components/Image';
import { LocBreadcrumbs } from './Locs';


export const Orgs: FC = () => {
  // const { locations, organization, assets, commonTags, countryCode } = useLoaderData() as LocationsData;
  const organization = {
    '_id': '61a67e71ebc745fb3cfe52c2',
    'name': 'SummitMD Dermatology',
    'children': [],
    'parent': null,
    'externalIds': [],
    'createdAt': '2021-11-30T19:41:37.151Z',
    'updatedAt': '2023-07-12T14:08:59.019Z',
    'root': '61a67e71ebc745fb3cfe52c2',
    'amenityTags': [
      '6102f0cc3b3fd28628ca935e',
      '613914872ab88d000b62d19e',
      '613915d127ea940aa8edb6d0',
      '613915eb27ea940aa8edb6ec',
      '6139173127ea940aa8edb781',
      '6139174027ea940aa8edb790',
      '613a0120550946000bce1b44',
      '613a7e50d2de29000bb1638c',
      '613a2944c573f8000afe1e2c',
      '613a28e2c573f8000afe1e08'
    ],
    'amenityLayout': 'card',
    'colorPrimary': '#1f3e60',
    'colorSecondary': '#22b4cc',
    'fontBody': 'roboto',
    'fontHeader': 'montserrat',
    'iconStyle': 'primary',
    'webUrl': 'https://summitmddermatology.com',
    'telephone': '6619490004',
    'email': 'summitmdderm@gmail.com',
    'id': '61a67e71ebc745fb3cfe52c2'
  };
  const assets = [
    {
      '_id': '61ae599bb75af64fe70c63ff',
      'subject': 'Restrooms',
      'body': 'Restrooms',
      'images': [
        '61ae599ab75af64fe70c63fd'
      ],
      'tags': [
        '61aa9c9106d49c894f38fa93',
        '61a67e71ebc745fb3cfe52c2'
      ],
      'createdAt': '2021-12-06T18:42:35.184Z',
      'updatedAt': '2021-12-06T18:42:35.184Z',
      'id': '61ae599bb75af64fe70c63ff'
    },
    {
      '_id': '61b24811884ef8be77017c73',
      'subject': 'test',
      'body': 'test',
      'images': [],
      'tags': [
        '61a67e71ebc745fb3cfe52c2'
      ],
      'createdAt': '2021-12-09T18:16:49.883Z',
      'updatedAt': '2021-12-09T18:16:49.883Z',
      'id': '61b24811884ef8be77017c73'
    },
    {
      '_id': '61b2494b93c686b903462bcd',
      'subject': 'test',
      'body': 'root',
      'images': [],
      'tags': [
        '61a67e71ebc745fb3cfe52c2'
      ],
      'createdAt': '2021-12-09T18:22:03.482Z',
      'updatedAt': '2021-12-09T18:22:03.482Z',
      'id': '61b2494b93c686b903462bcd'
    },
    {
      '_id': '646c1217521360839e9184ab',
      'subject': 'Photos',
      'body': 'SummitMD Dermatology',
      'images': [
        '646c1216521360839e9184a8'
      ],
      'tags': [
        '61aa9c9106d49c894f38fa93',
        '61a67e71ebc745fb3cfe52c2',
        '6102e8c4ac15a717ce15fedd'
      ],
      'createdAt': '2023-05-23T01:08:39.373Z',
      'updatedAt': '2023-05-23T01:08:39.373Z',
      'id': '646c1217521360839e9184ab'
    },
    {
      '_id': '646e84fabe06db278d22825a',
      'subject': 'Main Banner',
      'body': 'SummitMD Dermatology',
      'images': [
        '646e84f9be06db278d228257'
      ],
      'tags': [
        '61aa9c9106d49c894f38fa93',
        '61a67e71ebc745fb3cfe52c2',
        '6102f0c13b3fd28628ca935a'
      ],
      'createdAt': '2023-05-24T21:43:22.678Z',
      'updatedAt': '2023-05-24T21:43:22.678Z',
      'id': '646e84fabe06db278d22825a'
    }
  ];
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

  const routerLocation = useLocation();
  const pathnames = routerLocation.pathname.split( '/' ).filter( ( x ) => x );

  const [ title, setTitle ] = useState( 'Practice Directory' );
  const [ position, _setPosition ] = useState<GeolocationPosition>();

  // Google Analytics
  // useEffect(() => {ga('send', 'pageview');}, [routerLocation]);

  useEffect( () => {
    if( position?.timestamp ) return;
    // navigator.geolocation.getCurrentPosition( ( p ) => {
    //   if( p.timestamp && p.timestamp != position?.timestamp ) {
    //     // console.log( p );
    //     setPosition( p );
    //   }
    // }, undefined, { maximumAge: 60000 /* Infinity */, timeout: Infinity, enableHighAccuracy: false } )
  }, [] );

  const { logoAsset, logoTitle, colorPrimary, colorSecondary, fontBody, fontHeader, iconProps } = useMemo( () => {
    if( !organization ) return {};
    // const { amenityTags } = organization;
    const { iconStyle = 'default', amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader } = ( organization || {} ) as unknown as OrganizationStyling;
    const iconProps = {
      // htmlColor: [ 'default', 'primary', 'secondary' ].includes( iconStyle ) ? undefined : iconStyle,
      '& svg': {
        color: [ 'primary', 'secondary' ].includes( iconStyle )
          ? ( iconStyle == 'primary' ? colorPrimary : colorSecondary )
          : 'inherit',
        fontSize: '2.25rem', // 'large',
      }
    };
    const logoAsset = ( assets ).find( asset => ( asset.tags || [] ).includes( getCommonTagId( 'banner' ) ) );
    const logoTitle = [ organization.name, logoAsset?.subject || 'logo' ].join( ' ' );
    return { logoAsset, logoTitle, iconProps, amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader };
  }, [ assets, organization ] );


  return (
    <Box
      id='orgs'
      sx={{
        padding: '0 1.5rem',
        maxWidth: '960px',
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: '1.2rem',
        backgroundColor: '#f9fafb',
        '& .MuiListItemText-root .MuiTypography-root': {
          // fontFamily: fontBody,
        },

      }}>


      { /* <SeoTags title={title} organization={organization} logoAsset={logoAsset} /> */}
      <ScrollRestoration />

      <Grid item container direction="row"
        sx={{
          margin: { xs: '0 -1.3em', md: 'inherit' },
          width: { xs: '120%', md: 'inherit' },
          // border: '1px solid blue',
        }}
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <Typography variant='h1'
          align={isXSmall ? 'center' : 'left'}
          style={{
            fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
            color: colorPrimary,
            // color: 'white',
            width: '100%',
            padding: '0rem 1.0rem',
            fontWeight: 'bold', //'600',
            fontSize: 'medium',
            fontSmooth: 'always',
          }}
        >
          <MuiLink
            href='https://analoginformatics.com'
            sx={{
              // width: '100%',
              textDecorationLine: 'none',
              color: 'inherit',
              '&:hover': {
                textDecorationLine: 'underline',
                // color: 'initial',

              }
            }}
          >
            AIC Analog Conceirge&trade;
          </MuiLink>
          { /* transparent button used to match height of Location header bar */}
          <IconButton sx={{ color: 'transparent' }}>
            <ArrowBackIosNew />
          </IconButton>
        </Typography>
        <MuiLink
          href='/locs'
          sx={{
            width: '100%',
            textDecorationLine: 'none',
          }}
        >
          <Typography variant='h1'
            align={isXSmall ? 'center' : 'left'}
            style={{
              fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
              backgroundColor: colorPrimary,
              color: 'white',
              width: '100%',
              padding: '0.5rem 1.0rem',
              fontWeight: 'bold', //'600',
              fontSize: 'x-large',
              fontSmooth: 'always',
            }}
          >
            {title}
            { /* transparent button used to match height of Location header bar */}
            <IconButton sx={{ color: 'transparent' }}>
              <ArrowBackIosNew />
            </IconButton>
          </Typography>
        </MuiLink>
      </Grid>

      <LocBreadcrumbs />

      <Box
        sx={{
          minHeight: '30rem',
        }}
      >
        <Outlet
          context={{
            logoAsset, logoTitle,
            organization, assets,
            fontBody, fontHeader, colorPrimary, colorSecondary,
            setTitle,
          }}

        />
      </Box>
      <PageFooter />
    </Box >
  );
}

export interface OrganizationsOutlet extends LocationsData {
  logoAsset?: AssetInterface;
  logoTitle?: string;
  setTitle: Dispatch<SetStateAction<string>>;
  fontBody: string;
  fontHeader: string;
  colorPrimary: string;
  colorSecondary: string;
  closestLocation?: LocationInterface;
}

export const OrgsIndex: FC = () => {
  const {
    logoAsset, logoTitle,
    locations, organization,
    fontBody, fontHeader, colorPrimary, colorSecondary,
    setTitle,
  } = useOutletContext() as OrganizationsOutlet;
  const theme = useTheme();

  // useEffect( () => setTitle( organization.name ), [ organization.name ] );

  return (
    <Box
    >
      <Grid item container key='derm' direction='column' pt={1}>
        <Grid item>
          <Typography variant='h2'
            style={{
              fontFamily: fontHeader,
              color: colorPrimary,
              // color: 'white',
              // width: '100%',
              // padding: '1rem',
              fontWeight: 'bold', // '300',
              fontSize: 'x-large',
              fontSmooth: 'always',
              textTransform: 'uppercase',
            }}
          >
            Dermatology
          </Typography>
        </Grid>

        <Grid item container direction='column' pt={1}>
          <Grid item>
            <List
              disablePadding
              sx={{
                marginTop: '2rem',
                '& .MuiListItem-root:hover': {
                  backgroundColor: alpha( theme.palette.text.primary, 0.04 ),
                }
              }}
            >
              <Link
                key='organization.name'
                to='https://summitmd.analoginformation.com'
                style={{
                  color: 'inherit',
                  textDecorationLine: 'none',
                }}
              >
                <ListItem
                  key='organization.id'
                  disableGutters
                  sx={{
                    color: 'inherit',
                    '& .MuiListItemIcon-root': {
                      minWidth: 40,
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      marginRight: '1rem',
                      // padding: '0.5rem',
                    }}

                  >
                    {/* @ts-ignore:2322 */}
                    <img src='https://summitmd.analoginformation.com/api/v1/image/646e853b6fcab80acb3b1c3c.png?t=168496466882377476'
                      {...titleAlt( 'SummitMD logo' )}
                      width='240' height='80'
                      style={{ objectFit: 'cover' }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary='SummitMD Dermatology'
                    primaryTypographyProps={{ variant: 'h6' }}
                    // secondary={location.addressComponents?.short?.city ?? undefined}
                    sx={{
                      display: { xs: 'none', sm: 'inherit' },
                    }}
                  />
                </ListItem>
              </Link>
            </List>
          </Grid>
        </Grid>

        <Grid item>
          <Typography variant='h2'
            style={{
              fontFamily: fontHeader,
              color: colorPrimary,
              // color: 'white',
              // width: '100%',
              paddingTop: '2rem',
              fontWeight: 'bold', // '300',
              fontSize: 'x-large',
              fontSmooth: 'always',
              textTransform: 'uppercase',
            }}
          >
            Ophthalmology
          </Typography>
        </Grid>

        <Grid item container direction='column' pt={1}>
          <Grid item>
            <List
              disablePadding
              sx={{
                marginTop: '2rem',
                '& .MuiListItem-root:hover': {
                  backgroundColor: alpha( theme.palette.text.primary, 0.04 ),
                }
              }}
            >
              <Link
                key='organization.name'
                to='https://lindavision.analoginformation.com'
                style={{
                  color: 'inherit',
                  textDecorationLine: 'none',
                }}
              >
                <ListItem
                  key='organization.id'
                  disableGutters
                  sx={{
                    color: 'inherit',
                    '& .MuiListItemIcon-root': {
                      minWidth: 40,
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      marginRight: '1rem',
                    }}

                  >
                    {/* @ts-ignore:2322 */}
                    <img src='https://lindavision.analoginformation.com/api/v1/image/66049eb4d45f689b3eeb1e9a.png?t=170000012458115855'
                      {...titleAlt( 'Linda Vision logo' )}
                      width='240' height='80'
                      style={{ objectFit: 'contain' }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary='Linda Vision'
                    primaryTypographyProps={{ variant: 'h6' }}
                    // secondary={location.addressComponents?.short?.city ?? undefined}
                    sx={{
                      display: { xs: 'none', sm: 'inherit' },
                    }}
                  />


                </ListItem>
              </Link>
            </List>
          </Grid>
        </Grid>
      </Grid>

    </Box>

  )
}
