import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, useMemo, useCallback } from 'react';
import { FeedbackInterface, LocationInterface, PractitionerInterface, PractitionerReputationServiceInterface, ReputationServiceInterface } from '../types';
import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';
import { Box, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

const caredash = '/caredash.png';
const google = '/google.png';
const googleMaps = '/google.png';
const healthcare6 = '/healthcare6.png';
const healthgrades = '/healthgrades.png';
const npino = '/npino.png';
const sharecare = '/sharecare.png';
const yelp = '/yelp.png';

const apiServer = process.env.REACT_APP_API_SERVER || '';
const apiPath = process.env.REACT_APP_API_PATH || '/api/v1/data';

const reputationServiceLogo: Record<string, string> = {
  caredash,
  google,
  googleMaps,
  healthcare6,
  healthgrades,
  npino,
  sharecare,
  yelp,
};

export const ReputationServiceExplanation: FC<{ platform: string }> = ( { platform } ) => {
  const { i18n } = useTranslation( 'translation', { useSuspense: false } );

  const reputationServiceExplanation: Record<string, JSX.Element> = {
    google: (
      <>
        <Typography paragraph> To leave a <span className='translated'>Google</span> review, you will need a <span className='translated'>Google</span> account.</Typography>
        <Typography paragraph>If you have a <span className='translated'>Gmail</span> address or an <span className='translated'>Android</span> phone, that email address is your existing <span className='translated'>Google</span> account you will use to leave a review.</Typography>
        <Typography paragraph>You can setup a <u>new</u> <span className='translated'>Google</span> account using your existing email address or by creating a new free <span className='translated'>Gmail</span> email account.</Typography>
        <Typography paragraph>Use the following link to create a new <span className='translated'>Google</span> account: <a className='translated' target="_blank" rel="noopener" href={`https://support.google.com/accounts/answer/27441?hl=${ i18n.language }`}>https://support.google.com/accounts/answer/27441</a>
        </Typography>
      </>
    ),
    yelp: (
      <>
        <Typography paragraph>To leave a <span className='translated'>Yelp</span> review, you will need a <span className='translated'>Google</span>, <span className='translated'>Apple</span> or <span className='translated'>Yelp</span> account.</Typography>
        <Typography paragraph>If you have a <span className='translated'>Google</span> or <span className='translated'>Apple</span> account, simply click Login and select <span className='translated'>"Continue with Google"</span> or <span className='translated'>"Continue with Apple"</span>.</Typography>
        <Typography paragraph>If you do not have a <span className='translated'>Google</span> or <span className='translated'>Apple</span> account or wish to use a different email, just click the <span className='translated'>"Sign up"</span> button in the top-right of the screen. Then click <span className='translated'>"Continue with email"</span>.</Typography>
      </>
    ),
  };

  return reputationServiceExplanation[ platform ] || null;
}



export interface ReviewButtonsProps {
  feedback: FeedbackInterface;
  subject: PractitionerInterface | LocationInterface;
  reputationServices: ReputationServiceInterface[];
}

export const ReviewButtons: FC<ReviewButtonsProps> = ( props ) => {
  const { subject, feedback, reputationServices } = props;
  const { i18n } = useTranslation( 'translation', { useSuspense: false } );

  return (
    <>
      <Typography variant="h5" sx={{ marginTop: '0em', marginBottom: '0.5em' }} >
        Review: <span className='translated'>{'fullName' in subject ? `${ subject.fullName }` : `${ subject.name }`}</span>
      </Typography>
      <Grid container direction="row" key="reviewLinks"
        justifyContent="flex-start" spacing={6}
        alignItems="center"
        sx={{
          marginBottom: '2.0em',
        }}
      >
        {sortBy( reputationServices, 'id' ).map( ( platform ) => (
          <Grid item key={platform.id} xs={12} sm={4}  >
            <ReviewButton key={platform.id} service={platform} feedback={feedback} />
          </Grid>

          /*
             <Grid item key={platform.id} xs={12} sm={12} sx={{
             // border: '1px solid blue',
             // height: '10em'
             }}>
             <Box sx={{ display: 'flex' }}>
             <ReviewButton key={platform.id} service={platform} feedback={feedback} />
             <Box sx={{ marginLeft: '2rem', flex: 8, display: 'none' }} >
             <ReputationServiceExplanation platform={platform.id} />
             </Box>
             </Box>
             </Grid>
           */
        ) )
        }
      </Grid >
    </>
  );
}

export interface ReviewButtonProps {
  feedback: FeedbackInterface;
  service: ReputationServiceInterface;
}

export const ReviewButton: FC<ReviewButtonProps> = ( { feedback, service } ) => {
  const { id, name, referralUrl, url } = service;
  const image = reputationServiceLogo[ id ];
  const { i18n } = useTranslation( 'translation', { useSuspense: false } );

  const reviewUrl = ( referralUrl || url || '' );

  const recordClick = useCallback( debounce( async () => {
    await fetch( `${ apiServer }${ apiPath }/feedback/review/clicked`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( { t: feedback._id, url } ),
    } );
  }, 10000, { leading: true, trailing: false } ), [] )

  const handleClick = async () => {
    console.log( 'clicked', name );
    const url = reviewUrl.replace( /writereview/, 'reviews' ) + ( reviewUrl.includes( '?' ) ? '&' : '?' ) + `hl=${ i18n.language }`;
    document.open( url, '_blank', 'noopener' );
    await recordClick();
  };

  if( !service || !url || !image ) return null;

  return (
    <Box
      sx={{
        flex: 'auto',
        width: '12rem',
        height: '12rem',
      }}
    >
      <Card
        sx={{
          height: '100%',
          width: '100%',
          borderRadius: '5%',
        }}
        elevation={4}
      >
        <CardActionArea
          onClick={handleClick}
          sx={{ width: '100%', height: '100%' }}
        >
          <CardMedia
            component='img'
            image={image}
            alt={`Review link for ${ service.name }`}
            sx={{ maxWidth: '90%', maxHeight: '90%', margin: 'auto' }}
          />
        </CardActionArea>
      </Card>
    </Box>
  );
}
