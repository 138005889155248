/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { printf } from 'fast-printf';
import { ReactElement } from 'react';

export interface WeekdayOpenClose {
  weekday: number;
  openHour?: number;
  openMinute?: number;
  closeHour?: number;
  closeMinute?: number;
}
export type WeekdaysHours = WeekdayOpenClose[]

export const defaultWeekdaysHours: WeekdaysHours = [
  { weekday: 1, openHour: 8, openMinute: 0, closeHour: 17, closeMinute: 0 },
  { weekday: 2, openHour: 8, openMinute: 0, closeHour: 17, closeMinute: 0 },
  { weekday: 3, openHour: 8, openMinute: 0, closeHour: 17, closeMinute: 0 },
  { weekday: 4, openHour: 8, openMinute: 0, closeHour: 17, closeMinute: 0 },
  { weekday: 5, openHour: 8, openMinute: 0, closeHour: 17, closeMinute: 0 },
  { weekday: 6 },
  { weekday: 0 },
]


export const lpad = ( n = 0, len = 2, pad = '0' ): string => ( pad.repeat( len ) + n ).slice( 0 - len )

type WeekdayHoursPair = [ string, string | undefined ];
type WeekdaysHoursPair = [ string[], string | undefined ];
type WeekdaysHoursPairs = WeekdaysHoursPair[];

const nbsp = '\u00a0';

export const formatWeekdaysHours = ( weekdaysHours: WeekdaysHours = [], useNbsp = false ): string => {
  if( !weekdaysHours ) return '';
  const days = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
  const formatTime = ( hour?: number, minute?: number ): string => (
    `${ ( hour && hour > 12 ? hour - 12 : hour )?.toString() }${ minute ? `:${ lpad( minute ) }` : '' } ${ !hour || hour <= 12 ? 'AM' : 'PM' }`
  );
  const formatDayHours = ( wd: WeekdayOpenClose ): WeekdayHoursPair => {
    const { weekday, openHour, openMinute, closeHour, closeMinute } = wd;
    const day = days[ weekday ] || '';
    if( openHour == undefined || closeHour == undefined ) {
      return [ day, undefined ];
    }
    const open = formatTime( openHour, openMinute );
    const close = formatTime( closeHour, closeMinute );
    return [ day, `${ open } - ${ close }` ];
  }
  // Turn [ [Sun, '9 AM - 1:30 PM'], [ 'Sun', '2 PM - 6 PM']...] into [ [ 'Sun', '9 AM - 1:30 PM,2 PM - 6 PM' ], ... ]
  const combineDayHoursReducer = ( pairs: WeekdayHoursPair[], [ day, hours ]: WeekdayHoursPair ): WeekdayHoursPair[] => {
    const idx = pairs.findIndex( pair => pair[ 0 ] == day );
    if( idx >= 0 ) {
      pairs[ idx ][ 1 ] = pairs[ idx ][ 1 ] ? `${ pairs[ idx ][ 1 ] }, ${ hours }` : hours;
      return pairs;
    }
    return pairs.concat( [ [ day, hours ] ] );
  };

  const weekdaysHoursReducer = ( pairs: WeekdaysHoursPairs, [ day, hours ]: WeekdayHoursPair ): WeekdaysHoursPairs => {
    let [ prevDays, prevHours ]: WeekdaysHoursPair = [ [], undefined ];
    if( pairs.length > 0 ) {
      [ prevDays, prevHours ] = pairs[ pairs.length - 1 ];
    }
    if( prevHours && prevHours == hours ) {
      return pairs.slice( 0, -1 ).concat( [ [ [ ...prevDays, day ], prevHours ] ] )
    }
    return pairs.concat( [ [ [ day ], hours ] ] );
  }

  return weekdaysHours
    .map( formatDayHours )
    .reduce<WeekdayHoursPair[]>( combineDayHoursReducer, [] )
    .reduce<WeekdaysHoursPairs>( weekdaysHoursReducer, [] )
    .filter( pair => pair[ 1 ] )
    .map( ( [ days, hours ]: WeekdaysHoursPair ): string => {
      return `${ days.length > 2 ? `${ days[ 0 ] }-${ days.slice( -1 )[ 0 ] }` : days.join( ', ' ) } ${ hours }`
    } )
    .map( s => useNbsp ? s.replace( / /g, nbsp ) : s )
    .join( ', ' );
}

interface WeekdayHourMinute {
  weekday: number;
  hour: number;
  minute: number;
}

export const getWeekdayHourMinute = (): WeekdayHourMinute => {
  const current = new Date();
  return {
    weekday: current.getDay(),
    hour: current.getHours(),
    minute: current.getMinutes(),
  };
}

export const isOpen = ( weekdaysHours?: WeekdaysHours ): boolean => {
  if( !weekdaysHours ) return false;
  const { weekday, hour, minute } = getWeekdayHourMinute();
  const todayWeekdayHours = weekdaysHours.filter( w => w.weekday == weekday );
  if( todayWeekdayHours.length == 0 ) return false;
  return !todayWeekdayHours.find( hours => {
    const { openHour, openMinute = 0, closeHour, closeMinute = 0 } = hours;
    if( !openHour || !closeHour ) return false;
    if( hour < openHour ) {
      return false;
    } else if( hour === openHour ) {
      return minute >= openMinute;
    } else if( hour === closeHour ) {
      return minute < closeMinute;
    } else if( hour > closeHour ) {
      return false;
    }
    return true;
  } );
}

type WeekdayOpenCloseActive = { openHour: number, closeHour: number } & Omit<WeekdayOpenClose, 'openHour' | 'closeHour'>;

export const hoursToday = ( weekdaysHours?: WeekdaysHours ): ReactElement => {
  if( !weekdaysHours ) return <></>;
  const current = new Date();
  const weekday = current.getDay();
  const todayWeekdayHours = weekdaysHours.filter( w => w.weekday == weekday && w.openHour && w.closeHour )
    .filter( ( w ): w is WeekdayOpenCloseActive => !!w );
  if( !todayWeekdayHours.length ) return <></>;

  const formattedHM = ( hour: number, minute = 0 ): string => (
    printf( '%d:%02d ', hour == 0 ? 12 : hour > 12 ? hour - 12 : hour, minute ) + ( hour >= 12 ? 'pm' : 'am' )
  );

  for( const [ idx, weekdayHours ] of todayWeekdayHours.entries() ) {
    const { closeHour, closeMinute } = weekdayHours;
    if( isOpen( [ weekdayHours ] ) ) {
      const open = <span>Open until <span className='translated'>{ formattedHM( closeHour, closeMinute ) }</span></span>;
      if( idx + 1 >= todayWeekdayHours.length ) return open;
      return <>
        { open }
        {', '}
        <span>also open</span>
        {' '}
        <span className='translated'>
          { todayWeekdayHours.slice( idx + 1 )
            .map( weekdayHours => {
              const { openHour, openMinute = 0, closeHour, closeMinute = 0 } = weekdayHours;
              return `${ formattedHM( openHour, openMinute ) } - ${ formattedHM( closeHour, closeMinute ) }`;
            } ).join( ', ' )
          }
        </span>
      </>;
    }
  }

  return <span className='translated'>{
    todayWeekdayHours.map( weekdayHours => {
      const { openHour, openMinute = 0, closeHour, closeMinute = 0 } = weekdayHours;
      return `${ formattedHM( openHour, openMinute ) } - ${ formattedHM( closeHour, closeMinute ) }`;
    } ).join( ', ' )
  }</span>;
}
