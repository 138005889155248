

// Both isGuid and isObjectId are type guard predicates that assert the argument is valid and of type string

export type GuidString = string;
export const isGuid = ( guid: unknown ): guid is GuidString => ( typeof guid == 'string' && !!guid.match( /^[-a-f0-9]{36}$/ ) );

export type ObjectIdString = string;
export const isObjectId = ( id: unknown ): id is ObjectIdString => ( typeof id == 'string' && !!id.match( /^[a-f0-9]{24}$/ ) );

