/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import { ArrowBackIosNew } from '@mui/icons-material';
import { Box, IconButton, Link as MuiLink, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRouteLoaderData } from 'react-router-dom';
import { getCommonTagId } from './components/AmenityChoices';
import { PageFooter } from './components/page-footer';
import { LocationsData } from './profiles';
import styles from './styles/Home.module.css';
import { LocationInterface, OrganizationInterface, OrganizationStyling } from './types';


export const ProfileThankYou: FC = () => {
  const { organization, assets } = useRouteLoaderData( 'profiles' ) as LocationsData;
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

  const [ isLoaded, setIsLoaded ] = useState( false );

  const { logoAsset, logoTitle, colorPrimary, fontBody, fontHeader } = useMemo( () => {
    if( !organization ) return {};
    // const { amenityTags } = organization;
    const { iconStyle = 'default', amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader } = ( organization || {} ) as unknown as OrganizationStyling;
    const iconProps = {
      // htmlColor: [ 'default', 'primary', 'secondary' ].includes( iconStyle ) ? undefined : iconStyle,
      '& svg': {
        color: [ 'primary', 'secondary' ].includes( iconStyle )
          ? ( iconStyle == 'primary' ? colorPrimary : colorSecondary )
          : 'inherit',
        fontSize: '2.25rem', // 'large',
      }
    };
    const logoAsset = ( assets ).find( asset => ( asset.tags || [] ).includes( getCommonTagId( 'square' ) ) );
    const logoTitle = [ organization.name, logoAsset?.subject || 'logo' ].join( ' ' );
    return { logoAsset, logoTitle, iconProps, amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader };
  }, [ assets, organization ] );

  if( !( organization ) ) return null;

  return (
    <Box
      id='profile'
      sx={{
        padding: { xs: '0 1.5rem' },
        maxWidth: { xs: '100%', md: '960px' },
        minHeight: '600px',
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: { xs: '1rem', md: '1.2rem' },
        backgroundColor: '#f9fafb',
        fontFamily: fontBody,
        '& .MuiListItemText-root .MuiTypography-root': {
          fontFamily: fontBody,
        },
      }}>

      <Helmet>
        <title>Thank you - {organization.name || ''}</title>
        <meta name='description' content={`Thank you from ${ organization.name || '' }`} />
        <link rel='icon' href='/favicon.ico' />
      </Helmet>



      <TopSection {...{ organization, fontHeader, colorPrimary, isXSmall }} />

      <Typography
        align='left'
        sx={{
          margin: '2rem 0 20rem',
          width: '100%',
          fontStyle: 'italic',
          fontSize: '120%',
          // color: colorPrimary || 'primary',
        }}
        color='inherit'
      >
        Thank you for using Analog Concierge&trade;.
      </Typography>

      <PageFooter organization={organization} />

    </Box>
  )
}

export interface TopSectionProps {
  location?: LocationInterface;
  organization?: OrganizationInterface;
  fontHeader?: string;
  colorPrimary?: string;
  isXSmall?: boolean;
}

export const TopSection: FC<TopSectionProps> = props => {
  const { location, organization, fontHeader, colorPrimary, isXSmall } = props;
  return (
    <>
      <Grid item container direction='row'
        sx={{
          margin: { xs: '0 -1.3em', md: 'inherit' },
          width: { xs: '120%', md: 'inherit' },
          // border: '1px solid blue',
        }}
        justifyContent='space-between'
      >
        <Grid item xs={5} >
          <Typography variant='h1'
            align={isXSmall ? 'center' : 'left'}
            style={{
              fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
              color: colorPrimary,
              // color: 'white',
              width: '100%',
              padding: '0rem 1.0rem',
              fontWeight: 'bold', //'600',
              fontSize: 'medium',
              fontSmooth: 'always',
            }}
            className='translated'
          >
            AIC Analog Concierge &trade;
            { /* transparent button used to match height of Location header bar */}
            <IconButton sx={{ color: 'transparent' }}>
              <ArrowBackIosNew />
            </IconButton>
          </Typography>
        </Grid>
      </Grid>
      <MuiLink
        href='/locations'
        sx={{
          width: '100%',
          textDecorationLine: 'none',
        }}
      >
        <Typography variant='h1'
          align={isXSmall ? 'center' : 'left'}
          style={{
            fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
            backgroundColor: colorPrimary,
            color: 'white',
            width: '100%',
            padding: '0.5rem 1.0rem',
            fontWeight: 'bold', //'600',
            fontSize: 'x-large',
            fontSmooth: 'always',
          }}
          className='translated'
        >
          {location?.name || organization?.name}
          { /* transparent button used to match height of Location header bar */}
          <IconButton sx={{ color: 'transparent' }}> <ArrowBackIosNew /> </IconButton>
        </Typography>
      </MuiLink>
    </>
  );
}
