import { Box } from '@mui/material';
import { FC, useState, useRef } from 'react';
import { Camera, CameraProps, CameraType } from 'react-camera-pro';

export const Capture: FC = () => {
  const camera = useRef<CameraType | undefined>();
  const [ image, setImage ] = useState<string | undefined>();

  return (
    <div>
      {camera &&
        <Box
          sx={{
            zIndex: 1,
          }}

        >
          <button
            onClick={() => setImage( camera.current?.takePhoto( 'base64url' ) as string )}
          >
            Take photo
          </button>
        </Box>
      }
      <Camera
        ref={camera}
        errorMessages={{}}

      />
      {image &&
        <img src={image} alt='Taken photo' />
      }
    </div>
  );
}
