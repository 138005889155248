/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC } from 'react';
import { useHref, useOutletContext } from 'react-router-dom';
import { FeedbackContext } from './FeedbackContainer';
import { NonProfitSearch } from './components/NonProfitSearch';
import styles from './styles/Home.module.css';

export const FeedbackNonProfits: FC = () => {
  const { feedbackId, practitioner, selectedOption, setSelectedOption, isXSmall } = useOutletContext<FeedbackContext>();
  const { isInternalReviewOnly } = practitioner;

  const hrefReviews = useHref( '../reviews', { relative: 'path' } );
  const hrefSurvey = useHref( '../survey', { relative: 'path' } );
  const nextPage = isInternalReviewOnly ? hrefSurvey : hrefReviews;

  const hrefPrevious = useHref( '../recommend', { relative: 'path' } );

  return (
    <Box sx={{
    }}>
      <Grid item key="content">
        <Typography variant='h6' className={styles.description}
          sx={{ margin: '0.5em 0' }}
        >
          SELECT YOUR CHARITY
        </Typography>

        <p>
          We have partnered with <Link href="https://www.pledge.to/impact" target="_blank" rel="noopener" className='translated'>Pledge</Link>  to provide you
          with access to over 20,000 charities.
          Select a top charity or search for your preferred charity below or skip directly to the evaluation.
        </p>


        <NonProfitSearch token={feedbackId} onChange={setSelectedOption} initialValue={selectedOption || undefined} />

        <Grid item container key="nextButton" justifyContent="space-between" sx={{ marginTop: '2em' }}>
          {!isXSmall &&
            <Button variant="outlined" size="large" href={hrefPrevious} fullWidth={isXSmall}>
              Previous
            </Button>
          }
          <Button variant="contained" size="large" href={nextPage} color="success" fullWidth={isXSmall}>
            {selectedOption ? 'Next' : 'Skip'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}


