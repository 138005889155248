/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import { FormControl, FormHelperText, Radio, RadioGroup, Table, TableBody, TableContainer } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { validate as isEmail } from 'email-validator';
import { FC, useMemo } from 'react';
import { Controller, useForm, GlobalError } from 'react-hook-form';
import { splitArray } from '../Profile';
import { PatientContactMethodFormData, ServerError, CommunicationTypeFieldNames, isPatientContactMethodFormDataField } from '../profiles';
import { RecipientInterface, RecipientTypeEnum } from '../types';
import { normalizePhone, validatePhoneNumber } from './AwesomePhoneField';


const formDataDefault: PatientContactMethodFormData = {
  value: '',
  system: '',
}

export interface PatientContactMethodFormProps {
  data: PatientContactMethodFormData;
  countryCode: string;
  isPhone?: boolean;
  isAdd?: boolean;
  title?: string;
  open?: boolean;
  fullScreen?: boolean;
  onSubmit: ( data: PatientContactMethodFormData ) => Promise<undefined | Record<keyof PatientContactMethodFormData, ServerError>>;
  onClose?: () => void;
}

export type AddPatientContactMethodProps = Omit<PatientContactMethodFormProps, 'title' | 'data'>;

export const AddPatientContactMethod: FC<AddPatientContactMethodProps> = ( props ) => {
  return <PatientContactMethodForm
    title='Add contact method'
    isAdd
    {...props}
    data={formDataDefault}
  />
}

export interface EditPatientContactMethodProps extends Omit<PatientContactMethodFormProps, 'title' | 'data'> {
  data: PatientContactMethodFormData;
}

export const EditPatientContactMethod: FC<EditPatientContactMethodProps> = ( props ) => {
  return <PatientContactMethodForm
    title='Edit contact method'
    {...props} />
}

export const PatientContactMethodForm: FC<PatientContactMethodFormProps> = ( props ) => {
  const { isAdd, isPhone, countryCode, onClose, onSubmit, data: defaultValues, open = true, fullScreen = false, title } = props;
  const { control, getValues, watch, handleSubmit, setError, clearErrors, formState: { errors, isDirty, isValidating, isValid, isSubmitted, isSubmitting } } = useForm<PatientContactMethodFormData>( { defaultValues } );

  defaultValues.system = defaultValues.system || isPhone ? 'phone' : 'email';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth='lg'
      sx={{
        '& .MuiDialog-paper': {
          minWidth: '50%',
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        { /*
        <DialogContentText>
          Designate an Authorized Recipient to receive communications on your behalf and only of the types you select here. Change or remove this recipient here at any time later.
        </DialogContentText>
     */ }
        <form>

          {!isPhone &&
            <Controller
              name='value'
              control={control}
              rules={{
                validate: {
                  valid: ( email ) => !email || isEmail( email ) || 'Must be an email',
                }
              }}

              render={( { field } ) => (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  onChange={( e ) => {
                    field.onChange( e.target.value.toLowerCase() );
                    clearErrors( 'value' );
                  }}
                  required
                  margin='dense'
                  label='Email'
                  type='email'
                  fullWidth
                  variant='filled'
                  error={!!errors?.value?.message}
                  helperText={errors?.value?.message}
                />
              )}
            />
          }

          {isPhone &&
            <>
              <Controller
                name='value'
                control={control}
                rules={{
                  validate: {
                    valid: ( phone ) => !phone || validatePhoneNumber( countryCode )( phone ) || 'Must be a phone number',
                  },
                }}
                render={( { field } ) => {
                  return (
                    <TextField
                      {...field}
                      inputRef={field.ref}
                      onChange={( e ) => {
                        field.onChange( normalizePhone( countryCode )( e.target.value ) );
                        clearErrors( [ 'value', 'system' ] );
                      }}
                      required
                      margin='dense'
                      label='Phone'
                      variant='filled'
                      error={!!errors?.value?.message}
                      helperText={errors?.value?.message}
                      fullWidth
                    />
                  );
                }}
              />

              <Controller
                name='system'
                control={control}
                render={( { field } ) => {

                  return (
                    <FormControl
                      // margin='dense'
                      variant='filled'
                      error={!!errors?.system?.message}
                    >
                      <FormLabel id='system'>Use</FormLabel>
                      <RadioGroup
                        {...field}
                        aria-labelledby='system'
                        onChange={( e ) => { field.onChange( e.target.value ); clearErrors( 'system' ) }}
                        row
                      >
                        <FormControlLabel value='phone' control={<Radio />} label='Phone/Voice' />
                        <FormControlLabel value='sms' control={<Radio />} label='SMS/Text' />
                      </RadioGroup>
                      <FormHelperText error={!!errors?.system?.message}>
                        {errors?.system?.message}
                      </FormHelperText>
                    </FormControl>
                  );
                }}
              />
            </>
          }

        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          disabled={isSubmitting || ( isSubmitted && isValid )}
        >
          Cancel
        </Button>
        <Button variant='contained'
          disabled={!isDirty || isValidating || isSubmitting || !isValid}
          onClick={handleSubmit(
            async ( data ) => {
              const errors = await onSubmit( data );
              if( errors ) {
                Object.entries( errors )
                  .forEach( ( [ field, error ], idx ) => {
                    if( isPatientContactMethodFormDataField( field ) ) {
                      setError( field, error, { shouldFocus: idx === 0 } );
                    }
                  } );
              }
            },
            ( v ) => { console.log( errors, v ) } )}
        >
          {isAdd ? 'Add' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog >
  )
}

