/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import { Add as AddIcon, Delete as DeleteIcon, DeviceUnknown, DialerSip, Edit as EditIcon, Email as EmailIcon, Fax, HelpOutline, Lock, PhoneIphone, RecordVoiceOver, RemoveCircle as Unsubscribe, Smartphone, Sms } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import styled from '@mui/material/styles/styled';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { capitalize } from 'inflection';
import chunk from 'lodash/chunk';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext, useRevalidator } from 'react-router-dom';
import { renderPhoneNumber } from './components/AwesomePhoneField';
import { ConfirmDialog } from './components/ConfirmDialog';
import { AddPatientContactMethod } from './components/PatientContactMethodForm';
import { AddPatientRecipient, EditPatientRecipient } from './components/PatientRecipientForm';
import RotaryPhone from './components/RotaryPhone';
import { ProfileContext } from './ProfileContainer';
import { activateContactMethod, addContactMethod, addRecipient, CommunicationTypeFieldNames, deleteContactMethod, deleteRecipient, moveContactMethod, PatientRecipientFormData, unsubscribeAllContactMethods, unsubscribeAllRecipients, updateCommunicationType, updateRecipient } from './profiles';
import { RecipientInterface, TelecomOptionInterface } from './types';

export const splitArray = <T = unknown>( a: T[], chunks = 2 ): T[][] => {
  return chunk( a, Math.ceil( a.length / chunks ) );
}

export const Profile: FC = () => {
  const { profileId, recipient, recipients, organization, colorPrimary, colorSecondary, fontBody, fontHeader, setNotify, setError, setTitle, isXSmall, isSmall, isMedium, countryCode: regionCode = 'US', appointments } = useOutletContext<ProfileContext>();
  const revalidator = useRevalidator();
  const navigate = useNavigate();

  useEffect( () => setTitle( organization.name ), [ organization.name ] );

  const [ openAddPhone, setOpenAddPhone ] = useState( false );
  const [ openAddEmail, setOpenAddEmail ] = useState( false );
  const [ openDeleteContactMethod, setOpenDeleteContactMethod ] = useState<string>( '' );
  const [ openLockedContactMethod, setOpenLockedContactMethod ] = useState<string>( '' );
  const [ openUnsubscribeAllContactMethods, setOpenUnsubscribeAllContactMethods ] = useState( false );
  const [ openAddRecipient, setOpenAddRecipient ] = useState( false );
  const [ openDeleteRecipient, setOpenDeleteRecipient ] = useState<string>( '' );
  const [ openEditRecipient, setOpenEditRecipient ] = useState<string>( '' );
  const [ openUnsubscribeAllRecipients, setOpenUnsubscribeAllRecipients ] = useState( false );
  const [ deletedIds, setDeletedIds ] = useState<string[]>( [] ); // both contact methods and recipients

  const getFormattedValueOfTelecomOption = useCallback( ( telecomOption?: TelecomOptionInterface ): string => {
    const { system = '', value = '' } = telecomOption || {};
    if( ![ 'phone', 'sms' ].includes( system ) ) return value;
    return value && parsePhoneNumber( value, { regionCode } ).number?.national || '';
  }, [] );

  const onAddContactMethod = useCallback( async ( system: 'phone' | 'email' | 'sms', value?: string ) => {
    const result = await addContactMethod( profileId, { system, value } );
    if( result ) return result;
    setOpenAddPhone( false );
    setOpenAddEmail( false );
    window.location.reload();
  }, [ profileId ] );

  const onDeleteContactMethod = useCallback( async ( id: string ) => {
    await deleteContactMethod( profileId, id );
    window.location.reload();
  }, [ profileId ] );

  const onUnsubscribeAllContactMethods = useCallback( async () => {
    await unsubscribeAllContactMethods( profileId );
    window.location.reload();
  }, [ profileId ] );

  const onMoveContactMethod = useCallback( async ( id: string, move: 'up' | 'down' ) => {
    if( !recipient ) return;
    await moveContactMethod( profileId, id, move );
    revalidator.revalidate();
  }, [ profileId, recipient ] );

  const onAddRecipient = useCallback( async ( recipient: PatientRecipientFormData ) => {
    const result = await addRecipient( profileId, recipient );
    if( result ) return result;
    setOpenAddRecipient( false );
    revalidator.revalidate();
  }, [ profileId ] );

  const onEditRecipient = useCallback( ( id: string ) => async ( recipient: PatientRecipientFormData ) => {
    const result = await updateRecipient( profileId, id, recipient );
    if( result ) return result;
    setOpenEditRecipient( '' );
    revalidator.revalidate();
    window.location.reload();
  }, [ profileId ] );


  const onDeleteRecipient = useCallback( async ( id: string ) => {
    await deleteRecipient( profileId, id );
    revalidator.revalidate();
  }, [ profileId ] );

  const onUnsubscribeAllRecipients = useCallback( async () => {
    await unsubscribeAllRecipients( profileId );
    revalidator.revalidate();
    window.location.reload();
  }, [ profileId ] );

  const IntroSection: FC = () => {
    return (
      <Grid item sx={{
        width: '100%',
        fontFamily: fontBody,
      }} >
        {appointments.length
          ? <Button
            variant='outlined'
            color='success'
            sx={{
              float: 'right',
              fontWeight: 'bold',
              fontSize: '1.1rem',
              marginTop: '-1rem',
            }}
            onClick={() => navigate( 'appointments' )}
          >Manage Appointments</Button>
          : <Button
            variant='outlined'
            color='success'
            sx={{
              float: 'right',
              fontWeight: 'bold',
              fontSize: '1.1rem',
              marginTop: '-1rem',
            }}
            onClick={() => navigate( 'appointments/locations' )}
          >
            Book Appointment
          </Button>

        }
        <p className='translated'>{recipient?.firstName},</p>

        {/* <p>Thank you for visiting us at {organization?.name}.</p> */}
        <p>
          Use this page to update your contact methods and authorized recipients.
          Of course, you can also opt out at any time.
        </p>

        {/* <Button variant="contained">My Settings</Button> */}
        {/* <Button>My Authorized Recipients</Button> */}

      </Grid>
    );
  }

  const BottomSection: FC = () => {

    const contactLink = useCallback( ( name: string, protocol: string, value?: string, formatted?: string ) => {
      if( !value ) return null;
      return <>{name} <Link underline='hover' href={`${ protocol }:${ value }`} className='translated' >{formatted}</Link></>;
    }, [] );

    const contactLinks = useMemo( () => {
      const { telephone: phone, email } = organization || {};
      if( !phone && !email ) return ( <span className='translated'>.</span> );
      return (
        <>
          {' by '}
          {contactLink( 'phone', 'tel', phone, renderPhoneNumber( phone ) )}
          {phone && email ? ' or ' : ''}
          {contactLink( 'email', 'mailto', email, email )}
          <span className='translated'>.</span>
        </>
      );
    }, [ contactLink ] );

    return (
      <>
        <p>
          If you have any questions or concerns, please
          don&apos;t hesitate to get in touch with
          us {contactLinks}
        </p>
      </>
    );
  }

  const commTypes: { label: string, field: CommunicationTypeFieldNames }[] = [
    { label: 'Appointment Reminders', field: 'disableAppointmentReminders' },
    { label: 'Real-Time Appointment Status', field: 'disableAppointmentRealTimeUpdates' },
    { label: 'Practitioner Communications', field: 'disablePractitionerCommunication' },
    { label: 'Pre-/Post-Op Care Instructions', field: 'disablePrePostOpCareInstructions' },
    { label: 'Pathology/Lab Updates', field: 'disableLabUpdates' },
    { label: 'Real-Time Office Alerts', field: 'disableOfficeCommunication' },
    { label: 'Feedback/Review Reminders', field: 'disableReviewReminders' },
    { label: 'Birthday Greetings', field: 'disableBirthdays' },
    { label: 'General Information', field: 'disableMarketingUpdates' },
  ];


  const StyledTableRow = styled( TableRow )( () => ( {
    [ `&.${ tableRowClasses.root }` ]: {
      // '& td, & th': { border: 0 },
      '& th': { border: 0 },
      // '& button': { color: 'hsl(0deg 0% 0% / 30%)' },
    },
    [ `&.${ tableRowClasses.hover }` ]: {
      '&:hover button': { color: 'hsl(0deg 0% 0% / 50%)' },
    },
  } ) );

  const StyledBaseTableCell = styled( TableCell )( ( { theme } ) => ( {
    [ `&.${ tableCellClasses.head }` ]: {
      backgroundColor: theme.palette.common.white,
      color: 'hsl( 0 0% 0% /26% )', //theme.palette.augmentColor,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontFamily: fontHeader,
    },
    [ `&.${ tableCellClasses.body }` ]: {
      fontSize: isXSmall ? 16 : 18,
      // paddingRight: '4em',
      fontFamily: fontBody,
    },
  } ) );

  const StyledTableCell = styled( StyledBaseTableCell )( ( /* {theme} */ ) => ( {
    [ `&.${ tableCellClasses.body }` ]: {
      paddingRight: isXSmall ? '2rem' : '4rem',
    },
  } ) );

  // email - EmailIcon
  // landline - voice  RecordVoiceOver
  // voip - voice Sms
  // mobile - voice Sms
  // other, invalid, prepaid - DeviceUnknown
  const TelecomOptionCapabilityIcons: FC<{ telecomOption?: TelecomOptionInterface, color?: SvgIconProps[ 'color' ] }> = ( { telecomOption = {}, color = 'action' } ) => {
    const { system, carrierLineType: lineType, value, transmitMethod } = telecomOption;
    const props = { color };
    if( system ) {
      if( system == 'email' ) return <Tooltip title="Email"><EmailIcon {...props} /></Tooltip>;
      if( system == 'fax' ) return <Tooltip title="Fax"><Fax {...props} /></Tooltip>;
      if( lineType ) {
        if( [ 'mobile', 'prepaid' ].includes( lineType ) ) {
          const annotated = recipient?.commChannelsFound?.annotatedTelecomOptions.find( a => a.value == value );
          const { transmitMethod: annotatedTransmitMethod } = annotated || {};
          if( transmitMethod == 'voice' || annotatedTransmitMethod == 'voice' ) return <Tooltip title="Voice"><RecordVoiceOver {...props} /></Tooltip>;
          // if( transmitMethod == 'sms' )
          return <Tooltip title="SMS Text"><Sms {...props} /></Tooltip>;
          // return (
          //   <>
          //     <Tooltip title="SMS Text"><Sms { ...props } /></Tooltip>
          //     <Tooltip title="Voice"><RecordVoiceOver sx={ { marginLeft: '0.5rem' } } { ...props } /></Tooltip>
          //   </>
          // );
        }
        if( [ 'voip', 'landline' ].includes( lineType ) ) return <Tooltip title="Voice"><RecordVoiceOver {...props} /></Tooltip>;
      }
    }
    // if( system ) {
    //   if( system == 'email' ) return <Tooltip title="Email"><EmailIcon {...props} /></Tooltip>;
    //   if( system == 'fax' ) return <Tooltip title="Fax"><Fax {...props} /></Tooltip>;
    //   if( lineType ) {
    //     if( [ 'mobile', 'prepaid' ].includes( lineType ) ) return <><Tooltip title="Voice"><RecordVoiceOver {...props} /></Tooltip> <Tooltip title="SMS Text"><Sms {...props} /></Tooltip></>;
    //     if( [ 'voip', 'landline' ].includes( lineType ) ) return <Tooltip title="Voice"><RecordVoiceOver {...props} /></Tooltip>;
    //   }
    // }
    return <Tooltip title="Voice"><HelpOutline {...props} /></Tooltip>;
  };

  const TelecomOptionLineType: FC<{ telecomOption?: TelecomOptionInterface }> = ( { telecomOption = {} } ) => {
    const { system, carrierLineType: lineType } = telecomOption;
    if( !system ) return null;
    if( lineType == 'other' ) return <>Landline</>;
    if( lineType == 'voip' ) return <>Landline</>;
    if( lineType ) return <>{capitalize( lineType )}</>;
    return <>{capitalize( system )}</>;
  };

  const TelecomOptionIcon: FC<{ telecomOption?: TelecomOptionInterface }> = ( { telecomOption = {} } ) => {
    const { system, carrierChecked: checked, carrierLineType: lineType } = telecomOption;
    if( !system ) return null;
    if( system == 'email' ) return <Tooltip title="Email"><EmailIcon /></Tooltip>;
    if( [ 'phone', 'sms' ].includes( system ) ) {
      if( checked ) {
        if( lineType == 'mobile' ) return <Tooltip title="Mobile"><PhoneIphone /></Tooltip>;
        if( lineType == 'prepaid' ) return <Tooltip title="Prepaid"><Smartphone /></Tooltip>;
        if( lineType == 'landline' ) return <Tooltip title="LandLine"><RotaryPhone /></Tooltip>;
        if( lineType == 'other' ) return <Tooltip title="Phone"><RotaryPhone /></Tooltip>;
        if( lineType == 'voip' ) return <Tooltip title="VOIP"><DialerSip /></Tooltip>;
      }
      return <Tooltip title="Unknown"><DeviceUnknown /></Tooltip>;
    }
    return null;
  };

  const TelecomOptionCheckbox: FC<{ telecomOption?: TelecomOptionInterface, onChanged: ( id: string | undefined, checked: boolean ) => void }> = ( { telecomOption = {}, onChanged } ) => {
    const { _id: id, system, value, forcedIgnore } = telecomOption;
    const formatted = system == 'phone' ? renderPhoneNumber( value ) : value || '';
    const getActiveLabel = ( active: boolean ) => active ? 'Active' : 'Not active';
    const [ tooltip, setTooltip ] = useState( getActiveLabel( !forcedIgnore ) );

    const onChange = useCallback<NonNullable<CheckboxProps[ 'onChange' ]>>( async ( _event, checked: boolean ) => {
      await activateContactMethod( profileId, telecomOption._id, checked );
      telecomOption.forcedIgnore = !checked;
      // revalidator.revalidate();
      setNotify( `${ checked ? 'Activated' : 'Deactivated' } '${ formatted }'` );
      setTooltip( getActiveLabel( checked ) );
      onChanged( id, checked );
    }, [ profileId, setNotify, telecomOption ] );

    return (
      <>
        <Tooltip title={tooltip} placement='right'>
          <Checkbox
            defaultChecked={!forcedIgnore}
            onChange={onChange}
          />
        </Tooltip>
      </>
    );
  }

  const ContactMethods: FC = () => {
    const [ checkedIds, setCheckedIds ] = useState<Set<string>>(
      new Set( recipient?.telecomOptions
        .filter( o => !o.forcedIgnore )
        .map( o => o._id )
        .filter( ( id ): id is string => typeof id == 'string' )
      ) );

    const onCheckboxChange = useCallback( ( id: string | undefined, checked: boolean ) => {
      if( !id ) return;
      const updated = new Set( checkedIds );
      updated[ checked ? 'add' : 'delete' ]( id );
      setCheckedIds( updated );
    }, [ checkedIds, setCheckedIds ] );

    return (
      <Grid item xs={12}>
        <Typography variant='h6' align='left'
          sx={{
            color: 'black',
            margin: 0,
            padding: 0,
            textTransform: 'uppercase',
            width: isXSmall ? '100%' : undefined,
            fontFamily: fontHeader,
          }}
        >
          Contact Methods
        </Typography>

        <TableContainer sx={{ width: isXSmall ? undefined : 'fit-content' }}>
          <Table size='small' padding='none' aria-label='contact methods table'>
            <TableHead>
              <StyledTableRow>

                <StyledBaseTableCell colSpan={2} scope='row' sx={{ width: '2em' }}>
                  Active: <span className='translated'>{checkedIds.size}</span> of <span className='translated'>{recipient?.telecomOptions.length}</span>
                </StyledBaseTableCell>


              </StyledTableRow>
            </TableHead>
            <TableBody>
              {recipient?.telecomOptions.map( ( row, idx ) => {
                if( row._id && deletedIds.includes( row._id || '' ) ) return;
                return (
                  <StyledTableRow
                    key={`row${ idx }`}
                    hover={true}
                  >

                    {/*
                        <StyledBaseTableCell scope='row' sx={{ width: '2em' }}>
                        {idx < 4 ? ( idx + 1 ) : null}
                        </StyledBaseTableCell>
                      */}
                    <StyledBaseTableCell scope='row' sx={{ width: '2em' }}>
                      <TelecomOptionCheckbox telecomOption={row} onChanged={onCheckboxChange} />
                    </StyledBaseTableCell>

                    <StyledTableCell scope='row' className='translated'>
                      {getFormattedValueOfTelecomOption( row )}
                    </StyledTableCell>

                    {/* !isXSmall && !isSmall && (
                      <StyledTableCell scope='row'>
                        {capitalize( row.use || '' )}
                      </StyledTableCell>
                    ) */ }

                    {!isXSmall && !isSmall && !isMedium && (
                      <>
                        { /*
                        <StyledBaseTableCell scope='row' sx={{ width: '2em' }}>
                          <TelecomOptionIcon telecomOption={row} />
                        </StyledBaseTableCell>
                        */}
                        <StyledTableCell scope='row' sx={{ width: '2em', whiteSpace: 'nowrap' }}>
                          <TelecomOptionLineType telecomOption={row} />
                        </StyledTableCell>

                        <StyledBaseTableCell scope='row' sx={{ width: '2em', whiteSpace: 'nowrap' }}>
                          <TelecomOptionCapabilityIcons telecomOption={row} />
                        </StyledBaseTableCell>
                      </>
                    )}

                    { /*
                    <TableCell scope='row' sx={{ width: '1em', paddingLeft: '2em' }}>
                      {idx == 0
                        ?
                        null
                        :
                        <Tooltip title='Move up' placement='left'>
                          <IconButton aria-label='preferred'
                            onClick={() => row._id && onMoveContactMethod( row._id.toString(), 'up' )}
                          >
                            <ExpandLess />
                          </IconButton>
                        </Tooltip>
                      }
                    </TableCell>

                    {!isXSmall && (
                      <TableCell scope='row' sx={{ width: '1em' }}>
                        {idx + 1 >= telecomOptions.length
                          ?
                          null
                          :
                          <Tooltip title='Move down' placement='left'>
                            <IconButton aria-label='preferred'
                              onClick={() => row._id && onMoveContactMethod( row._id.toString(), 'down' )}
                            >
                              <ExpandMore />
                            </IconButton>
                          </Tooltip>
                        }
                      </TableCell>
                    )}
                      */ }


                    <StyledBaseTableCell align='right'>
                      {row.dataSource != 'directEntry'
                        ? <>
                          <Tooltip title='Locked' placement='left'>
                            <IconButton aria-label='locked'
                              onClick={() => row._id && setOpenLockedContactMethod( row._id.toString() )}
                            >
                              <Lock />
                            </IconButton>
                          </Tooltip>

                          <ConfirmDialog
                            open={!!( row._id && openLockedContactMethod == row._id.toString() )}
                            title={( row.value ? `'${ row.system == 'phone' ? renderPhoneNumber( row.value ) : row.value }'` : 'Contact method' ) + ' is locked'}
                            message='In order to change or delete this contact method, you must contact your provider.'
                            confirmButton=''
                            cancelButton='Ok'
                            onClose={() => {
                              setOpenLockedContactMethod( '' );
                            }}
                            fullScreen={isXSmall}
                          />
                        </>


                        :
                        <>
                          <Tooltip title='Delete' placement='left'>
                            <IconButton aria-label='delete'
                              onClick={() => row._id && setOpenDeleteContactMethod( row._id.toString() )}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>

                          <ConfirmDialog
                            open={!!( row._id && openDeleteContactMethod == row._id.toString() )}
                            title={'Delete ' + ( row.value ? `'${ row.system == 'phone' ? renderPhoneNumber( row.value ) : row.value }'` : 'contact method' )}
                            message='Are you sure you want to delete this contact method?'
                            confirmButton='Delete'
                            onClose={( confirmed ) => {
                              if( confirmed && row._id ) {
                                onDeleteContactMethod( row._id.toString() );
                                // setDeletedIds( [ ...deletedIds, row._id.toString() ] );
                              }
                              setOpenDeleteContactMethod( '' );
                            }}
                            fullScreen={isXSmall}
                          />
                        </>
                      }
                    </StyledBaseTableCell>

                  </StyledTableRow>
                );
              } )}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid item sx={{ mt: '0.5em', mb: '1em' }}>
          <Button
            onClick={() => setOpenAddPhone( true )}
            className='addButton addPhoneButton'
            startIcon={ /* isXSmall ? <PhoneIcon /> : */ <AddIcon />}
          >
            {isXSmall ? 'Phone' : 'Add phone'}
          </Button>
          <AddPatientContactMethod
            open={openAddPhone}
            countryCode={regionCode}
            onClose={() => { setOpenAddPhone( false ); }}
            fullScreen={isXSmall}
            onSubmit={async ( data ) => {
              return onAddContactMethod( data.system as 'phone' | 'sms', data.value );
            }}
            isPhone
          />

          <Button
            onClick={() => setOpenAddEmail( true )}
            className='addButton addEmailButton'
            startIcon={ /* isXSmall ? <EmailIcon /> : */ <AddIcon />}
          >
            {isXSmall ? 'Email' : 'Add email'}
          </Button>
          <AddPatientContactMethod
            open={openAddEmail}
            countryCode={regionCode}
            onClose={() => { setOpenAddEmail( false ); }}
            fullScreen={isXSmall}
            onSubmit={async ( data ) => {
              return onAddContactMethod( 'email', data.value );
            }}

          />

          {( recipient?.telecomOptions || [] ).length > 0 &&
            <Button
              onClick={() => setOpenUnsubscribeAllContactMethods( true )}
              className='addButton unsubscribeAllButton'
              color='error'
              startIcon={!isXSmall ? <Unsubscribe /> : null}
            >
              Unsubscribe all
            </Button>
          }
          <ConfirmDialog
            open={openUnsubscribeAllContactMethods}
            title={'Unsubscribe all contact methods'}
            message='Are you sure you want to unsubscribe all contact methods?'
            confirmButton='Unsubscribe all'
            onClose={( confirmed ) => {
              if( confirmed ) {
                onUnsubscribeAllContactMethods();
              }
              setOpenUnsubscribeAllContactMethods( false );
            }}
            fullScreen={isXSmall}
          />

        </Grid>

      </Grid>
    );
  }

  const AuthorizedRecipients: FC = () => {

    const getSubscriptionCount = useCallback( ( record: RecipientInterface ): string => {
      const total = commTypes.length;
      const active = commTypes.reduce( ( tot, c ) => tot - ( record[ c.field ] ? 1 : 0 ), commTypes.length );
      return active == total ? `All ${ total }` : active === 0 ? 'None' : `${ active } of ${ total }`;
    }, [ commTypes ] );

    const TelecomPreferenceIcon: FC<{ record: RecipientInterface, color?: SvgIconProps[ 'color' ] }> = ( { record, color = 'action' } ) => {
      const phoneOption = record.telecomOptions.find( o => [ 'phone', 'sms' ].includes( o.system || '' ) );
      const emailOption = record.telecomOptions.find( o => o.system == 'email' );
      const preference = !phoneOption?.value || emailOption?.rank == 1 ? 'email' : phoneOption?.system == 'sms' ? 'sms' : 'phone';
      const props = { color };
      if( preference == 'email' ) return <Tooltip title="Email"><EmailIcon {...props} /></Tooltip>;
      if( preference == 'sms' ) return <Tooltip title="SMS Text"><Sms {...props} /></Tooltip>;
      if( preference == 'phone' ) return <Tooltip title="Voice"><RecordVoiceOver {...props} /></Tooltip>;
      return <Tooltip title="Voice"><HelpOutline {...props} /></Tooltip>;
    };


    return (
      <Grid item xs={12}>
        <Typography variant='h6' align='left'
          sx={{
            color: 'black',
            margin: 0,
            padding: 0,
            textTransform: 'uppercase',
            width: isXSmall ? '100%' : undefined,
            fontFamily: fontHeader,
          }}
        >
          authorized recipients <span className='translated'>{recipients.length > 0 ? `(${ recipients.length })` : ''}</span>
        </Typography>

        <TableContainer sx={{ width: isXSmall ? undefined : 'fit-content' }}>
          <Table size='small' padding='none' aria-label='authorized recipients table'
          >
            <TableHead sx={{ display: 'none' }}>
              <StyledTableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align='left'>Value</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {recipients.map( ( row, idx ) => {
                if( row._id && deletedIds.includes( row._id.toString() ) ) return;

                return (
                  <StyledTableRow key={`row${ idx }`} hover={true} >

                    <StyledTableCell scope='row' className='translated'>
                      {row.fullName}
                    </StyledTableCell>


                    <StyledTableCell scope='row'>

                      <Typography component='span' className='translated'>
                        {getFormattedValueOfTelecomOption( row.telecomOptions[ 0 ] )
                        }
                      </Typography >
                      <Typography component='span' ml={row.telecomOptions[ 0 ]?.value ? 2 : undefined} className='translated'>
                        {getFormattedValueOfTelecomOption( row.telecomOptions[ 1 ] )}
                      </Typography >
                    </StyledTableCell>

                    <StyledBaseTableCell scope='row' sx={{ width: '3em' }}>
                      <TelecomPreferenceIcon record={row} />
                    </StyledBaseTableCell>

                    {/*
                      <StyledTableCell scope='row' >
                      {getFormattedValueOfTelecomOption( row.telecomOptions[ 0 ] )}
                      </StyledTableCell>

                      <StyledTableCell scope='row'>
                      {getFormattedValueOfTelecomOption( row.telecomOptions[ 1 ] )}
                      </StyledTableCell>
                    */}
                    {/*
                      <StyledBaseTableCell scope='row' sx={{ width: '2em' }}>
                      <TelecomOptionIcon telecomOption={row.telecomOptions[ 0 ]} />
                      </StyledBaseTableCell>

                      <StyledTableCell scope='row' sx={{ width: '2em' }}>
                      <TelecomOptionLineType telecomOption={row.telecomOptions[ 0 ]} />
                      </StyledTableCell>

                      <StyledBaseTableCell scope='row' sx={{ whiteSpace: 'nowrap', border: '0px red solid', paddingRight: '1em' }}>
                      <TelecomOptionCapabilityIcons telecomOption={row.telecomOptions[ 0 ]} color='disabled' />
                      </StyledBaseTableCell>
                    */}

                    <StyledBaseTableCell scope='row'>
                      <Tooltip title='Edit' placement='right' >
                        <IconButton aria-label='edit'
                          onClick={() => setOpenEditRecipient( row._id.toString() )  /* TODO also make the row click open edit  */}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>


                      <EditPatientRecipient
                        open={openEditRecipient == row._id.toString()}
                        data={row}
                        countryCode={regionCode}
                        onClose={() => { setOpenEditRecipient( '' ); }}
                        fullScreen={isXSmall}
                        onSubmit={onEditRecipient( row._id.toString() )}
                      />

                    </StyledBaseTableCell>


                    <StyledBaseTableCell align='right'>
                      <Tooltip title='Delete' placement='left'>
                        <IconButton aria-label='delete'
                          onClick={() => setOpenDeleteRecipient( row._id.toString() )}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>

                      <ConfirmDialog
                        open={openDeleteRecipient == row._id.toString()}
                        title={'Delete ' + ( row.fullName ? `'${ row.fullName }'` : 'recipient' )}
                        message='Are you sure you want to delete this recipient ?'
                        confirmButton='Delete'
                        onClose={( confirmed ) => {
                          if( confirmed && row._id ) {
                            onDeleteRecipient( row._id.toString() );
                            setDeletedIds( [ ...deletedIds, row._id.toString() ] );
                          }
                          setOpenDeleteRecipient( '' );
                        }}
                        fullScreen={isXSmall}
                      />

                    </StyledBaseTableCell>

                  </StyledTableRow>
                );
              } )}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid item sx={{ mt: '0.5em', mb: '1em' }}>
          <Button
            onClick={() => setOpenAddRecipient( true )}
            // disabled={ index <= 0 }
            className='addButton addRecipientButton'
            startIcon={<AddIcon />}
          >
            {isXSmall ? 'Recipient' : 'Add recipient'}
          </Button>

          {recipients.length > 0 &&
            <Button
              onClick={() => setOpenUnsubscribeAllRecipients( true )}
              className='addButton unsubscribeAllButton'
              color='error'
              startIcon={!isXSmall ? <Unsubscribe /> : null}
            >
              Unsubscribe all
            </Button>
          }
          <ConfirmDialog
            open={openUnsubscribeAllRecipients}
            title={'Unsubscribe all recipients'}
            message='Are you sure you want to unsubscribe all recipients?'
            confirmButton='Unsubscribe all'
            onClose={( confirmed ) => {
              if( confirmed ) {
                onUnsubscribeAllRecipients();
              }
              setOpenUnsubscribeAllRecipients( false );
            }}
            fullScreen={isXSmall}
          />

          <AddPatientRecipient
            open={openAddRecipient}
            countryCode={regionCode}
            onClose={() => { setOpenAddRecipient( false ); }}
            fullScreen={isXSmall}
            onSubmit={onAddRecipient}
          />


        </Grid>


      </Grid >
    );
  }

  const SimpleSnackbar: FC<{ open: boolean, onClose: () => void, message: string }> = ( props ) => {
    const { open, onClose, message } = props;

    return (
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          severity='success'
          variant='filled'
        >
          {message}
        </Alert>
      </Snackbar>
    );
  }


  const SelectedCommunications: FC = () => {
    const onChange = useCallback( ( commType: typeof commTypes[ number ] ): CheckboxProps[ 'onChange' ] => async ( _event, checked: boolean ) => {
      await updateCommunicationType( profileId, commType.field, !checked );
      recipient[ commType.field ] = !checked;
      // revalidator.revalidate();
      setNotify( `Saved ${ commType.label } ${ checked ? 'on' : 'off' }` );
    }, [ profileId, setNotify ] );

    if( !recipient ) return null;

    return (
      <Grid item sx={{ marginBottom: 4 }}>
        <Typography variant='h6' align='left'
          sx={{
            color: 'black',
            margin: 0,
            padding: 0,
            textTransform: 'uppercase',
            fontFamily: fontHeader,
          }}
        >
          Selected communications
        </Typography>

        {splitArray( commTypes, 2 ).map( ( commTypes, idx ) => (

          <TableContainer
            key={idx}
            sx={{ width: 'fit-content', display: 'inline-block', verticalAlign: 'top' }}
          >
            <Table size='small' padding='none' aria-label='selected communications table'
            >
              <TableBody>
                {commTypes.map( ( row, idx ) => {

                  return (
                    <StyledTableRow
                      key={`row${ idx }`}
                      hover={true}
                    >
                      <StyledBaseTableCell scope='row' >
                        <Checkbox
                          defaultChecked={!recipient[ row.field ]}
                          onChange={onChange( row )}
                        />
                      </StyledBaseTableCell>

                      <StyledTableCell scope='row'>
                        {row.label}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                } )}
              </TableBody>
            </Table>
          </TableContainer>
        ) )}
      </Grid >
    );
  }


  // Fix 'Error: Hydration failed because the initial UI does not match what was rendered on the server'
  const [ mounted, setMounted ] = useState( false );
  useEffect( () => setMounted( true ), [] );
  if( !mounted ) return null;

  if( !organization || !recipient ) return null;

  return (
    <Grid item container key='content'
      sx={{
        padding: isXSmall ? '1.5rem' : undefined,
        '& .MuiButton-text': {
          fontFamily: fontHeader,
        }
      }}
    >

      <IntroSection />

      {/* <PreferredContactMethods /> */}


      <ContactMethods />

      <SelectedCommunications />

      <AuthorizedRecipients />


      <BottomSection />
    </Grid>
  )
}
