import { AccessTime as HoursIcon, ContentCut as Scissors, DirectionsOutlined, Email as EmailIcon, EmojiPeople, Info as InfoIcon, Launch as WebIcon, LocalPharmacy, Phone as PhoneIcon, Restaurant, Search, ShoppingCart, Star, StarHalf, StarOutline, Train as TrainIcon } from '@mui/icons-material';
import { alpha, Avatar, Button, ButtonProps, Divider, IconButton, IconButtonProps, InputAdornment, Link as MuiLink, List, ListItem, ListItemIcon, ListItemText, Slide, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { TransitionProps } from '@mui/material/transitions';
import { dasherize } from 'inflection';
import { pick, reverse, sortBy } from 'lodash';
import { micromark } from 'micromark';
import { gfm, gfmHtml } from 'micromark-extension-gfm';
import { Dispatch, FC, forwardRef, HTMLAttributeAnchorTarget, ReactElement, Ref, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Link, Outlet, useLoaderData, useOutletContext, useParams, useRouteLoaderData } from 'react-router-dom';
import { amenityChoices, getCommonTagId } from './components/AmenityChoices';
import { Image, titleAlt } from './components/Image';
import { googleMapsUrlAddress, RideShareLinks, TransitLinks } from './components/navigation';
import { LocationsOutlet } from './Locs';
import { LocationData, Retail } from './profiles';
import { LocationInterface } from './types';
import { formatWeekdaysHours, hoursToday, isOpen } from './weekdays-hours';

export const Loc: FC = () => {
  const { location, organization, assets, commonTags, countryCode } = useLoaderData() as LocationData;
  const {
    fontBody, fontHeader, colorPrimary, colorSecondary,
    setTitle,
  } = useOutletContext() as LocationsOutlet;
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

  useEffect( () => setTitle( location.name ), [ location.name ] );

  const renderMarkdown = useCallback( ( markdown: string ): string => {
    return micromark( markdown.replaceAll( /\n\s+\n/g, '\n\n' ), {
      extensions: [ gfm() ],
      htmlExtensions: [ gfmHtml() ],
    } );
  }, [] );


  const contactTextProps = { '&, & span, & p': { fontFamily: fontBody, fontSize: '1.25rem' } };
  const ContactSection = !location
    ? null
    : (
      <>
        <Grid container direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between'
          sx={{ maxWidth: 600, margin: '0 auto' }}
        >
          {location.addressComponents?.formatted &&
            <Grid item >
              <MuiLink
                href={googleMapsUrlAddress( 'driving', `${ location.name }, ${ location.addressComponents.formatted }` )}
                target="_blank"
                rel="noopener"
                sx={{
                  textDecorationLine: 'none',
                }}
              >
                {!isXSmall &&
                  <IconButton sx={{ '& svg': { fontSize: '2.5rem', color: 'gray' } }} size='large' aria-label='Directions' >
                    <DirectionsOutlined /> </IconButton>
                }
                <Box sx={{ display: 'inline-block', verticalAlign: 'middle' }}>

                  {( location.addressComponents?.lines || [] ).map( ( line: string, idx: number ) => (
                    <Typography
                      sx={{ ...contactTextProps, fontWeight: 'bold', paddingTop: '2px', color: 'gray' }}
                      key={`line${ idx }`}
                    >
                      {line}
                    </Typography>
                  ) )}
                  {!location.addressComponents?.lines &&

                    <Typography
                      sx={{ ...contactTextProps, fontWeight: 'bold', paddingTop: '2px', color: 'gray' }}
                    >
                      {location.humanAddress} </Typography>
                  }
                </Box>
              </MuiLink>
            </Grid>
          }

          {formatWeekdaysHours( location.weekdaysHours ) &&
            <Grid item>
              {!isXSmall &&
                <IconButton sx={{ '& svg': { fontSize: '2.5rem', color: 'gray' } }} size='large' aria-label='Hours' >
                  <HoursIcon />
                </IconButton>
              }
              <Typography
                component='span'
                sx={{ ...contactTextProps, fontWeight: 'bold', paddingTop: '2px', color: 'gray' }}
              >

                {formatWeekdaysHours( location.weekdaysHours )}
              </Typography>
            </Grid>
          }

        </Grid>

        <Divider sx={{ marginTop: '0.5rem' }} />

        <Grid container justifyContent='space-between'
          sx={{ maxWidth: 400, margin: '0 auto' }}
        >
          {location.telephone &&
            <IconLabelButton label='Call' href={`tel: ${ location.telephone } `} >
              <PhoneIcon fontSize='large' />
            </IconLabelButton>
          }

          {location.email &&
            <IconLabelButton label='Email' href={`mailto: ${ location.email } `} >
              <EmailIcon />
            </IconLabelButton>
          }

          {location.webUrl &&
            <IconLabelButton label='Website' href={location.webUrl}
              target="_blank" rel="noopener"
            >
              <WebIcon />
            </IconLabelButton>
          }

        </Grid>

        <Divider />

        {/*
                             {location.email &&
                             <animated.div
                             id='abcd'
                             ref={animatedRef}
                             style={{
                             scale: x.to( {
                             range: [ 0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1 ],
                             output: [ 1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1 ].reverse().map( x => ( x - 1 ) * 0.4 + 1 ),
                             } ),
                             }}
                             >
                             <Link
                             component='div'
                             href={`mailto:${ location.email }?subject=${ encodeURIComponent( 'Book appointment' ) }&body=${ encodeURIComponent( 'I would like to book an appointment at ' + location.name ) }`}
                             target="_blank" rel="noopener"
                             sx={{
                             textDecorationLine: 'none',
                             }}
                             >
                             <Grid container direction='row' alignItems='center'>
                             <Grid item>
                             <IconButton
                             sx={{ ...contactIconProps }}
                             >
                             <CalendarMonth />
                             </IconButton>
                             </Grid>
                             <Grid item>
                             <Typography
                             component='div'
                             sx={{ ...contactTextProps, fontWeight: 'bold', display: 'inline-flex' }}
                             >
                             Book an appointment
                             </Typography>
                             </Grid>
                             </Grid>
                             </Link>
                             </animated.div>
                             }
                           */}
      </>
    );



  return (
    <div id='location'>

      <Grid item container direction='row' justifyContent='space-between' p='0.5rem' mb={2}>
        <Grid item sx={{ width: '100%' }}>
          {ContactSection}
        </Grid>
      </Grid>
      <Outlet
        context={{
          location, organization, assets, commonTags, countryCode,
          fontBody, fontHeader, colorPrimary, colorSecondary,
          setTitle,

        }}
      />
    </div>
  );
}

export const nearbySections = [
  { id: 'pharmacies', label: 'Pharmacies', icon: <LocalPharmacy /> },
  { id: 'restaurants', label: 'Dining', icon: <Restaurant /> },
  { id: 'shops', label: 'Shops', icon: <ShoppingCart /> },
  { id: 'salons', label: 'Salons', icon: <Scissors /> },
];
export const nearbySectionsMap = Object.fromEntries<( typeof nearbySections )[ number ]>( nearbySections.map( s => [ s.id, s ] ) );

export interface LocationOutletProps extends LocationData {
  setTitle: Dispatch<SetStateAction<string>>;
  fontBody: string;
  fontHeader: string;
  colorPrimary: string;
  colorSecondary: string;
}



export const LocIndex: FC = () => {
  // const { location, organization, assets, commonTags, countryCode } = useRouteLoaderData( 'location' ) as LocationData;
  const {
    location, organization, assets, commonTags,
  } = useOutletContext() as LocationOutletProps;
  const { amenityTags = [] } = organization;

  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

  const assetsSorted = sortBy( assets, asset => !asset.tags.includes( location.id ) ); // order Location tagged assets first
  const choices = amenityChoices.map( c => pick( c, [ 'id', 'name', 'icon', 'tag' ] ) )
    .filter( choice => amenityTags.length == 0 || amenityTags.includes( choice.id ) )
    .map( choice => {
      const asset = assetsSorted.find( asset => asset.tags.includes( choice.id ) );
      return { ...choice, ...asset };
    } )
    .filter( ( r ) => r.body && r.name );
  const descriptionAsset = ( assetsSorted ).find( asset => ( asset.tags || [] ).includes( getCommonTagId( 'banner' ) ) );
  const commonTagNames = commonTags.filter( tag => ( location.tags || [] ).includes( tag.id ) ).map( t => t.name );
  const latLng = location.lat && location.lng ? [ location.lat, location.lng ].join( ',' ) : undefined;
  const commonAssets: Record<string, { icon: ReactElement, body?: ReactElement }> = {
    'Ride Share': {
      icon: <EmojiPeople />,
      body: location.humanAddress && latLng ?
        <RideShareLinks dropoff={location.humanAddress} latLng={latLng} />
        : undefined,
    },
    'Public Transit': {
      icon: <TrainIcon />,
      body: location.humanAddress && latLng ?
        <TransitLinks dropoff={location.humanAddress} latLng={latLng} />
        : undefined
    },
  };


  const buildMapUrl = useCallback( ( location: LocationInterface ): string => {
    if( !location.humanAddress ) return '';
    // src="https://maps.google.com/maps?q=5950%20S%20Cooper%20Road%20Suite%204%20Chandler%2C%20Arizona%2085249&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" title="5950 S Cooper Road Suite 4 Chandler, Arizona 85249"

    const mapEmbedBase = 'https://www.google.com/maps/embed/v1/place';
    const mapEmbedOptions = new URLSearchParams( {
      key: 'AIzaSyAk5MsiefJopKk6Im5NYTnXhHfLR_4E1OM',
      q: [ location.name, location.humanAddress ].join( '\n' ),
      zoom: '16',
    } );
    return mapEmbedBase + '?' + mapEmbedOptions.toString();

  }, [] );


  return (
    <Grid item container
      direction={isXSmall ? 'column' : 'row'}
      alignItems={isXSmall ? 'center' : 'flex-start'}
      justifyContent='center'
      spacing={6}
      sx={{
        width: '100%',
        // border: '2px solid blue',
      }}
    >
      <Grid item
      // xs={isXSmall ? 10 : 6}
      // xs={12}
      >
        {location?.humanAddress &&
          <iframe
            width='400'
            height='400'
            loading="lazy"
            src={buildMapUrl( location )}
            title={location.name}
            aria-label="5950 S Cooper Road Suite 4 Chandler, Arizona 85249"
            style={{
              border: `1px solid ${ theme.palette.divider }`,
              marginTop: '1em',
              borderRadius: '0.5em',
            }}
          ></iframe>
        }
      </Grid>

      <Grid item
        key='aaa'
      // xs={isXSmall ? 10 : 6}
      // md={6}
      >

        <List dense
          sx={{
            width: '100%',
            // border: '2px solid blue',
            '& .MuiListItem-root:hover': {
              backgroundColor: alpha( theme.palette.text.primary, 0.04 ),
            },
          }}
        >
          {descriptionAsset?.body &&
            <Link
              key='about-us'
              style={{
                textDecorationLine: 'none',
                color: 'inherit',
              }}
              to={`about-us`}
            >
              <ListItem
                sx={{
                  color: 'inherit',
                  '& .MuiListItemIcon-root': {
                    minWidth: 40,
                  }
                }}
              >
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText
                  primary='About Us'
                  primaryTypographyProps={{ variant: 'h6' }}
                // secondary={secondary ? 'Secondary text' : null}
                />
              </ListItem>
            </Link>
          }


          {commonTagNames.map( label => {
            const r = commonAssets[ label ];
            const tag = dasherize( label.toLowerCase() );
            if( !( r?.body ) ) return null;
            return (
              <Link
                key={label}
                style={{
                  textDecorationLine: 'none',
                  color: 'inherit',
                }}
                to={`amenities/${ tag }`}
              >
                <ListItem
                  key={label}
                  sx={{
                    '& .MuiListItemIcon-root': {
                      minWidth: 40,
                    }
                  }}
                >
                  {/* <ListItemAvatar > <Avatar > {r?.icon} </Avatar> </ListItemAvatar> */}

                  <ListItemIcon> {r?.icon} </ListItemIcon>
                  <ListItemText
                    primary={label}
                    primaryTypographyProps={{ variant: 'h6' }}
                  // secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>
              </Link>
            );
          } )
          }


          {choices.map( r => {
            if( !( r?.id && r?.body && r?.name ) ) return null;
            const { id, subject, name, tag } = r
            const label = subject || name;
            // const tag = dasherize( name.toLowerCase() );
            return (
              <Link
                key={id}
                style={{
                  textDecorationLine: 'none',
                  color: 'inherit',
                }}
                to={`amenities/${ tag }`}
              >
                <ListItem
                  key={label}
                  sx={{
                    '& .MuiListItemIcon-root': {
                      minWidth: 40,
                    }
                  }}
                >
                  {/* <ListItemAvatar > <Avatar > {r?.icon} </Avatar> </ListItemAvatar> */}

                  <ListItemIcon > {r?.icon} </ListItemIcon>
                  <ListItemText
                    primary={label}
                    // secondary={secondary ? 'Secondary text' : null}
                    primaryTypographyProps={{ variant: 'h6' }}
                  />
                </ListItem>
              </Link>

            )
          } )}


          <Grid container mt={2} pl={2}>
            Nearby
          </Grid>

          {nearbySections.map( ( { id, label, icon } ) => {

            return (
              <Link
                key={id}
                style={{
                  textDecorationLine: 'none',
                  color: 'inherit',
                }}
                to={`nearby/${ id }`}
              >
                <ListItem
                  key={label}
                  sx={{
                    '& .MuiListItemIcon-root': {
                      minWidth: 40,
                    }
                  }}
                >
                  {/* <ListItemAvatar > <Avatar > {r?.icon} </Avatar> </ListItemAvatar> */}

                  <ListItemIcon > {icon} </ListItemIcon>
                  <ListItemText
                    primary={`Nearby ${ label }`}
                    // secondary={secondary ? 'Secondary text' : null}
                    primaryTypographyProps={{ variant: 'h6' }}
                  />
                </ListItem >

              </Link>
            )
          } )}

        </List>
      </Grid>
    </Grid >
  );
}

const sluggerize = ( s?: string ): string | undefined => {
  if( !s ) return;
  return dasherize( s.toLowerCase().replaceAll( /[^a-z0-9]+/g, ' ' ) );
}

export const LocNearbyDirectory: FC = () => {
  const { directoryId: id } = useParams();
  const { fontHeader, colorSecondary } = useOutletContext() as LocationOutletProps;
  const records = useRouteLoaderData( 'loc-nearbys' ) as Retail[];
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

  const [ tag, _setTag ] = useState<string>( 'Any' );
  const [ openType, _setOpenType ] = useState<'openNow' | 'all'>( 'all' );
  const [ sort, setSort ] = useState<'distance' | 'rating'>( 'distance' );
  const [ search, setSearch ] = useState( '' );

  const filtered = useMemo<Retail[]>( () => {
    const sorted = sort == 'rating' ? reverse( sortBy( records, r => r.rating ) ) : records;
    const openFirstSorted = [
      ...sorted.filter( r => isOpen( r.weekdaysHours ) ),
      ...sorted.filter( r => !isOpen( r.weekdaysHours ) ),
    ];
    return openFirstSorted // sorted
      .filter( r => openType == 'all' || isOpen( r.weekdaysHours ) )
      .filter( r => !search || r.name.toLowerCase().includes( search.toLowerCase() ) );
  }, [ records, tag, openType, sort, search ] );

  // // Restaurants only, add categories for other types later
  // const tags = [ 'American', 'French', 'Japanese', 'America', 'Frenc', 'Japanes', 'Ameri', 'Fre', 'Japan' ];

  if( !id ) return null;

  return (
    <Stack
      sx={{
        minHeight: 680,
      }}
    >
      <Typography variant='h1'
        align={isXSmall ? 'center' : 'left'}
        style={{
          fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
          backgroundColor: colorSecondary,
          color: 'white',
          width: '100%',
          padding: '0.5rem 1.0rem',
          fontWeight: 'bold', //'600',
          fontSize: 'x-large',
          fontSmooth: 'always',
        }}
      >
        {nearbySectionsMap[ id ].label}
        { /* transparent button used to match height of Location header bar */}
      </Typography>

      { /* id == 'restaurants' &&
         <ToggleButtonGroup
         value={tag}
         exclusive
         color='primary'
         aria-label='Open'
         size='small'
         fullWidth
         onChange={( _event, value ) => { setTag( value ) }}
         sx={{
         marginTop: 2,
         marginBottom: 2,
         // '& .MuiToggleButtonGroup-root': {
         overflowX: 'scroll',
         // }
         }}
         >
         <ToggleButton key='Any' value='Any'>Any</ToggleButton>
         {tags.map( tag => <ToggleButton key={tag} value={tag}>{tag}</ToggleButton> )}
         </ToggleButtonGroup>
       */ }
      {filtered.length > 10 &&
        <TextField
          id='seach'
          size='small'
          fullWidth
          variant='filled'
          // margin='dense'
          placeholder='Search'
          label={false}
          type='search'
          InputProps={{
            startAdornment: <InputAdornment position="start" ><Search /> </InputAdornment>
          }}
          onChange={( e ) => { setSearch( e.target.value ); }}
        // sx={{
        //   '& .MuiFilledInput-root': {
        //     borderRadius: 2,
        //   }
        // }}
        />
      }

      {/*
          <ToggleButtonGroup
          value={openType}
          exclusive
          color='primary'
          aria-label='Open'
          size='small'
          fullWidth
          onChange={( _event, value ) => { setOpenType( value ) }}
          sx={{
          marginTop: 2,
          marginBottom: 2,
          }}
          >
          <ToggleButton value="openNow">Open Now</ToggleButton>
          <ToggleButton value="all">All</ToggleButton>
          </ToggleButtonGroup>
        */}

      <ToggleButtonGroup
        value={sort}
        exclusive
        color='primary'
        aria-label='Sort'
        size='small'
        fullWidth
        onChange={( _event, value ) => { setSort( value ) }}
        sx={{
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        <ToggleButton key="rating" value="rating">Rating</ToggleButton>
        <ToggleButton key="distance" value="distance" >Distance</ToggleButton>
      </ToggleButtonGroup>


      < Grid container >

        {
          filtered.map( r => (
            <Link
              to={[ sluggerize( r.name ), sluggerize( r.address ), r.id ].filter( a => a ).join( '/' )}
              key={r.id}
              style={{
                color: 'inherit',
                textDecorationLine: 'none'
              }}
            >
              <LocNearbyDirectoryItem key={r.id} record={r} />
            </Link>
          ) )
        }


      </Grid >
    </Stack >
  );

  // return (
  //   <>
  //   <h3>
  //   Nearby {id}
  //   </h3>
  //   <NearbyDirectory id={id!} records={records} handleClickOpen={() => () => { null }} />
  //   <pre>
  //   {( records || [] ).map( n => (
  //     <Typography>
  //     {n.name}
  //           </Typography>
  //         </Link>
  //       ) )}
  //     </pre>
  //   </>
  // );
}


export interface LocNearbyDirectoryItemProps {
  record: Retail;
}

export const LocNearbyDirectoryItem: FC<LocNearbyDirectoryItemProps> = props => {
  const { record: r } = props;
  const theme = useTheme();

  return (

    <Grid item container
      key={r.id}
      sx={{
        borderTop: `1px solid ${ theme.palette.divider }`,
        paddingTop: 1,
        paddingBottom: 1,
        marginBottom: 1,
        '& .MuiAvatar-rounded': {
          borderRadius: 4,
        }
      }}
    >
      <Grid item container>
        <Grid item container direction='row' justifyContent='space-between'>
          <Grid item><StarRating rating={r.rating} /></Grid>
          { /* <Grid item><Typography>{r.distance || ''} </Typography></Grid> */}
          {/* <Grid item><Typography>$$$</Typography></Grid>  */}
        </Grid>

        <Grid item container direction='row' spacing={1} justifyContent='flex-start' alignItems='center' >
          {r.images && r.images.length > 0 &&
            <Grid item xs='auto' >
              <Avatar
                alt={r.name}
                src={`https://gunter.analoginformation.com/retail-api/v1/image/${ r.images[ 0 ] }`}
                sx={{ width: 120, height: 120, marginRight: 2 }}
                variant='rounded'
                imgProps={{
                  'aria-label': `image`,
                  'aria-describedby': `${ r.name } image`,
                }}
              />
            </Grid>
          }
          <Grid item container direction='column' xs={true} justifyContent='space-between'>
            <Grid item container direction='column' className="translated">
              <Grid item xs='auto'>
                <Typography variant='h5'>{r.name}</Typography>
              </Grid >

              <Grid item xs='auto'>
                <Typography>{r.address}</Typography>
              </Grid>

            </Grid>
            <Grid item>
              <Typography align='left'>
                {hoursToday( r.weekdaysHours )}
              </Typography>

              <Grid item><Typography className="translated" >{r.distance || ''} </Typography></Grid>

            </Grid>
          </Grid>
        </Grid>
      </Grid >
    </Grid >

  );
}



export const LocNearbyDetail: FC = () => {
  const { directoryId, nearbyId } = useParams();
  const nearbys = useRouteLoaderData( 'loc-nearbys' ) as Retail[];
  const record = useMemo( () => {
    return ( nearbys || [] ).find( n => n.id == nearbyId );
  }, [ nearbys, nearbyId ] );
  const { fontHeader, colorSecondary } = useOutletContext() as LocationOutletProps;

  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

  if( !record || !directoryId || !nearbyId ) return null;

  return (
    <Stack>
      <Link to='..' style={{ textDecorationLine: 'none', paddingBottom: '1rem' }} >
        <Typography variant='h1'
          align={isXSmall ? 'center' : 'left'}
          style={{
            fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
            backgroundColor: colorSecondary,
            color: 'white',
            width: '100%',
            padding: '0.5rem 1.0rem',
            fontWeight: 'bold', //'600',
            fontSize: 'x-large',
            fontSmooth: 'always',
          }}
        >
          {nearbySectionsMap[ directoryId ].label}
        </Typography>
      </Link>

      <Grid item container
        key={record.id}
        direction='column'
        justifyContent='flex-start'
        alignItems='center'
        sx={{
          '& .MuiAvatar-rounded': {
            borderRadius: 4,
          },
          minHeight: 680,
        }}
      >

        <Grid item container key='distance' direction='row' justifyContent='space-between'>
          <Grid key="rating" item><StarRating rating={record.rating} /></Grid>
          {/* <Grid item><Typography>$$$</Typography></Grid> */}
          <Grid key="distance" item><Typography>{record.distance || ''} </Typography></Grid>
        </Grid>

        <Grid item key='name'>
          <Typography variant='h4'>
            {record.name}
          </Typography>
        </Grid>

        {record.images && record.images.length > 0 &&
          <Carousel key='images'
            sx={{
              width: 300
            }}
          >
            {record.images.map( ( image, idx ) => (
              <Box
                key={idx}
                sx={{
                  width: 300
                }}
              >
                <Avatar
                  alt={record.name}
                  key={image}
                  src={`https://gunter.analoginformation.com/retail-api/v1/image/${ image }`}
                  sx={{ width: '300px', height: '300px' }}
                  variant='rounded'
                // imgProps={{
                //   'ariaLabel': 'image',
                //   'ariaDescribedby': `${ r.name } image`
                // }}
                />
              </Box>
            ) )}
          </Carousel>

        }

        <Grid container
          key='contact'
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          justifyContent='space-between'
          sx={{ maxWidth: 600, margin: '0 auto' }}
        >
          {record.address &&
            <MuiLink
              href={googleMapsUrlAddress( 'driving', `${ record.name }, ${ record.address }` )}
              target="_blank"
              rel="noopener"
              sx={{
                textDecorationLine: 'none',
              }}
            >
              <Grid item container direction='row' justifyContent='flex-start' alignItems='center' >

                <Grid item xs='auto'>
                  <IconButton sx={{ '& svg': { fontSize: '2.5rem', color: 'gray' } }} size='large' aria-label='directions' >
                    <DirectionsOutlined />
                  </IconButton>
                </Grid>
                <Grid item xs={true}>
                  <Typography className='translated'
                    sx={{ fontWeight: 'bold', paddingTop: '2px', color: 'gray' }}
                  >
                    {record.address}
                  </Typography>

                </Grid>
              </Grid>
            </MuiLink>
          }

          {formatWeekdaysHours( record.weekdaysHours ) &&
            <Grid item container direction='row' justifyContent='flex-start' alignItems='fiex-start' >

              <Grid item xs='auto'>
                <IconButton sx={{ '& svg': { fontSize: '2.5rem', color: 'gray' } }} size='large' aria-label='hours'>
                  <HoursIcon />
                </IconButton>
              </Grid>
              <Grid item xs={true}>
                <Typography >
                  {hoursToday( record.weekdaysHours )}
                </Typography>
                <Typography
                  component='span'
                  sx={{ fontWeight: 'bold', paddingTop: '2px', color: 'gray' }}
                >

                  {formatWeekdaysHours( record.weekdaysHours )}
                </Typography>
              </Grid>
            </Grid>
          }

        </Grid>

        <Divider sx={{ marginTop: '0.5rem' }} />

        <Grid container
          // justifyContent={{
          //   xs: 'space-between'
          // }}
          columnSpacing={4}
          alignItems='flex-start'
          direction='row'
          sx={{ maxWidth: 600, margin: '0 auto' }}
        >
          {record.phone &&
            <Grid item>
              <IconLabelButton label='Call' href={`tel: ${ record.phone } `} >
                <PhoneIcon fontSize='large' />
              </IconLabelButton>
            </Grid>
          }

          {record.website &&
            <Grid item>
              <IconLabelButton label='Website' href={record.website}
                target="_blank" rel="noopener"
              >
                <WebIcon />
              </IconLabelButton>
            </Grid>
          }

        </Grid>

        <Divider sx={{ marginTop: '0.5rem' }} />

        <Grid container
          justifyContent='space-between'
          alignItems='flex-start'
          direction='row'
          sx={{ maxWidth: 600, margin: '0 auto' }}
        >

          {!!record.addressComponents?.lines[ 0 ] && record.location.coordinates.length >= 2 &&
            <RideShareLinks dropoff={record.addressComponents.lines[ 0 ]} latLng={record.location.coordinates.slice( 0, 2 ).join( ',' )} />
          }
        </Grid>

      </Grid >

    </Stack>
  );
}

export const LocAbout: FC = () => {
  const { location, assets, fontHeader, colorSecondary } = useOutletContext() as LocationOutletProps;
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );
  const assetsSorted = sortBy( assets, asset => !asset.tags.includes( location.id ) ); // order Location tagged assets first
  const descriptionAsset = ( assetsSorted ).find( asset => ( asset.tags || [] ).includes( getCommonTagId( 'banner' ) ) );

  const renderMarkdown = useCallback( ( markdown: string ): string => {
    return micromark( markdown.replaceAll( /\n\s+\n/g, '\n\n' ), {
      extensions: [ gfm() ],
      htmlExtensions: [ gfmHtml() ],
    } );
  }, [] );


  return (
    <>
      <Typography variant='h1'
        align={isXSmall ? 'center' : 'left'}
        style={{
          fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
          backgroundColor: colorSecondary,
          color: 'white',
          width: '100%',
          padding: '0.5rem 1.0rem',
          fontWeight: 'bold', //'600',
          fontSize: 'x-large',
          fontSmooth: 'always',
        }}
      >
        About us
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: renderMarkdown( descriptionAsset?.body || '' ) }} />

    </>
  );
}

export const LocRideShare: FC = () => {
  const { location, fontHeader, colorSecondary } = useOutletContext() as LocationOutletProps;
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );
  const latLng = location.lat && location.lng ? [ location.lat, location.lng ].join( ',' ) : undefined;

  if( !location?.humanAddress || !latLng ) return null;

  return (
    <>
      <Typography variant='h1'
        align={isXSmall ? 'center' : 'left'}
        style={{
          fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
          backgroundColor: colorSecondary,
          color: 'white',
          width: '100%',
          padding: '0.5rem 1.0rem',
          fontWeight: 'bold', //'600',
          fontSize: 'x-large',
          fontSmooth: 'always',
        }}
      >
        Ride Share
      </Typography>
      <RideShareLinks dropoff={location.humanAddress} latLng={latLng} />
    </>
  );
}

export const LocAmenity: FC = () => {
  const { tagId } = useParams();
  const { location, assets, fontHeader, colorSecondary } = useOutletContext() as LocationOutletProps;
  const theme = useTheme();
  const isSmall = useMediaQuery( theme.breakpoints.down( 'md' ) );
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

  const renderMarkdown = useCallback( ( markdown: string ): string => {
    return micromark( markdown.replaceAll( /\n\s+\n/g, '\n\n' ), {
      extensions: [ gfm() ],
      htmlExtensions: [ gfmHtml() ],
    } );
  }, [] );


  const assetsSorted = sortBy( assets, asset => !asset.tags.includes( location.id ) ); // order Location tagged assets first

  const amenity = amenityChoices.find( c => c.tag == tagId );
  if( !amenity ) return null;
  const { id, name } = amenity;
  const r = assetsSorted.find( asset => asset.tags.includes( id ) );

  if( !amenity || !r ) return null;
  if( !( r?.body && amenity?.name ) ) return null;
  const label = r.subject || amenity.name;


  return (
    <>
      <Typography variant='h1'
        align={isXSmall ? 'center' : 'left'}
        style={{
          fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
          backgroundColor: colorSecondary,
          color: 'white',
          width: '100%',
          padding: '0.5rem 1.0rem',
          fontWeight: 'bold', //'600',
          fontSize: 'x-large',
          fontSmooth: 'always',
        }}
      >
        {name}
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          padding: '1em',
          width: '100%',
        }}
        mt={2}
      >
        <Grid container spacing={2}>
          {( r.images || [] ).map( ( image ) => (
            <Grid item key={image} >
              {/* @ts-ignore:2322 */}
              <Image id="primaryimage" decoding='sync' fetchpriority='low'
                key={image}
                src={image}
                {...titleAlt( label )}
                width={isSmall ? '324' : '432'}     // '420' // {isXSmall ? 240 : 432} // '24em',
                height={isSmall ? '243' : '324'}    // '315' // {isXSmall ? 180 : 324} // '18em',
                fit='cover' position='north'
                loading='lazy'
                style={{
                  borderRadius: '1.2em',
                  boxShadow: '2px 2px 4px 2px rgb(0 0 0 / 20%)',
                }}
              />


            </Grid>
          ) )}

        </Grid>

        {r.body &&
          <Grid item xs={12} md={7} sx={{ width: '100%' }} key="text" >
            <div dangerouslySetInnerHTML={{ __html: renderMarkdown( r.body || '' ) }} />
          </Grid>
        }
      </Grid>

    </>
  );
}




export interface StarRatingProps {

  rating?: number;
  max?: number;
  threshold?: number;
}

export const StarRating: FC<StarRatingProps> = props => {
  const { rating = 0, max = 5, threshold = 0.25 } = props;

  const getIcon = ( i: number ) => {
    if( i <= Math.floor( rating ) ) {
      return <Star key={i} />;
    }
    if( i == Math.ceil( rating ) && i - rating < threshold ) {
      return <Star key={i} />;
    }
    if( i == Math.floor( rating ) + 1 && rating - ( i - 1 ) > threshold ) {
      return <StarHalf key={i} />;
    }
    return <StarOutline key={i} />;
  }

  return (
    <Box
    // aria-role='image'
    // aria-label='rating'
    // aria-describedby={`rating of ${ rating.toFixed( 1 ) } of ${ max } stars`}
    >
      {Array.from( Array( max + 1 ).keys() ).slice( 1 ).map( getIcon )}
    </Box>
  )

}


export const SlideTransition = forwardRef( function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
} );

export interface IconLabelButtonProps extends Omit<IconButtonProps, 'color' | 'classes'>, ButtonProps {
  // href?: ButtonProps[ 'href' ];
  rel?: string | undefined;
  target?: HTMLAttributeAnchorTarget | undefined;
  label: string;
}

export const IconLabelButton: FC<IconLabelButtonProps> = props => {
  const { children, label, sx = {}, ...rest } = props;

  return (
    <Button
      color='inherit'
      sx={{
        color: 'gray',
        '& svg': {
          fontSize: '3em',
          // margin: '0 auto',
        },
        ...sx
      }}
      {...rest}
    >
      <Box>
        <Box sx={{ margin: '0 auto', width: 'fit-content' }}>
          {children}
        </Box>
        <Box>
          <Typography sx={{ textTransform: 'initial', textAlign: 'center' }}>
            {label}
          </Typography>
        </Box>
      </Box>
    </Button>
  );
}

