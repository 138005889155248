import { Box, CircularProgress, Typography } from '@mui/material';
import { default as Smart } from 'fhirclient';
import SmartClient from 'fhirclient/lib/Client';
import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { getSmartConfiguration, SmartConfiguration } from './profiles';

export interface SmartProviderContext {
  client?: SmartClient;
  configuration?: SmartConfiguration;
}

export const SmartContext = createContext<SmartProviderContext>( {} );

export const useSmartContext = () => useContext( SmartContext );

interface SmartProviderProps {
  children: ReactNode;
}

export const SmartProvider: FC<SmartProviderProps> = ( { children } ) => {
  const [ smartClient, setSmartClient ] = useState<SmartClient | undefined>();
  const [ configuration, setConfiguration ] = useState<SmartConfiguration | undefined>();
  const { clientId } = configuration || {};

  useEffect( () => {
    if( !clientId ) return;
    // Smart.oauth2.ready().then( ( smartClient ) => setSmartClient( smartClient ) )
    Smart.oauth2.init( {
      clientId, // : 'lU97nuh34H_osFDvHc6rsP21uwbGYllzB-SAbzIXNd8', // 'smarthealthit1',
      redirectUri: '/app/patient',
      scope: 'launch launch/patient openid fhirUser',
      completeInTarget: true, // ???

    } ).then( ( smartClient ) => setSmartClient( smartClient ) )

      .catch( e => {
        console.error( e );
      } );

  }, [ setSmartClient, clientId ] );

  useEffect( () => {
    ( async () => {
      if( configuration ) return;
      const config = await getSmartConfiguration();
      setConfiguration( config );
    } )();
  }, [ configuration, setConfiguration ] );

  const context = {
    client: smartClient,
    configuration,
  }

  if( !configuration || ( clientId && !smartClient ) ) return <CircularProgress />
  //   return (
  //     <Box>
  //       <Typography>
  //         SmartProvider
  //       </Typography>
  //       <CircularProgress />
  //     </Box>
  //   );
  // }


  return (
    <SmartContext.Provider value={context}>
      {children}
    </SmartContext.Provider>
  );

}

