import { parsePhoneNumber } from 'awesome-phonenumber';
import { ArrowBackIosNew } from '@mui/icons-material';
import { Alert, Box, IconButton, Link as MuiLink, Snackbar, Table, TableBody, TableCell, TableRow, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { titleize } from 'inflection';
import { Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Outlet, ScrollRestoration, useLoaderData, useOutletContext } from 'react-router-dom';
import { PageFooter } from './components/page-footer';
import { LocBreadcrumbs } from './Locs';
import { OrganizationContext, ProfileContext } from './ProfileContainer';
import { AppointmentData } from './profiles';
import { OrganizationStyling, PractitionerInterface, RecipientInterface } from './types';

export const scrollDown = ( ref: MutableRefObject<HTMLDivElement | null> ) => {
  window.setTimeout( () => {
    ref?.current &&
      window.scrollTo( {
        top: ref.current.offsetTop,
        behavior: 'smooth',
      } );
  }, 200 );
}


export const prettyDate = ( date: Date | string | undefined, timeZone: string ): string => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleDateString( 'en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone } );
}

export const parseableDateTz = ( date: Date | string | undefined, timeZone: string ): string => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleDateString( 'en-US', { year: 'numeric', month: 'numeric', day: 'numeric', timeZone, timeZoneName: 'short' } );
}

export const prettyShortDate = ( date: Date | string | undefined, timeZone: string ): string => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleDateString( 'en-US', { weekday: 'short', year: undefined, month: 'short', day: 'numeric', timeZone } );
}

export const prettyTime = ( date: string | undefined, timeZone: string ) => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleTimeString( 'en-US', { timeStyle: 'short', timeZone } );
}

export const prettyTimeTz = ( date: string | undefined, timeZone: string ) => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleTimeString( 'en-US', { hour: 'numeric', minute: 'numeric', timeZoneName: 'short', timeZone } );
}

export const prettyDateTime = ( date: string | undefined, timeZone: string ) => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return [
    d.toLocaleDateString( 'en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone } ),
    d.toLocaleTimeString( 'en-US', { timeStyle: 'short', timeZone } )
  ].join( ' ' );
}

export const prettyShortDateTime = ( date: string | undefined, timeZone: string ) => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return [
    d.toLocaleDateString( 'en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', timeZone } ),
    d.toLocaleTimeString( 'en-US', { timeStyle: 'short', timeZone } )
  ].join( ' ' );
}

export const prettyTimezone = ( date: Date | string | undefined = new Date(), timeZone?: string ): string => {
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleTimeString( 'en-US', { hour: '2-digit', hour12: false, timeZoneName: 'long', timeZone } ).slice( 3 );
}

export const getProperName = ( names: Pick<PractitionerInterface, 'prefix' | 'fullName' | 'suffix'> ): string => {
  const { fullName, prefix, suffix } = names;
  return [ prefix, fullName, suffix ].filter( n => !!n ).join( ' ' )
}

export const SimpleConfirmSnackbar: FC<{ open: boolean, onClose: () => void, message: string }> = ( props ) => {
  const { open, onClose, message } = props;

  return (
    <Snackbar
      key='notify'
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        severity='success'
        variant='filled'
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export const SimpleErrorSnackbar: FC<{ open: boolean, onClose: () => void, message: string }> = ( props ) => {
  const { open, onClose, message } = props;

  return (
    <Snackbar
      key='error'
      open={open}
      autoHideDuration={10000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        severity='error'
        variant='filled'
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export const ProfileAppointmentContainer: FC = () => {
  const context = useOutletContext<ProfileContext>();
  return (
    <Outlet context={context} />
  );
}

export interface AppointmentContext extends OrganizationContext, AppointmentData {
  setStatus: Dispatch<SetStateAction<string>>;
  setStartTime: Dispatch<SetStateAction<string>>;
  status: string;
  startTime: string;
}

export const AppointmentContainer: FC = () => {
  const orgContext = useOutletContext<ProfileContext>();
  const { colorPrimary, colorSecondary, fontBody, fontHeader, setNotify, setError, setTitle, isXSmall, isSmall, isMedium, organization, assets, countryCode: regionCode = 'US' } = orgContext;
  const data = useLoaderData() as AppointmentData;
  const { appointment, patient, practitioner, location, commonTags } = data;

  const [ startTime, setStartTime ] = useState( '' );
  const [ status, setStatus ] = useState( '' );

  useEffect( () => {
    setStatus( appointment?.status || '' );
    setStartTime( appointment?.startTime || '' );
  }, [ appointment.status, appointment.startTime ] ); // let Outlets setStatus pending an update

  useEffect( () => setTitle( location.name ), [ location.name ] );

  const { appointmentTypeDisplay, timeZoneName } = appointment;

  const { firstName, lastName: lastInitial } = patient;
  const patientName = `${ firstName } ${ lastInitial }`;
  const { addressComponents: { lines: addressLines = [] } = {}, telephone: phone } = location;
  const telephone = phone ? parsePhoneNumber( phone, { regionCode } ).number?.national || phone : '';

  const context = {
    ...orgContext,
    appointment, patient, practitioner,
    location, organization, assets, commonTags,
    setStatus, setStartTime,
    status, startTime,
  };


  if( !data ) return null;

  return (
    <>
      <Typography gutterBottom fontSize='1.2rem' fontWeight='bold'
        sx={{
          fontFamily: fontHeader
        }}
      >
        Currently scheduled appointment
      </Typography>
      <Grid container alignItems='flex-start' columnSpacing={10} >
        <Grid item xs={'auto'}>
          <Table size='small' padding='none'
            sx={{
              '& .MuiTableCell-body': {
                fontSize: '1rem',
                borderBottomWidth: 0,
                paddingBottom: '1rem',
                paddingRight: '1rem',
                fontWeight: 'bold',
                verticalAlign: 'top',
                '&:first-of-type': {
                  fontWeight: 'inherit',
                  width: '6rem',
                  // color: 'red',
                },
              },
            }}
          >
            <TableBody>
              <TableRow >
                <TableCell>Patient</TableCell>
                <TableCell>{patientName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date </TableCell>
                <TableCell  >{prettyDate( startTime, timeZoneName )}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Time </TableCell>
                <TableCell  >{prettyTimeTz( startTime, timeZoneName )}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell>Status </TableCell>
                <TableCell>{titleize( status == 'booked' ? 'confirmed' : status )}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={'auto'}>
          <Table size='small' padding='none'
            sx={{
              '& .MuiTableCell-body': {
                fontSize: '1rem',
                borderBottomWidth: 0,
                paddingBottom: '1rem',
                paddingRight: '1rem',
                fontWeight: 'bold',
                verticalAlign: 'top',
                '&:first-of-type': {
                  fontWeight: 'inherit',
                  width: '6rem',
                  // color: 'red',
                },
              },
            }}
          >
            <TableBody>
              <TableRow >
                <TableCell>Type </TableCell>
                <TableCell>{appointmentTypeDisplay}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell>Practitioner </TableCell>
                <TableCell>{getProperName( practitioner )}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell>Location </TableCell>
                <TableCell >
                  {addressLines.map( ( address, idx ) => ( <Box key={idx}>{address}</Box> ) )}
                </TableCell>
              </TableRow>
              <TableRow >
                <TableCell>Phone </TableCell>
                <TableCell >
                  <Box key='phone'><MuiLink href={`tel:${ phone }`}>{telephone}</MuiLink></Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>


      <Outlet
        context={context}
      />

    </>

  );
}
