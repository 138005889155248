/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import { FormControl, FormHelperText, Radio, RadioGroup, Table, TableBody, TableContainer } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { validate as isEmail } from 'email-validator';
import { FC, useMemo } from 'react';
import { Controller, useForm, GlobalError } from 'react-hook-form';
import { splitArray } from '../Profile';
import { PatientRecipientFormData, ServerError, CommunicationTypeFieldNames, isPatientRecipientFormDataField } from '../profiles';
import { RecipientInterface, RecipientTypeEnum } from '../types';
import { normalizePhone, validatePhoneNumber } from './AwesomePhoneField';



export interface PatientRecipientFormProps {
  data: PatientRecipientFormData;
  countryCode: string;
  title?: string;
  open?: boolean;
  fullScreen?: boolean;
  onSubmit: ( data: PatientRecipientFormData ) => Promise<undefined | Record<keyof PatientRecipientFormData, ServerError>>;
  onClose?: () => void;
}

const formDataDefault: PatientRecipientFormData = {
  fullName: '',
  recipientType: RecipientTypeEnum.NON_PATIENT,
  phone: '',
  email: '',
  preference: 'phone',
  disableAppointmentReminders: false,
  disableAppointmentRealTimeUpdates: false,
  disableLabUpdates: false,
  disableOfficeCommunication: false,
  disablePractitionerCommunication: false,
  disablePrePostOpCareInstructions: false,
  disableBirthdays: false,
  disableMarketingUpdates: false,
  disableReviewReminders: false,
}

export type AddPatientRecipientProps = Omit<PatientRecipientFormProps, 'title' | 'data'>;

export const AddPatientRecipient: FC<AddPatientRecipientProps> = ( props ) => {
  const { ...rest } = props;
  return <PatientRecipientForm
    title='Add recipient'
    data={formDataDefault}
    {...rest}
  />
}

export interface EditPatientRecipientProps extends Omit<PatientRecipientFormProps, 'title' | 'data'> {
  data: RecipientInterface;
}

export const convertData = ( data: RecipientInterface ): PatientRecipientFormData => {
  const phoneOption = data.telecomOptions.find( o => [ 'phone', 'sms' ].includes( o.system || '' ) );
  const emailOption = data.telecomOptions.find( o => o.system == 'email' );
  return {
    ...data,
    // this assumes at most one of each
    phone: normalizePhone( 'US' )( phoneOption?.value || '' ),
    email: ( emailOption?.value || '' ).toLowerCase(),
    preference: !phoneOption?.value || emailOption?.rank == 1 ? 'email' : phoneOption?.system == 'sms' ? 'sms' : 'phone',
  };
}

export const EditPatientRecipient: FC<EditPatientRecipientProps> = ( props ) => {
  const { data, ...rest } = props;
  return <PatientRecipientForm
    title='Edit recipient'
    data={convertData( data )}
    {...rest} />
}

export const PatientRecipientForm: FC<PatientRecipientFormProps> = ( props ) => {
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );
  const { countryCode, onClose, onSubmit, data: defaultValues, open = true, fullScreen = false, title } = props;
  const { control, getValues, watch, handleSubmit, setError, clearErrors, formState: { errors, isDirty, isValidating, isValid, isSubmitted, isSubmitting } } = useForm<PatientRecipientFormData>( { defaultValues } );
  const watchPhone = watch( 'phone' );
  const watchEmail = watch( 'email' );
  const isAdd = useMemo<boolean>( () => {
    return defaultValues.fullName === '';
  }, [] );

  const commTypes: { label: string, field: CommunicationTypeFieldNames }[] = [
    { label: 'Appointment Reminders', field: 'disableAppointmentReminders' },
    { label: 'Real-Time Appointment Status', field: 'disableAppointmentRealTimeUpdates' },
    { label: 'Practitioner Communications', field: 'disablePractitionerCommunication' },
    { label: 'Pre-/Post-Op Care Instructions', field: 'disablePrePostOpCareInstructions' },
    { label: 'Pathology/Lab Updates', field: 'disableLabUpdates' },
    { label: 'Real-Time Office Alerts', field: 'disableOfficeCommunication' },
    { label: 'Feedback/Review Reminders', field: 'disableReviewReminders' },
    { label: 'Birthday Greetings', field: 'disableBirthdays' },
    { label: 'General Information', field: 'disableMarketingUpdates' },
  ];

  const StyledTableRow = styled( TableRow )( () => ( {
    [ `&.${ tableRowClasses.root }` ]: {
      // '& td, & th': { border: 0 },
      '& th': { border: 0 },
      '& button': { color: 'hsl(0deg 0% 0% / 30%)' },
    },
    [ `&.${ tableRowClasses.hover }` ]: {
      '&:hover button': { color: 'hsl(0deg 0% 0% / 50%)' },
    },
  } ) );

  const StyledBaseTableCell = styled( TableCell )( ( { theme } ) => ( {
    [ `&.${ tableCellClasses.head }` ]: {
      backgroundColor: theme.palette.common.white,
      color: 'hsl( 0 0% 0% /26% )', //theme.palette.augmentColor,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    [ `&.${ tableCellClasses.body }` ]: {
      fontSize: isXSmall ? 16 : 18,
      // paddingRight: '4em',
    },
  } ) );

  const StyledTableCell = styled( StyledBaseTableCell )( ( /* {theme} */ ) => ( {
    [ `&.${ tableCellClasses.body }` ]: {
      paddingRight: isXSmall ? '2rem' : '1rem',
    },
  } ) );


  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth='lg'
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Designate an Authorized Recipient to receive communications on your behalf and only of the types you select here. Change or remove this recipient here at any time later.
        </DialogContentText>
        <form>

          <Controller
            name='fullName'
            control={control}
            rules={{
              required: 'Name is required',
              // minLength: {
              //   value: 5,
              //   message: 'At least 5 chars'
              // }
            }}
            render={( { field } ) => (
              <TextField
                {...field}
                inputRef={field.ref}
                autoFocus
                required
                margin='dense'
                label='Name'
                fullWidth
                variant='filled'
                error={!!errors?.fullName?.message}
                helperText={errors?.fullName?.message}
              />
            )}
          />

          <Controller
            name='phone'
            control={control}
            rules={{
              validate: {
                required: () => !!getValues( 'email' ) || !!getValues( 'phone' ) || 'Either email or phone is required',
                valid: ( phone ) => !phone || validatePhoneNumber( countryCode )( phone ) || 'Must be a phone number',
              },
            }}
            render={( { field } ) => {
              // field.onChange( normalizePhone( countryCode ) )
              return (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  onChange={( e ) => {
                    field.onChange( normalizePhone( countryCode )( e.target.value ) );
                    clearErrors( [ 'phone', 'preference' ] );
                  }}
                  margin='dense'
                  label='Phone'
                  variant='filled'
                  error={!!errors?.phone?.message}
                  helperText={errors?.phone?.message}
                  fullWidth
                />
              );
            }}
          />

          <Controller
            name='email'
            control={control}
            rules={{
              validate: {
                required: () => !!getValues( 'email' ) || !!getValues( 'phone' ) || 'Either email or phone is required',
                valid: ( email ) => !email || isEmail( email ) || 'Must be an email',
              }
            }}
            render={( { field } ) => (
              <TextField
                {...field}
                inputRef={field.ref}
                onChange={( e ) => {
                  field.onChange( e.target.value.toLowerCase() );
                  clearErrors( 'email' );
                }}
                margin='dense'
                label='Email'
                type='email'
                fullWidth
                variant='filled'
                error={!!errors?.email?.message}
                helperText={errors?.email?.message}
              />
            )}
          />

          { watchPhone && 
          <Controller
            name='preference'
            control={control}
            render={( { field } ) => {

              return (
                <FormControl
                  variant='filled'
                  error={!!errors?.preference?.message}
                >
                  <FormLabel id='preference'>Preference</FormLabel>
                  <RadioGroup
                    {...field}
                    aria-labelledby='preference'
                    onChange={( e ) => { field.onChange( e.target.value ); clearErrors( 'preference' ) }}
                    row
                  >
                    <FormControlLabel value='phone' control={<Radio />} label='Phone/Voice' />
                    <FormControlLabel value='sms' control={<Radio />} label='SMS/Text' />
                    { watchEmail &&
                    <FormControlLabel value='email' control={<Radio />} label='Email' />
                    }
                  </RadioGroup>
                  <FormHelperText error={!!errors?.preference?.message}>
                    {errors?.preference?.message}
                  </FormHelperText>
                </FormControl>
              );
            }}
          />
          }
          <section>

            <FormLabel component='legend' sx={{ marginTop: 4 }}>
              Communication Types
            </FormLabel>

            {splitArray( commTypes, 3 ).map( ( commTypes, idx ) => (

              <TableContainer key={idx} sx={{ width: 'fit-content', display: 'inline-block', verticalAlign: 'top' }}>
                <Table size='small' padding='none' aria-label='selected communications table'
                >
                  <TableBody>
                    {commTypes.map( ( row, idx ) => {
                      return (
                        <StyledTableRow
                          key={`row${ idx }`}
                          hover={true}
                        >
                          <StyledTableCell scope='row' >
                            <Controller
                              key={`row${ idx }`}
                              name={row.field}
                              control={control}
                              render={( { field } ) => (
                                <FormControlLabel
                                  label={row.label}
                                  control={
                                    <Checkbox
                                      onChange={( e ) => field.onChange( !e.target.checked )}
                                      checked={!field.value}
                                    />
                                  }
                                />
                              )}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    } )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) )}
          </section>

        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          disabled={isSubmitting || ( isSubmitted && isValid )}
        >
          Cancel
        </Button>
        <Button variant='contained'
          disabled={!isDirty || isValidating || isSubmitting || !isValid}
          onClick={handleSubmit(
            async ( data ) => {
              const errors = await onSubmit( data );
              if( errors ) {
                // setError( 'root.serverError', {
                //   type: '400',
                // } );
                Object.entries( errors )
                  .forEach( ( [ field, error ], idx ) => {
                    if( isPatientRecipientFormDataField( field ) ) {
                      setError( field, error, { shouldFocus: idx === 0 } );
                      // setError( 'root.serverError', error );
                    }
                  } );
              }
            },
            ( v ) => { console.log( errors, v ) } ) /*onSubmit( getValues() ) */}
        >
          {isAdd ? 'Add' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog >
  )
}

