import { alpha, Box, Button, Grid, Slide, Stack, Typography, useTheme } from '@mui/material';
import { uniq, uniqBy } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLoaderData, useNavigate, useOutletContext, useRouteLoaderData } from 'react-router-dom';
import { AppointmentContext, getProperName, parseableDateTz, prettyDate, prettyShortDate, prettyTime, prettyTimeTz, scrollDown } from './AppointmentContainer';
import { ConfirmDialog } from './components/ConfirmDialog';
import { AppointmentRescheduleSlot, LocationsData, ProfileScheduleSlot, rescheduleAppointment, scheduleAppointment } from './profiles';
import { sample } from './AppointmentReschedule';
import { ProfileContext } from './ProfileContainer';
import { LocationInterface, PractitionerSummary } from './types';


export const AppointmentLocations: FC = () => {
  const { recipient, organization, colorPrimary, colorSecondary, fontBody, fontHeader, setNotify, setError, setTitle, isXSmall, isSmall, isMedium, countryCode } = useOutletContext<ProfileContext>();
  const { locations } = useRouteLoaderData( 'appointment-locations' ) as LocationsData;

  const theme = useTheme();
  const navigate = useNavigate();
  const sectionLocations = useRef<HTMLDivElement>( null );

  useEffect( () => setTitle( organization.name ), [ organization.name ] );

  const [ location, setLocation ] = useState<LocationInterface | undefined>();

  const timeZoneName = useMemo( () => {
    return location?.timeZoneName ?? recipient.timeZoneName ?? locations.slice( -1 )[ 0 ]?.timeZoneName ?? 'America/Chicago' // 'America/Los_Angeles';
  }, [ recipient, location, locations ] );

  useEffect( () => scrollDown( sectionLocations ) );

  const onClickLocation = useCallback( ( newLocation: LocationInterface ) => () => {
    // setLocation( location == newLocation ? undefined : newLocation )
    navigate( `${ newLocation._id }/schedule` );
  }, [] );


  return (
    <div id='appointment-locations'>
      <h3
        style={{
          fontFamily: fontHeader,
        }}
      >
        New Appointment - Pick Location
      </h3>
      <Box display='none' className='translated'>
        <Typography>{timeZoneName}</Typography>
        <Typography>{location?.name ?? ''}</Typography>
      </Box>

      <Stack mb={2}
      >
        <Box
          sx={{
            textAlign: 'center',
            // border: '1px solid purple',
            // width: '100%'
          }}
        >

          {/***********  LOCATIONS *****************/}
          <Box
            ref={sectionLocations}
            sx={{
              // border: '1px solid red',
              margin: '2rem auto 0',
              '& .MuiTypography-body1': {
                // color: 'red',
                margin: '1.25rem 1rem 1rem 0',
                fontWeight: 'bold',
                width: '16rem',
                display: 'inline-block',
              },
            }}
          >
            <Typography
              component='span'
              sx={{
              }}
              align='center'
            >
              What is your preferred location?
            </Typography>

          </Box>


          <Grid container
            direction='row'
            // justifyContent='space-between'
            alignItems='center'
            sx={{
              // height: '22rem',
              // border: '1px solid purple'
              '& .MuiButton-outlined': {
                backgroundColor: alpha( theme.palette.primary.main, 0.08 ),
                color: theme.palette.primary.main,
                '&:hover,&:active': {
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                }
              }
            }}
          >
            {locations.filter( l => ( l.appointmentTypeCodesEnabledForSchedule || [] ).length ).map( loc => (
              <Grid
                item
                key={loc.id}
                xs={12} sm={'auto'}
                className='translated'
              >

                <Button
                  variant={location?.id == loc.id ? 'contained' : 'outlined'}
                  size='small'
                  onClick={onClickLocation( loc )}
                  fullWidth={isXSmall}
                  sx={{
                    margin: { xs: '0.5rem 0', sm: '0.5rem' },
                    width: { xs: undefined, sm: '10rem', md: '9rem' },
                    '& .MuiTypography-root': {
                      margin: 'inherit',
                    },
                  }}
                  disableRipple
                  className='translated'
                >
                  <Typography
                    component='span'
                    sx={{
                      margin: '0.5rem 1rem 0 0',
                    }}
                  >
                    {loc.nickname || loc.name}
                  </Typography>
                </Button>

              </Grid>
            ) )
            }
          </Grid>
        </Box>

      </Stack>
    </div>
  );
}




